import { io } from 'socket.io-client';
import store from '../store'; // Redux store or your central store
import { addNotification, removeNotification } from '../features/notifcation/notificationsSlice';
import config from '../components/contexts/config'; // Ensure this path is correct

let socket = null;

/**
 * Initiates a Socket.IO connection with authentication.
 * @param {string} token - JWT token for authentication.
 */
export const initiateSocket = (token) => {
  if (!token) {
    console.warn('No token provided. Cannot connect to Socket.IO server.');
    return;
  }

  console.log('Connecting to Socket.IO server with token:', token);

  socket = io(config.API_URL, {
    transports: ['websocket'],
    auth: { token },
    reconnectionAttempts: 5,
    timeout: 10000,
  });

  socket.on('connect', () => {
    console.log('Connected to Socket.IO server.');
    store.dispatch(addNotification({ message: 'Connected to live updates.' }));
  });

  // Handle server-sent notifications
  socket.on('NEW_NOTIFICATION', (data) => {
    console.log('Received NEW_NOTIFICATION:', data);
    store.dispatch(addNotification(data));
  });

  socket.on('REMOVE_NOTIFICATION', (data) => {
    console.log('Received REMOVE_NOTIFICATION:', data);
    store.dispatch(removeNotification(data.id));
  });

  // Handle disconnection
  socket.on('disconnect', () => {
    console.log('Disconnected from Socket.IO server.');
    store.dispatch(addNotification({ message: 'Disconnected from live updates.' }));
  });

  // Handle connection errors
  socket.on('connect_error', (err) => {
    console.error('Socket connection error:', err.message);
    store.dispatch(addNotification({ message: 'Socket connection error.' }));
  });

  // Optional: Handle reconnection attempts
  socket.io.on('reconnect_attempt', (attempt) => {
    console.log(`Reconnection attempt #${attempt}`);
  });

  socket.io.on('reconnect_failed', () => {
    console.log('Reconnection failed.');
    store.dispatch(addNotification({ message: 'Reconnection failed.' }));
  });
};

/**
 * Disconnects the Socket.IO connection.
 */
export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if (socket) socket.disconnect();
};

/**
 * Emit a custom event to remove a notification (optional).
 * @param {string} notificationId - The ID of the notification to remove.
 */
export const emitRemoveNotification = (notificationId) => {
  if (socket) {
    socket.emit('REMOVE_NOTIFICATION', { id: notificationId });
    console.log(`Emitted REMOVE_NOTIFICATION for ID: ${notificationId}`);
  }
};
