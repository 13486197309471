// frontend/src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { UserProvider } from './components/contexts/UserContext';
import { CartProvider } from './components/contexts/CartContext';
import { OrderProvider } from './components/contexts/OrderContext';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store'; // Corrected import path
import { StoreProvider } from './components/contexts/StoreContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import './adjustForDevice';
import ErrorBoundary from './components/ErrorBoundary';
// Removed duplicate ToastContainer import
import disableToastNotifications from './utils/disableToast'; // Import the override function

// Disable all toast notifications
disableToastNotifications();

// Google Client ID
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <ReduxProvider store={store}> {/* Correctly imported store */}
          <UserProvider>
            <StoreProvider>
              <CartProvider>
                <OrderProvider>
                  <Router>
                    <App />
                  </Router>
                </OrderProvider>
              </CartProvider>
            </StoreProvider>
          </UserProvider>
        </ReduxProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
    {/* <ToastContainer /> is already included in App.js and hidden via CSS */}
  </React.StrictMode>
);
