// frontend/src/App.js

import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './Pages/Homepage';
import Store from './Pages/Store';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import ContactUs from './components/ContactUs';
import ViewCart from './Pages/ViewCart';
import Aboutus from './Pages/Aboutus';
import Orders from './components/Orders';
import ProfilePage from './components/Profile';
import ResetPassword from './components/ResetPassword';
import SharedWishlist from './components/SharedWishlist';
import ProductPage from './components/ProductPage';
import TermsAndConditions from './components/TermsAndConditions';
import AdminPage from './Pages/Admin';
import CheckoutPage from './Pages/CheckoutPage';
import Success from './Pages/Success';
import Cancel from './Pages/Cancel';
import { useUserContext } from './components/contexts/UserContext';
import { useStore } from './components/contexts/StoreContext';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import { initiateSocket, disconnectSocket } from './services/socket'; // Import Socket.IO handlers
import { useSelector } from 'react-redux'; // Import useSelector

/**
 * AdminRoute Component
 * Protects admin routes by checking if the user is an admin.
 */
const AdminRoute = ({ children }) => {
  const { user } = useUserContext();

  if (!user || user.role !== 'admin') {
    return <Navigate to="/" replace />;
  }

  return children;
};

const App = () => {
  const { isAddToCartModalOpen, closeAddToCartModal, selectedProductForCart } = useStore();
  const { user, token } = useUserContext(); // Ensure token is accessible

  useEffect(() => {
    if (token) {
      initiateSocket(token);
    }

    return () => {
      disconnectSocket();
    };
  }, [token]);

  return (
    <>
      <Navbar />

      <div className="pt-20">
        <div className="h-10 bg-gray-100"></div>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/store" element={<Store />} />
          <Route path="/store/:id" element={<Store />} />
          <Route path="/view-cart" element={<ViewCart />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/wishlist/:token" element={<SharedWishlist />} />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          {/* Admin route - only accessible to admins */}
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminPage />
              </AdminRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      <Footer />

      {/* Place ToastContainer here for global access and hide it via CSS */}
      <ToastContainer className="hide-toast" position="bottom-left" /> {/* Single instance */}
    </>
  );
};

export default App;
