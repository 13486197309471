// frontend/src/components/EditProductForm.js

import React, { useState, useRef, useEffect } from 'react';
import { useStore } from './contexts/StoreContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowDown, FaTimes } from 'react-icons/fa'; // Import FaTimes for remove buttons
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import config from './contexts/config';
import logger from '../utils/logger'; // Ensure logger is correctly imported
import { joinUrl } from '../utils/joinUrl'; // Ensure joinUrl is correctly imported

const animatedComponents = makeAnimated();

const EditProductForm = ({ product, onClose, onProductEdited }) => {
  // Access necessary methods and data from StoreContext
  const {
    categories = [],
    ingredients = [],
    stores = [],
    products = [], // Ensure products are available for AI Related Products
    createCategory,
    createIngredient,
    updateProduct,
    loading,
    error,
    setError,
  } = useStore();

  // Form State Variables initialized with existing product data
  const [name, setName] = useState(product.name || '');
  const [description, setDescription] = useState(product.description || '');
  const [price, setPrice] = useState(product.price || ''); // Base Price
  const [aiRecommendedPrice, setAiRecommendedPrice] = useState(product.aiRecommendedPrice || ''); // New Field
  const [aiRelatedProducts, setAiRelatedProducts] = useState(
    product.aiRelatedProducts
      ? product.aiRelatedProducts.map((prod) => ({
          label: prod.name,
          value: prod.id,
        }))
      : []
  ); // New Field: AI Related Products
  const [flavor, setFlavor] = useState(product.flavor || ''); // New Field: Flavor
  const [category, setCategory] = useState(
    product.category
      ? { label: product.category.name, value: product.category.id }
      : null
  ); // Changed to object
  const [image, setImage] = useState(null); // New image file if changed
  const [store, setStore] = useState(
    product.store
      ? { label: product.store.name, value: product.store.id }
      : null
  ); // Add state for selected store

  // SEO Fields
  const [metaTitle, setMetaTitle] = useState(product.metaTitle || ''); // New Field
  const [metaDescription, setMetaDescription] = useState(product.metaDescription || ''); // New Field
  const [metaKeywords, setMetaKeywords] = useState(product.metaKeywords || ''); // New Field

  // Active Status
  const [isActive, setIsActive] = useState(product.isActive || true); // New Field

  // Stock Options State
  const [stockOptions, setStockOptions] = useState(
    product.sizeOptions && product.sizeOptions.length > 0
      ? product.sizeOptions
      : [{ size: '', color: '', stock: 0 }]
  );

  // Ingredients State
  const [productIngredients, setProductIngredients] = useState(
    product.ingredients && product.ingredients.length > 0
      ? product.ingredients.map((ing) => ({
          ingredient: ingredients.find((item) => item.id === ing.ingredientId)
            ? {
                label: ingredients.find((item) => item.id === ing.ingredientId).name,
                value: ingredients.find((item) => item.id === ing.ingredientId).id,
              }
            : null,
          quantity: ing.quantity || '',
          unit: ing.unit || '',
        }))
      : [{ ingredient: null, quantity: '', unit: '' }]
  );

  // Recipe Details State
  const [recipe, setRecipe] = useState(
    product.recipe
      ? {
          instructions: product.recipe.instructions || '',
          prepTime: product.recipe.prepTime || '',
          cookTime: product.recipe.cookTime || '',
          servings: product.recipe.servings || '',
        }
      : {
          instructions: '',
          prepTime: '',
          cookTime: '',
          servings: '',
        }
  );

  // Refs and State for Scroll Handling
  const contentRef = useRef(null);
  const prevScrollTop = useRef(0);
  const [showArrow, setShowArrow] = useState(true);

  // Handle Scroll Event to show/hide arrow
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = contentRef.current.scrollTop;

      if (currentScrollTop > prevScrollTop.current) {
        // Scrolling Down
        setShowArrow(false);
      } else {
        // Scrolling Up
        setShowArrow(true);
      }

      prevScrollTop.current = currentScrollTop;
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  /**
   * Scroll to bottom function for the arrow
   */
  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  // Handle Stock Options
  const handleAddStockOption = () => {
    setStockOptions([...stockOptions, { size: '', color: '', stock: 0 }]);
  };

  const handleRemoveStockOption = (index) => {
    setStockOptions(stockOptions.filter((_, i) => i !== index));
  };

  const handleStockOptionChange = (index, key, value) => {
    const newStockOptions = [...stockOptions];
    newStockOptions[index][key] = value;
    setStockOptions(newStockOptions);
  };

  // Handle Ingredients
  const handleAddIngredient = () => {
    setProductIngredients([
      ...productIngredients,
      { ingredient: null, quantity: '', unit: '' },
    ]);
  };

  const handleRemoveIngredient = (index) => {
    setProductIngredients(productIngredients.filter((_, i) => i !== index));
  };

  const handleIngredientChange = (index, key, value) => {
    const newIngredients = [...productIngredients];
    newIngredients[index][key] = value;
    setProductIngredients(newIngredients);
  };

  // Handle Recipe Details
  const handleRecipeChange = (e) => {
    const { name, value } = e.target;
    setRecipe((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Image Upload
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  /**
   * Handle Form Submission
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic Validation
    if (
      !name ||
      !price ||
      !category ||
      !store ||
      !aiRecommendedPrice ||
      !flavor ||
      !metaTitle ||
      !metaDescription ||
      !metaKeywords
    ) {
      toast.error('Please fill out all required fields.');
      return;
    }

    // Validate Price
    if (parseFloat(price) < 0) {
      toast.error('Price must be a positive number.');
      return;
    }

    // Validate Ingredients
    for (let ing of productIngredients) {
      if (!ing.ingredient || !ing.quantity || !ing.unit) {
        toast.error('Please fill out all ingredient fields.');
        return;
      }
    }

    // Validate Recipe
    if (
      !recipe.instructions ||
      !recipe.prepTime ||
      !recipe.cookTime ||
      !recipe.servings
    ) {
      toast.error('Please fill out all recipe fields.');
      return;
    }

    // Prepare FormData
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price); // Base Price
    formData.append('aiRecommendedPrice', aiRecommendedPrice); // New Field
    formData.append(
      'aiRelatedProducts',
      JSON.stringify(aiRelatedProducts.map((prod) => prod.value))
    ); // New Field
    formData.append('flavor', flavor); // New Field
    formData.append('categoryId', category.value);
    formData.append('storeId', store.value);
    formData.append('isActive', isActive); // New Field

    // SEO Fields
    formData.append('metaTitle', metaTitle); // New Field
    formData.append('metaDescription', metaDescription); // New Field
    formData.append('metaKeywords', metaKeywords); // New Field

    // Add stock options
    formData.append('stockOptions', JSON.stringify(stockOptions));

    // Add ingredients
    const ingredientsData = productIngredients.map((ing) => ({
      ingredientId: ing.ingredient.value,
      quantity: ing.quantity,
      unit: ing.unit,
    }));
    formData.append('ingredients', JSON.stringify(ingredientsData));

    // Add recipe details
    formData.append('recipe', JSON.stringify(recipe));

    // Add image if changed
    if (image) {
      formData.append('image', image);
    }

    try {
      const updatedProduct = await updateProduct(product.id, formData);
      if (updatedProduct) {
        toast.success('Product updated successfully!');
        onProductEdited(updatedProduct);
        onClose();
      }
    } catch (err) {
      // Errors are handled within the context
      logger.error('Error updating product:', err);
      toast.error('Failed to update product.');
    }
  };

  /**
   * Handle Creating a New Category
   */
  const handleCreateCategory = async (inputValue) => {
    try {
      const created = await createCategory(inputValue);
      return {
        label: created.name,
        value: created.id,
      };
    } catch (err) {
      toast.error('Failed to create category.');
      return null;
    }
  };

  /**
   * Handle Creating a New Ingredient
   */
  const handleCreateIngredient = async (inputValue) => {
    try {
      const created = await createIngredient(inputValue);
      return {
        label: created.name,
        value: created.id,
      };
    } catch (err) {
      toast.error('Failed to create ingredient.');
      return null;
    }
  };

  /**
   * Convert categories, ingredients, stores, and products to react-select format
   */
  const categoryOptions = Array.isArray(categories)
    ? categories.map((cat) => ({
        label: cat.name,
        value: cat.id,
      }))
    : [];

  const ingredientOptions = Array.isArray(ingredients)
    ? ingredients.map((ing) => ({
        label: ing.name,
        value: ing.id,
      }))
    : [];

  const storeOptions = Array.isArray(stores)
    ? stores.map((store) => ({
        label: store.name,
        value: store.id,
      }))
    : [];

  const productOptions = Array.isArray(products)
    ? products.map((prod) => ({
        label: prod.name,
        value: prod.id,
      }))
    : [];

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto relative"
        ref={contentRef}
      >
        {/* Close Button */}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-gray-300 hover:text-white text-2xl font-bold focus:outline-none"
            aria-label="Close"
          >
            &times;
          </button>
        </div>

        <h2 className="text-2xl font-bold mb-6 text-white">Edit Product</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Product Name */}
          <div>
            <label className="block text-white font-semibold">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Description */}
          <div>
            <label className="block text-white font-semibold">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
            />
          </div>

          {/* Base Price */}
          <div>
            <label className="block text-white font-semibold">
              Base Price (£) <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              step="0.01"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* AI Recommended Price */}
          <div>
            <label className="block text-white font-semibold">
              AI Recommended Price (£) <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              step="0.01"
              value={aiRecommendedPrice}
              onChange={(e) => setAiRecommendedPrice(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* AI Related Products */}
          <div>
            <label className="block text-white font-semibold">
              AI Related Products <span className="text-red-500">*</span>
            </label>
            <CreatableSelect
              isMulti
              isClearable
              onChange={(selectedOptions) => setAiRelatedProducts(selectedOptions)}
              onCreateOption={async (inputValue) => {
                // Assuming you have a way to create a related product if not existing
                const created = await handleCreateCategory(inputValue); // Replace with createProduct if needed
                if (created) {
                  setAiRelatedProducts((prev) => [...prev, created]);
                }
              }}
              options={productOptions}
              value={aiRelatedProducts}
              components={animatedComponents}
              className="mt-1"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568', // Tailwind bg-gray-700
                  color: '#FFFFFF', // Tailwind text-white
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568',
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#2D3748', // Tailwind bg-gray-800
                }),
                input: (base) => ({
                  ...base,
                  color: '#FFFFFF',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                  color: '#FFFFFF',
                }),
              }}
              placeholder="Select or create related products"
              aria-label="AI Related Products Select"
              required
            />
          </div>

          {/* Flavor */}
          <div>
            <label className="block text-white font-semibold">
              Flavor <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={flavor}
              onChange={(e) => setFlavor(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., Chocolate, Vanilla"
            />
          </div>

          {/* Category Selection with Creatable Select */}
          <div>
            <label className="block text-white font-semibold">
              Category <span className="text-red-500">*</span>
            </label>
            <CreatableSelect
              isClearable
              onChange={(newValue) => setCategory(newValue)}
              onCreateOption={async (inputValue) => {
                const created = await handleCreateCategory(inputValue);
                if (created) {
                  setCategory(created);
                }
              }}
              options={categoryOptions}
              value={category}
              components={animatedComponents}
              className="mt-1"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568', // Tailwind bg-gray-700
                  color: '#FFFFFF', // Tailwind text-white
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568',
                }),
                singleValue: (base) => ({
                  ...base,
                  color: '#FFFFFF',
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#2D3748', // Tailwind bg-gray-800
                }),
                input: (base) => ({
                  ...base,
                  color: '#FFFFFF',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                  color: '#FFFFFF',
                }),
              }}
              placeholder="Select or create a category"
              aria-label="Category Select"
              required
            />
          </div>

          {/* Store Selection with Select */}
          <div>
            <label className="block text-white font-semibold">
              Store <span className="text-red-500">*</span>
            </label>
            <Select
              isClearable
              onChange={(newValue) => setStore(newValue)}
              options={storeOptions}
              value={store}
              components={animatedComponents}
              className="mt-1"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568',
                  color: '#FFFFFF',
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: '#4A5568',
                }),
                singleValue: (base) => ({
                  ...base,
                  color: '#FFFFFF',
                }),
                input: (base) => ({
                  ...base,
                  color: '#FFFFFF',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                  color: '#FFFFFF',
                }),
              }}
              placeholder="Select a store"
              aria-label="Store Select"
              required
            />
          </div>

          {/* Image Upload */}
          <div>
            <label className="block text-white font-semibold">
              Image {image ? '' : <span className="text-red-500">*</span>}
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Product Image Upload"
            />
            {/* Display Image Preview */}
            {image ? (
              <img
                src={URL.createObjectURL(image)}
                alt="New Product"
                className="mt-4 w-32 h-32 object-cover rounded"
              />
            ) : product.imageUrl ? (
              <img
                src={
                  product.imageUrl.startsWith('http')
                    ? product.imageUrl
                    : joinUrl(config.API_URL, product.imageUrl)
                }
                alt={product.name}
                className="mt-4 w-32 h-32 object-cover rounded"
              />
            ) : null}
          </div>

          {/* Stock Options */}
          <div>
            <label className="block text-white font-semibold">Stock Options</label>
            {stockOptions.map((option, index) => (
              <div key={index} className="flex items-center space-x-2 mt-2">
                <input
                  type="text"
                  placeholder="Size"
                  value={option.size}
                  onChange={(e) =>
                    handleStockOptionChange(index, 'size', e.target.value)
                  }
                  className="w-1/3 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label={`Size Option ${index + 1}`}
                />
                <input
                  type="text"
                  placeholder="Color"
                  value={option.color}
                  onChange={(e) =>
                    handleStockOptionChange(index, 'color', e.target.value)
                  }
                  className="w-1/3 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label={`Color Option ${index + 1}`}
                />
                <input
                  type="number"
                  placeholder="Stock"
                  value={option.stock}
                  onChange={(e) =>
                    handleStockOptionChange(index, 'stock', e.target.value)
                  }
                  className="w-1/3 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label={`Stock Quantity Option ${index + 1}`}
                />
                {stockOptions.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveStockOption(index)}
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
                    aria-label={`Remove Stock Option ${index + 1}`}
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddStockOption}
              className="mt-4 bg-green-500 hover:bg-green-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              aria-label="Add Stock Option"
            >
              Add Stock Option
            </button>
          </div>

          {/* Ingredients Selection with Creatable Select */}
          <div>
            <label className="block text-white font-semibold">
              Ingredients <span className="text-red-500">*</span>
            </label>
            {productIngredients.map((ing, index) => (
              <div key={index} className="flex items-center space-x-2 mt-2">
                <CreatableSelect
                  isClearable
                  onChange={(newValue) =>
                    handleIngredientChange(index, 'ingredient', newValue)
                  }
                  onCreateOption={async (inputValue) => {
                    const created = await handleCreateIngredient(inputValue);
                    if (created) {
                      handleIngredientChange(index, 'ingredient', created);
                    }
                  }}
                  options={ingredientOptions}
                  value={ing.ingredient}
                  components={animatedComponents}
                  className="flex-1"
                  styles={{
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#4A5568',
                      color: '#FFFFFF',
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: '#4A5568',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#FFFFFF',
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: '#2D3748',
                    }),
                    input: (base) => ({
                      ...base,
                      color: '#FFFFFF',
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? '#2D3748' : '#4A5568',
                      color: '#FFFFFF',
                    }),
                  }}
                  placeholder="Select or create an ingredient"
                  aria-label={`Ingredient ${index + 1}`}
                  required
                />
                <input
                  type="number"
                  placeholder="Quantity"
                  value={ing.quantity}
                  onChange={(e) =>
                    handleIngredientChange(index, 'quantity', e.target.value)
                  }
                  className="w-1/4 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label={`Ingredient Quantity ${index + 1}`}
                  required
                />
                <input
                  type="text"
                  placeholder="Unit"
                  value={ing.unit}
                  onChange={(e) =>
                    handleIngredientChange(index, 'unit', e.target.value)
                  }
                  className="w-1/4 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label={`Ingredient Unit ${index + 1}`}
                  required
                />
                {productIngredients.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveIngredient(index)}
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
                    aria-label={`Remove Ingredient ${index + 1}`}
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddIngredient}
              className="mt-4 bg-green-500 hover:bg-green-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              aria-label="Add Ingredient"
            >
              Add Ingredient
            </button>
          </div>

          {/* Recipe Details */}
          <div>
            <label className="block text-white font-semibold">
              Recipe Details <span className="text-red-500">*</span>
            </label>
            <div className="space-y-4 mt-2">
              <div>
                <label className="block text-white">Instructions</label>
                <textarea
                  name="instructions"
                  value={recipe.instructions}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                  required
                />
              </div>
              <div>
                <label className="block text-white">
                  Preparation Time (minutes)
                </label>
                <input
                  type="number"
                  name="prepTime"
                  value={recipe.prepTime}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-white">Cooking Time (minutes)</label>
                <input
                  type="number"
                  name="cookTime"
                  value={recipe.cookTime}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-white">Servings</label>
                <input
                  type="number"
                  name="servings"
                  value={recipe.servings}
                  onChange={handleRecipeChange}
                  className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          {/* SEO Fields */}
          <div>
            <label className="block text-white font-semibold">
              Meta Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={metaTitle}
              onChange={(e) => setMetaTitle(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="SEO Meta Title"
            />
          </div>

          <div>
            <label className="block text-white font-semibold">
              Meta Description <span className="text-red-500">*</span>
            </label>
            <textarea
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
              required
              placeholder="SEO Meta Description"
            />
          </div>

          <div>
            <label className="block text-white font-semibold">
              Meta Keywords <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={metaKeywords}
              onChange={(e) => setMetaKeywords(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="SEO Meta Keywords (comma separated)"
            />
          </div>

          {/* Active Status */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              aria-label="Active Status"
            />
            <label htmlFor="isActive" className="ml-2 block text-white">
              Active
            </label>
          </div>

          {/* Submit and Cancel Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-gray-400"
              aria-label="Cancel Editing"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              disabled={loading}
              aria-label="Save Changes"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>

          {/* Error Message */}
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </form>

        {/* Hovering Arrow */}
        {showArrow && (
          <button
            onClick={scrollToBottom}
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-full shadow-lg transition-opacity opacity-75 hover:opacity-100 animate-bounce"
            aria-label="Scroll to bottom"
          >
            <FaArrowDown />
          </button>
        )}
      </div>
    </div>
  );
};

export default EditProductForm;
