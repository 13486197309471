// frontend/src/utils/utils.js

/**
 * Formats a number as GBP currency.
 * @param {number} value - The numerical value to format.
 * @returns {string} - Formatted currency string.
 */
export const formatCurrency = (value) => {
    if (typeof value !== 'number') {
      return '£0.00';
    }
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
  };
  
  /**
   * Capitalizes the first letter of each word in a string.
   * @param {string} str - The string to capitalize.
   * @returns {string} - Capitalized string.
   */
  export const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  /**
   * Checks if a URL is absolute.
   * @param {string} url - The URL to check.
   * @returns {boolean} - True if absolute, else false.
   */
  export const isAbsoluteUrl = (url) => {
    return /^https?:\/\//i.test(url);
  };
  
  /**
   * Joins base URL with a path, ensuring proper formatting.
   * @param {string} base - The base URL.
   * @param {string} path - The path to append.
   * @returns {string} - Joined URL.
   */
  export const joinUrl = (base, path) => {
    return `${base.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`;
  };
  