export const avatars = [
  'https://img.icons8.com/color/2x/cupcake.png', // Cupcake icon from Icons8
  'https://img.icons8.com/color/2x/ice-cream.png', // Ice cream icon from Icons8
  'https://img.icons8.com/color/2x/doughnut.png', // Doughnut icon from Icons8
  'https://img.icons8.com/color/2x/pancake.png', // Pancake icon from Icons8
  'https://img.icons8.com/color/2x/chocolate-bar.png', // Chocolate bar icon from Icons8
  'https://img.icons8.com/color/2x/macarons.png', // Macarons icon from Icons8
  'https://img.icons8.com/color/2x/strawberry-cake.png', // Strawberry cake icon from Icons8
  'https://img.icons8.com/color/2x/cheesecake.png', // Cheesecake icon from Icons8
  'https://img.icons8.com/color/2x/banana-split.png', // Banana split icon from Icons8
  'https://img.icons8.com/color/2x/waffle.png', // Waffle icon from Icons8
  'https://cdn-icons-png.flaticon.com/512/1691/1691759.png', // Dessert icon from Flaticon
  'https://cdn-icons-png.flaticon.com/512/1253/12532315.png', // Dessert icon from Flaticon
  'https://cdn-icons-png.flaticon.com/512/2488/2488456.png', // Dessert icon from Flaticon
  'https://cdn-icons-png.flaticon.com/512/3713/3713022.png', // Dessert icon from Flaticon
  'https://cdn-icons-png.flaticon.com/512/3644/3644335.png', // Dessert icon from Flaticon
  'https://img.icons8.com/color/2x/fruit-cake.png', // Fruit cake icon from Icons8
  'https://img.icons8.com/color/2x/gelato.png', // Gelato icon from Icons8
  'https://img.icons8.com/color/2x/milkshake.png', // Milkshake icon from Icons8
  'https://img.icons8.com/color/2x/pie.png', // Pie icon from Icons8
  'https://img.icons8.com/color/2x/tiramisu.png', // Tiramisu icon from Icons8
];
