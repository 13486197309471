// src/components/GoogleLoginButton.js

import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useUserContext } from './contexts/UserContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

const GoogleLoginButton = () => {
  const { setToken } = useUserContext(); // Ensure setToken is available in UserContext
  const navigate = useNavigate(); // To navigate after successful login
  const [isLoading, setIsLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsLoading(true);
      try {
        // Send the access token to your backend for verification and to receive your own JWT
        const response = await axios.post('/users/google-login', {
          tokenId: tokenResponse.access_token,
        });

        if (response.data && response.data.token) {
          setToken(response.data.token);
          localStorage.setItem('token', response.data.token);
          toast.success('Logged in with Google successfully!');
          navigate('/'); // Redirect to home or desired page
        } else {
          throw new Error('Google login failed. No token received.');
        }
      } catch (error) {
        console.error('Google login error:', error);
        toast.error('Google login failed. Please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    onError: (errorResponse) => {
      console.error('Google login failed:', errorResponse);
      toast.error('Google login failed. Please try again.');
    },
  });

  return (
    <button
      type="button"
      onClick={() => login()}
      className={`w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm flex items-center justify-center bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
        isLoading ? 'cursor-not-allowed opacity-50' : ''
      }`}
      aria-label="Continue with Google"
      disabled={isLoading}
    >
      {/* Google Logo */}
      <img
        src="https://developers.google.com/identity/images/g-logo.png"
        alt="Google Logo"
        className="w-5 h-5 mr-3"
      />
      {isLoading ? (
        <FaSpinner className="animate-spin text-gray-700" />
      ) : (
        <span className="text-gray-700 font-medium">Continue with Google</span>
      )}
    </button>
  );
};

export default GoogleLoginButton;
