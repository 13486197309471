// src/components/LottieAnimation.js

import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const LottieAnimation = ({ animationPath, loop = true, autoplay = true, style = {} }) => {
  return (
    <Player
      autoplay={autoplay}
      loop={loop}
      src={animationPath}
      style={style}
      className="w-full h-full"
    />
  );
};

export default LottieAnimation;
