// src/components/contexts/UserContext.js

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import api from '../../api'; // Correct import path
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create a Context with default values
const UserContext = createContext({
  user: null,
  token: null,
  setToken: () => {},
  login: () => {},
  loginWithGoogle: () => {},
  loginWithFacebook: () => {},
  loginWithApple: () => {},
  logout: () => {},
  register: () => {},
  updateUser: () => {},
  initiatePasswordReset: () => {},
  resetPassword: () => {},
  fetchAllUsers: () => {},
  updateAnyUser: () => {},
  deleteAnyUser: () => {},
  generateWishlistShareLink: () => {},
  fetchSharedWishlist: () => {},
  loading: false,
  error: null,
  selectStore: () => {},
});

// Create a Provider Component
export const UserProvider = ({ children }) => {
  // State Variables
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Decode JWT token to get user information
   * @param {string} token
   * @returns {Object|null} decoded token
   */
  const decodeToken = (token) => {
    try {
      return jwtDecode(token);
    } catch (e) {
      console.error('Invalid token:', e);
      return null;
    }
  };

  /**
   * Fetch current authenticated user details
   */
  const fetchCurrentUser = useCallback(async () => {
    if (!token) return; // If no token, do nothing
    setLoading(true);
    setError(null);
    try {
      const decoded = decodeToken(token);
      if (!decoded) {
        throw new Error('Invalid token');
      }

      // Make API call to fetch current user
      const response = await api.get('/users/current_user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data.user) {
        setUser(response.data.data.user); // Set user data
      } else {
        throw new Error('User data not found');
      }
    } catch (err) {
      console.error('Failed to fetch current user:', err);
      setError('Failed to fetch user data. Please log in again.');
      logout(); // Automatically log out on failure
    } finally {
      setLoading(false);
    }
  }, [token]); // Removed 'api' from dependencies

  /**
   * Initialize user data on component mount or token change
   */
  useEffect(() => {
    if (token) {
      fetchCurrentUser();
    }
  }, [token, fetchCurrentUser]);

  /**
   * User Registration
   * @param {Object} details - { firstName, lastName, email, username, password }
   */
  const registerUser = async (details) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/users/register', details);

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token); // Set token state
        toast.success('Registered and logged in successfully!');
      } else {
        throw new Error('Registration failed. Please try again.');
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(
        err.response?.data?.message ||
          'Registration failed. Please try again.'
      );
      toast.error(
        err.response?.data?.message ||
          'Registration failed. Please try again.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * User Login
   * @param {Object} credentials - { email, password }
   */
  const login = async ({ email, password }) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/users/login', { email, password });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token); // Set token state
        toast.success('Logged in successfully!');
      } else {
        throw new Error('Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(
        err.response?.data?.message || 'Login failed. Please try again.'
      );
      toast.error(
        err.response?.data?.message || 'Login failed. Please try again.'
      );
      throw err; // Re-throw for component-level handling if needed
    } finally {
      setLoading(false);
    }
  };

  /**
   * Google Login
   * @param {string} tokenId - Google OAuth token
   */
  const loginWithGoogle = async (tokenId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/users/google-login', { tokenId });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token); // Set token state
        toast.success('Logged in with Google successfully!');
      } else {
        throw new Error('Google login failed. Please try again.');
      }
    } catch (err) {
      console.error('Google login error:', err);
      setError(
        err.response?.data?.message || 'Google login failed. Please try again.'
      );
      toast.error(
        err.response?.data?.message || 'Google login failed. Please try again.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Facebook Login
   * @param {string} accessToken - Facebook OAuth access token
   */
  const loginWithFacebook = async (accessToken) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/users/facebook-login', { accessToken });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token); // Set token state
        toast.success('Logged in with Facebook successfully!');
      } else {
        throw new Error('Facebook login failed. Please try again.');
      }
    } catch (err) {
      console.error('Facebook login error:', err);
      setError(
        err.response?.data?.message || 'Facebook login failed. Please try again.'
      );
      toast.error(
        err.response?.data?.message || 'Facebook login failed. Please try again.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Apple Login
   * @param {string} identityToken - Apple OAuth identity token
   */
  const loginWithApple = async (identityToken) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/users/apple-login', { identityToken });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token); // Set token state
        toast.success('Logged in with Apple successfully!');
      } else {
        throw new Error('Apple login failed. Please try again.');
      }
    } catch (err) {
      console.error('Apple login error:', err);
      setError(
        err.response?.data?.message || 'Apple login failed. Please try again.'
      );
      toast.error(
        err.response?.data?.message || 'Apple login failed. Please try again.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * User Logout
   */
  const logout = () => {
    localStorage.removeItem('token'); // Remove token from storage
    setToken(null); // Clear token state
    setUser(null); // Clear user state
    toast.info('Logged out successfully.');
  };

  /**
   * Update User Profile
   * @param {Object} updates - Fields to update
   */
  const updateUser = async (updates) => {
    if (!token || !user) {
      setError('You must be logged in to perform this action.');
      toast.error('You must be logged in to perform this action.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await api.put(`/users/${user.userId}`, updates, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data.user) {
        setUser(response.data.data.user); // Update user state
        toast.success('Profile updated successfully!');
      } else {
        throw new Error('Profile update failed.');
      }
    } catch (err) {
      console.error('Update profile error:', err);
      setError(
        err.response?.data?.message || 'Failed to update profile.'
      );
      toast.error(
        err.response?.data?.message || 'Failed to update profile.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Initiate Password Reset
   * @param {string} email
   */
  const initiatePasswordReset = async (email) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/users/forgot-password', { email });

      if (response.data && response.data.success) {
        toast.success('Password reset email sent!');
      } else {
        throw new Error('Failed to initiate password reset.');
      }
    } catch (err) {
      console.error('Initiate password reset error:', err);
      setError(
        err.response?.data?.message || 'Failed to initiate password reset.'
      );
      toast.error(
        err.response?.data?.message || 'Failed to initiate password reset.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Reset Password
   * @param {string} tokenParam - Password reset token
   * @param {string} newPassword
   */
  const resetPassword = async (tokenParam, newPassword) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post(
        `/users/reset-password/${tokenParam}`,
        { password: newPassword }
      );

      if (response.data && response.data.success) {
        toast.success(
          'Password reset successful! You can now log in with your new password.'
        );
      } else {
        throw new Error('Failed to reset password.');
      }
    } catch (err) {
      console.error('Reset password error:', err);
      setError(
        err.response?.data?.message || 'Failed to reset password.'
      );
      toast.error(
        err.response?.data?.message || 'Failed to reset password.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch All Users (Admin Only)
   */
  const fetchAllUsers = useCallback(async () => {
    if (!token || !user || user.role !== 'admin') {
      setError('Unauthorized access.');
      toast.error('Unauthorized access.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/users/admin', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data.users) {
        return response.data.data.users; // Return list of users
      } else {
        throw new Error('Failed to fetch users.');
      }
    } catch (err) {
      console.error('Fetch all users error:', err);
      setError(
        err.response?.data?.message || 'Failed to fetch users.'
      );
      toast.error(
        err.response?.data?.message || 'Failed to fetch users.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  }, [token, user]); // Removed 'api' from dependencies

  /**
   * Update Any User (Admin Only)
   * @param {number} id - User ID
   * @param {Object} updates - Fields to update
   */
  const updateAnyUser = useCallback(
    async (id, updates) => {
      if (!token || !user || user.role !== 'admin') {
        setError('Unauthorized access.');
        toast.error('Unauthorized access.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        await api.put(`/users/admin/${id}`, updates, { // Removed 'response' assignment
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('User updated successfully!');
        // Assuming you might want to return updated user data, fetch it again or return a success flag
      } catch (err) {
        console.error('Update any user error:', err);
        setError(
          err.response?.data?.message || 'Failed to update user.'
        );
        toast.error(
          err.response?.data?.message || 'Failed to update user.'
        );
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token, user] // Removed 'api' from dependencies
  );

  /**
   * Delete Any User (Admin Only)
   * @param {number} id - User ID
   */
  const deleteAnyUser = useCallback(
    async (id) => {
      if (!token || !user || user.role !== 'admin') {
        setError('Unauthorized access.');
        toast.error('Unauthorized access.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        await api.delete(`/users/admin/${id}`, { // Removed 'response' assignment
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('User deleted successfully!');
        return true; // Indicate success
      } catch (err) {
        console.error('Delete any user error:', err);
        setError(
          err.response?.data?.message || 'Failed to delete user.'
        );
        toast.error(
          err.response?.data?.message || 'Failed to delete user.'
        );
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token, user] // Removed 'api' from dependencies
  );

  /**
   * Generate Shareable Wishlist Link
   * @param {number} userId - User ID
   */
  const generateWishlistShareLink = useCallback(
    async (userId) => {
      if (!token || !user) {
        setError('You must be logged in to perform this action.');
        toast.error('You must be logged in to perform this action.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await api.get(
          `/users/wishlist/share/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.data.link) {
          toast.success('Shareable link generated successfully!');
          return response.data.data.link; // Return the link
        } else {
          throw new Error('Failed to generate shareable link.');
        }
      } catch (err) {
        console.error(
          'Generate wishlist share link error:',
          err
        );
        setError(
          err.response?.data?.message ||
            'Failed to generate shareable link.'
        );
        toast.error(
          err.response?.data?.message ||
            'Failed to generate shareable link.'
        );
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token, user] // Removed 'api' from dependencies
  );

  /**
   * Fetch Shared Wishlist via Shareable Link
   * @param {string} shareToken - Shareable link token
   */
  const fetchSharedWishlist = useCallback(
    async (shareToken) => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(
          `/users/wishlist/shared/${shareToken}`
        );

        if (response.data && response.data.data) {
          toast.success('Shared wishlist fetched successfully!');
          return response.data.data; // Return wishlist data
        } else {
          throw new Error('Failed to fetch shared wishlist.');
        }
      } catch (err) {
        console.error('Fetch shared wishlist error:', err);
        setError(
          err.response?.data?.message ||
            'Failed to fetch shared wishlist.'
        );
        toast.error(
          err.response?.data?.message ||
            'Failed to fetch shared wishlist.'
        );
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [] // Removed 'api' from dependencies; assuming 'api' doesn't change
  );

  /**
   * Select Store
   * @param {number} storeId
   */
  const selectStore = (storeId) => {
    setUser((prevUser) => ({
      ...prevUser,
      selectedStoreId: storeId,
    }));
  };

  /**
   * Fetches detailed information about a single order.
   * @param {number} orderId - The ID of the order.
   * @returns {Object|null} - The detailed information of the order.
   */
  const fetchOrderDetails = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required to fetch details');
        toast.error('Order ID is required to fetch details.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/orders/${orderId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(`Fetched details for Order ID ${orderId}:`, response.data.data);
        return response.data.data;
      } catch (err) {
        console.error(`Failed to fetch details for Order ID ${orderId}:`, err);
        setError('Failed to fetch order details');
        toast.error('Failed to fetch order details.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  // Return the Context Provider with all states and functions
  return (
    <UserContext.Provider
      value={{
        // User State
        user,
        token,
        setToken, // Expose setToken for external usage
        loading,
        error,

        // Authentication Methods
        login,
        loginWithGoogle,
        loginWithFacebook,
        loginWithApple,
        logout,
        register: registerUser,

        // Profile Management
        updateUser,

        // Password Reset
        initiatePasswordReset,
        resetPassword,

        // Admin Functionalities
        fetchAllUsers,
        updateAnyUser,
        deleteAnyUser,

        // Wishlist Sharing
        generateWishlistShareLink,
        fetchSharedWishlist,

        // Select Store
        selectStore,

        // Order Details
        fetchOrderDetails,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom Hook to use the UserContext
export const useUserContext = () => useContext(UserContext);

export default UserContext;
