// frontend/src/components/Sidebar.js

import React from 'react';
import {
  FaChartPie,
  FaUsers,
  FaBox,
  FaBell,
  FaCog,
  FaStore, // Import FaStore icon for the Stores tab
} from 'react-icons/fa';

const Sidebar = ({ selectedTab, setSelectedTab, darkMode }) => {
  const menuItems = [
    { label: 'Overview', icon: <FaChartPie />, value: 'overview' },
    { label: 'Orders', icon: <FaBox />, value: 'orders' },
    { label: 'Users', icon: <FaUsers />, value: 'users' },
    { label: 'Products', icon: <FaBox />, value: 'products' },
    { label: 'Inventory Alerts', icon: <FaBell />, value: 'inventory' },
    { label: 'Payments', icon: <FaCog />, value: 'payments' },
    { label: 'Stores', icon: <FaStore />, value: 'stores' }, // Corrected value to 'stores' and updated icon
  ];

  return (
    <div className={`w-64 p-4 ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
      <ul className="space-y-4">
        {menuItems.map((item) => (
          <li
            key={item.value}
            className={`cursor-pointer p-2 flex items-center space-x-3 ${
              selectedTab === item.value && 'bg-blue-500 text-white rounded-lg'
            }`}
            onClick={() => setSelectedTab(item.value)}
          >
            {item.icon}
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
