// src/components/ProgressTracker.js

import React from 'react';
import { motion } from 'framer-motion';
import { FaShoppingCart, FaBoxOpen, FaCheckCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import LottieAnimation from './LottieAnimation';
import successAnimation from '../assets/animations/success.json';
import preparingAnimation from '../assets/animations/preparing.json';
import 'react-tooltip/dist/react-tooltip.css'; // Import CSS for react-tooltip

const steps = [
  {
    id: 1,
    title: 'Order Received',
    icon: <FaShoppingCart />,
    status: 'completed',
    description: 'Your order has been successfully received.',
    animation: preparingAnimation,
  },
  {
    id: 2,
    title: 'Preparing Order',
    icon: <FaBoxOpen />,
    status: 'in-progress',
    description: 'We are preparing your delicious desserts.',
    animation: preparingAnimation,
  },
  {
    id: 3,
    title: 'Ready for Pickup',
    icon: <FaCheckCircle />,
    status: 'pending',
    description: 'Your order is ready for pickup at our store.',
    animation: null,
  },
  {
    id: 4,
    title: 'Collected',
    icon: <FaCheckCircle />,
    status: 'pending',
    description: 'You have successfully collected your order!',
    animation: null,
  },
  {
    id: 5,
    title: 'Completed',
    icon: <FaCheckCircle />,
    status: 'pending',
    description: 'Your order process is completed.',
    animation: successAnimation,
  },
];

const ProgressTracker = ({ currentStep }) => {
  return (
    <div className="w-full max-w-4xl mx-auto my-12">
      <svg
        className="w-full h-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 2"
      >
        {/* Static Line */}
        <line
          x1="0"
          y1="1"
          x2="500"
          y2="1"
          stroke="#e5e7eb"
          strokeWidth="2"
        />
        {/* Animated Line */}
        <motion.line
          x1="0"
          y1="1"
          x2="0"
          y2="1"
          stroke="#3b82f6"
          strokeWidth="2"
          initial={{ strokeDasharray: 500, strokeDashoffset: 500 }}
          animate={{ strokeDashoffset: 0 }}
          transition={{ duration: 2, ease: 'easeInOut' }}
        />
      </svg>

      <div className="flex justify-between items-center mt-6 relative">
        {steps.map((step, index) => {
          const isActive = currentStep === step.id;
          const isCompleted = currentStep > step.id;

          return (
            <div key={step.id} className="flex flex-col items-center w-1/5">
              {/* Step Indicator */}
              <motion.div
                className={`flex items-center justify-center rounded-full w-12 h-12 text-white cursor-pointer shadow-lg ${
                  isCompleted
                    ? 'bg-green-500'
                    : isActive
                    ? 'bg-blue-500 animate-pulse'
                    : 'bg-gray-300'
                }`}
                data-tooltip-id={`tooltip-${step.id}`}
                data-tooltip-content={step.description}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2 * index, duration: 0.5 }}
                aria-label={`${step.title} status`}
                role="button"
                tabIndex={0}
              >
                {isCompleted ? <FaCheckCircle /> : step.icon}
              </motion.div>

              {/* Tooltip */}
              <Tooltip id={`tooltip-${step.id}`} place="top" effect="solid" />

              {/* Step Title */}
              <span
                className={`mt-2 text-sm font-medium ${
                  isCompleted || isActive ? 'text-blue-600' : 'text-gray-500'
                }`}
              >
                {step.title}
              </span>

              {/* Lottie Animation for Active Steps */}
              {isActive && step.animation && (
                <div className="absolute top-16">
                  <LottieAnimation
                    animationPath={step.animation}
                    loop={true}
                    autoplay={true}
                    style={{ width: 100, height: 100 }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressTracker;
