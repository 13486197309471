// frontend/src/components/ProductsManagement.js

import React, { useState, useEffect, useRef } from 'react';
import { useStore } from './contexts/StoreContext';
import EditProductForm from './EditProductForm';
import AddProductForm from './AddProductForm';
import BulkActionModal from './BulkActionModal';
import ExportProducts from './ExportProducts';
import AIPricingRecommendations from './AIPricingRecommendations';
import io from 'socket.io-client';
import Modal from 'react-modal';
import { FaTrash, FaEdit, FaCheck, FaPlusCircle, FaSort, FaChartLine } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');

const ProductsManagement = () => {
  const { 
    products, 
    fetchProducts, 
    deleteProduct, 
    loading, 
    error, 
    sortProducts,
    filterProducts,
    bulkDeleteProducts,
    bulkUpdatePrice,
    bulkUpdateStock
    // Removed aiPricingRecommendations as it's not used
  } = useStore();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isAIModalOpen, setIsAIModalOpen] = useState(false);
  
  const socketRef = useRef(null);

  useEffect(() => {
    fetchProducts();

    // Initialize Socket.io
    socketRef.current = io(process.env.REACT_APP_API_URL, {
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    const socket = socketRef.current;

    socket.on('connect', () => {
      console.log('Connected to the server');
    });

    socket.on('productUpdate', () => fetchProducts());

    socket.on('productDelete', () => fetchProducts());

    socket.on('disconnect', () => {
      console.log('Disconnected from the server');
    });

    socket.on('error', (err) => {
      console.error('Socket error:', err);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setIsEditProductModalOpen(true);
  };

  const handleDelete = async (productId) => {
    try {
      await deleteProduct(productId);
      toast.success('Product deleted successfully!');
      // Optionally, emit socket event
      socketRef.current.emit('productDeleted', productId);
    } catch (error) {
      toast.error('Failed to delete product.');
    }
  };

  const handleBulkAction = () => {
    if (selectedProducts.length === 0) {
      toast.error('No products selected for bulk action.');
      return;
    }
    setIsBulkActionModalOpen(true);
  };

  const applyBulkAction = async (action, payload) => {
    try {
      if (action === 'delete') {
        await bulkDeleteProducts(selectedProducts);
        toast.success('Selected products deleted successfully!');
        socketRef.current.emit('bulkDelete', selectedProducts);
      } else if (action === 'updatePrice') {
        await bulkUpdatePrice(selectedProducts, payload.newPrice);
        toast.success('Prices updated successfully!');
        socketRef.current.emit('bulkUpdatePrice', { products: selectedProducts, newPrice: payload.newPrice });
      } else if (action === 'updateStock') {
        await bulkUpdateStock(selectedProducts, payload.newStock);
        toast.success('Stock quantities updated successfully!');
        socketRef.current.emit('bulkUpdateStock', { products: selectedProducts, newStock: payload.newStock });
      }
      setSelectedProducts([]);
    } catch (error) {
      toast.error('Failed to apply bulk action.');
    }
  };

  const handleSort = (key) => {
    sortProducts(key);
  };

  const handleFilterChange = (filterKey, value) => {
    filterProducts(filterKey, value);
  };

  const handleAddProduct = () => {
    setIsAddProductModalOpen(true);
  };

  const handleProductAdded = (newProduct) => {
    fetchProducts();
    toast.success(`Product "${newProduct.name}" added successfully!`);
  };

  const handleProductEdited = (editedProduct) => {
    fetchProducts();
    toast.success(`Product "${editedProduct.name}" updated successfully!`);
  };

  const handleAIPricing = () => {
    if (selectedProduct) {
      setIsAIModalOpen(true);
    } else {
      toast.error('Please select a product for AI recommendations.');
    }
  };

  return (
    <div className="ml-64 p-6">
      <h1 className="text-3xl font-bold mb-4 text-white">Manage Products</h1>

      {/* Action Buttons */}
      <div className="flex justify-between mb-4">
        <div className="flex space-x-4">
          <button 
            onClick={handleBulkAction} 
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded flex items-center"
          >
            <FaCheck className="mr-2" /> Bulk Actions
          </button>
          <button 
            onClick={handleAddProduct} 
            className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded flex items-center"
          >
            <FaPlusCircle className="mr-2" /> Add New Product
          </button>
          <ExportProducts products={products} />
          <button 
            onClick={handleAIPricing} 
            className="bg-indigo-500 hover:bg-indigo-700 text-white py-2 px-4 rounded flex items-center"
          >
            <FaChartLine className="mr-2" /> AI Pricing
          </button>
        </div>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search products..."
            className="p-2 border border-gray-400 rounded bg-gray-700 text-white"
            onChange={(e) => handleFilterChange('search', e.target.value)}
          />
          <button 
            onClick={() => handleSort('name')} 
            className="bg-gray-200 text-black py-2 px-4 rounded flex items-center"
          >
            <FaSort className="mr-2" /> Sort by Name
          </button>
          <button 
            onClick={() => handleSort('price')} 
            className="bg-gray-200 text-black py-2 px-4 rounded flex items-center"
          >
            <FaSort className="mr-2" /> Sort by Price
          </button>
        </div>
      </div>

      {/* Products Table */}
      {loading ? (
        <p className="text-white">Loading products...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-800 text-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">
                  <input 
                    type="checkbox" 
                    checked={selectedProducts.length === products.length && products.length > 0} 
                    onChange={(e) => setSelectedProducts(e.target.checked ? products.map(p => p.id) : [])}
                  />
                </th>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Category</th>
                <th className="py-2 px-4 border-b">Price (£)</th>
                <th className="py-2 px-4 border-b">Stock</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.length > 0 ? (
                products.map((product) => (
                  <tr key={product.id} className={`hover:bg-gray-700 ${selectedProduct?.id === product.id ? 'bg-gray-600' : ''}`}>
                    <td className="py-2 px-4 border-b">
                      <input 
                        type="checkbox" 
                        checked={selectedProducts.includes(product.id)} 
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedProducts([...selectedProducts, product.id]);
                          } else {
                            setSelectedProducts(selectedProducts.filter(id => id !== product.id));
                          }
                        }}
                      />
                    </td>
                    <td className="py-2 px-4 border-b">{product.id}</td>
                    <td className="py-2 px-4 border-b">{product.name}</td>
                    <td className="py-2 px-4 border-b">{product.Category?.name || 'Uncategorized'}</td>
                    <td className="py-2 px-4 border-b">£{parseFloat(product.price).toFixed(2)}</td>
                    <td className="py-2 px-4 border-b">{product.stockQuantity}</td>
                    <td className="py-2 px-4 border-b flex space-x-2">
                      <button 
                        onClick={() => handleEdit(product)} 
                        className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded flex items-center"
                      >
                        <FaEdit className="mr-1" /> Edit
                      </button>
                      <button 
                        onClick={() => handleDelete(product.id)} 
                        className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded flex items-center"
                      >
                        <FaTrash className="mr-1" /> Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">No products found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Bulk Action Modal */}
      {isBulkActionModalOpen && (
        <BulkActionModal
          selectedProducts={selectedProducts}
          onApply={applyBulkAction}
          onClose={() => setIsBulkActionModalOpen(false)}
        />
      )}

      {/* Add Product Modal */}
      <Modal
        isOpen={isAddProductModalOpen}
        onRequestClose={() => setIsAddProductModalOpen(false)}
        contentLabel="Add Product"
        className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50 text-white overflow-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
      >
        <AddProductForm 
          onClose={() => setIsAddProductModalOpen(false)} 
          onProductAdded={handleProductAdded} 
        />
      </Modal>

      {/* Edit Product Modal */}
      {isEditProductModalOpen && selectedProduct && (
        <Modal
          isOpen={isEditProductModalOpen}
          onRequestClose={() => setIsEditProductModalOpen(false)}
          contentLabel="Edit Product"
          className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-4xl mx-auto my-8 z-50 text-white overflow-auto"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <EditProductForm 
            product={selectedProduct} 
            onClose={() => setIsEditProductModalOpen(false)} 
            onProductEdited={handleProductEdited} 
          />
        </Modal>
      )}

      {/* AI Pricing Recommendations Modal */}
      {isAIModalOpen && selectedProduct && (
        <Modal
          isOpen={isAIModalOpen}
          onRequestClose={() => setIsAIModalOpen(false)}
          contentLabel="AI Pricing Recommendations"
          className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white overflow-auto"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <AIPricingRecommendations 
            productId={selectedProduct.id} 
            currentPrice={selectedProduct.price} 
            onClose={() => setIsAIModalOpen(false)} 
          />
        </Modal>
      )}
    </div>
  );
};

export default ProductsManagement;
