// frontend/src/utils/isAbsoluteUrl.js

/**
 * Checks if a given URL is absolute.
 * @param {string} url - The URL to check.
 * @returns {boolean} - True if the URL is absolute, false otherwise.
 */
const isAbsoluteUrl = (url) => {
    return /^(?:[a-z]+:)?\/\//i.test(url);
  };
  
  export default isAbsoluteUrl;
  