// src/components/OrdersManagement/OrdersManagement.jsx

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo, // Re-added useMemo
  Suspense,
} from 'react'; // Removed unused hooks
import { useStore } from '../contexts/StoreContext';
import { useUserContext } from '../contexts/UserContext';
import { io } from 'socket.io-client';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  createTheme,
  ThemeProvider,
  // useMediaQuery, // Removed 'useMediaQuery' as it's unused
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import config from '../contexts/config';
import { capitalizeWords } from '../../utils'; // Removed 'formatCurrency' as it's unused
import 'react-toastify/dist/ReactToastify.css';
// import Papa from 'papaparse'; // Removed 'Papa' as it's unused
// import { saveAs } from 'file-saver'; // Removed 'saveAs' as it's unused
import ErrorBoundary from '../ErrorBoundary';
import { FaTrash, FaCogs } from 'react-icons/fa';
import dayjs from 'dayjs';

import { ORDER_STATUSES, STATUS_TRANSITIONS } from '../constants/orderStatuses';

const Header = React.lazy(() => import('./Header'));
const TabsPanel = React.lazy(() => import('./TabsPanel'));
const OrderDetailsPanel = React.lazy(() => import('./OrderDetailsPanel'));
const BulkActionBar = React.lazy(() => import('./BulkActionBar'));

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#ff80ab' },
    secondary: { main: '#ffb74d' },
    background: { default: '#fff3e0', paper: '#ffffff' },
  },
  typography: { fontFamily: 'Inter, Arial, sans-serif' },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#ff80ab' },
    secondary: { main: '#ffb74d' },
    background: { default: '#262626', paper: '#333333' },
  },
  typography: { fontFamily: 'Inter, Arial, sans-serif' },
});

const OrdersManagement = () => {
  const {
    orders,
    setOrders,
    fetchOrders,
    updateOrder,
    deleteOrder,
    bulkUpdateOrders,
    downloadInvoice,
    // sendNotification, // Removed 'sendNotification' as it's unused
    fetchOrderDetails,
    // trackOrder, // Remove usage of trackOrder for this scenario
    loading,
    // error, // Removed 'error' as it's unused
    stores,
    fetchStores,
    selectedStore,
    setSelectedStore,
    ingredients, // Destructure ingredients from StoreContext
  } = useStore();

  const { user } = useUserContext();
  const token = user?.token;
  const isAdmin = user?.role === 'admin';
  const navigate = useNavigate();
  // Removed isMobile as it's unused
  // const isMobile = useMediaQuery('(max-width:600px)');

  const [columns, setColumns] = useState(() => {
    const initialColumns = {};
    ORDER_STATUSES.forEach((status) => {
      initialColumns[status] = { name: status, orders: [] };
    });
    return initialColumns;
  });

  const [selectedBulkOrders, setSelectedBulkOrders] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [confirmBulkAction, setConfirmBulkAction] = useState(false);

  const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') || 'light');
  const [socketConnected, setSocketConnected] = useState(false);
  const [isOrderDetailsPanelOpen, setIsOrderDetailsPanelOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  // Removed filterStatus as its setter is unused
  // const [filterStatus, setFilterStatus] = useState('all');
  // Removed setAutoRefresh as it's unused
  // const [autoRefresh, setAutoRefresh] = useState(true);

  const [tabValue, setTabValue] = useState(() => {
    const savedTab = localStorage.getItem('selectedTab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  // Date Filters
  const [showPreviousDays, setShowPreviousDays] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    if (!isAdmin) {
      fetchStores();
    }
  }, [fetchStores, isAdmin]);

  const loadOrders = useCallback(async () => {
    try {
      let params = { limit: 1000 };
      if (!isAdmin && selectedStore) {
        params.storeId = selectedStore.id;
      }

      if (showPreviousDays) {
        params.startDate = startDate;
        params.endDate = endDate;
      } else {
        const todayStr = dayjs().format('YYYY-MM-DD');
        params.startDate = todayStr;
        params.endDate = todayStr;
      }

      const data = await fetchOrders(params);

      if (data && Array.isArray(data.data)) {
        let fetchedOrders = data.data;

        if (!showPreviousDays) {
          const todayStart = dayjs().startOf('day');
          const todayEnd = dayjs().endOf('day');
          fetchedOrders = fetchedOrders.filter((order) => {
            const orderDate = dayjs(order.createdAt);
            return orderDate.isAfter(todayStart) && orderDate.isBefore(todayEnd);
          });
        }

        setOrders(fetchedOrders);
      } else {
        throw new Error('Failed to fetch orders.');
      }
    } catch (err) {
      console.error('Error fetching orders:', err);
      toast.error('Failed to fetch orders.');
    }
  }, [fetchOrders, isAdmin, selectedStore, showPreviousDays, startDate, endDate, setOrders]);

  useEffect(() => {
    if (isAdmin || selectedStore) {
      loadOrders();
    }
  }, [isAdmin, selectedStore, loadOrders, showPreviousDays, startDate, endDate]);

  // Removed autoRefresh useEffect as setAutoRefresh is unused

  useEffect(() => {
    const organizedColumns = {};
    ORDER_STATUSES.forEach((status) => {
      organizedColumns[status] = { name: status, orders: [] };
    });

    orders.forEach((order) => {
      let orderStatus = order.status.toLowerCase();
      if (!ORDER_STATUSES.includes(orderStatus)) {
        orderStatus = 'pending';
      }
      organizedColumns[orderStatus].orders.push(order);
    });

    setColumns(organizedColumns);
  }, [orders]);

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formatTime = useCallback((ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  const getTimeLeft = useCallback((order) => {
    const status = order.status.toLowerCase();
    if (status === 'processing' && order.processingStartTime) {
      const startTime = dayjs(order.processingStartTime).valueOf();
      const elapsed = currentTime - startTime;
      let remaining = 25 * 60 * 1000 - elapsed;
      if (remaining < 0) remaining = 0;
      return formatTime(remaining);
    }
    if (status === 'baking' && order.bakingStartTime) {
      const startTime = dayjs(order.bakingStartTime).valueOf();
      const elapsed = currentTime - startTime;
      let remaining = 20 * 60 * 1000 - elapsed;
      if (remaining < 0) remaining = 0;
      return formatTime(remaining);
    }
    if (status === 'ready for pickup' && order.readyForCollectionTime) {
      const startTime = dayjs(order.readyForCollectionTime).valueOf();
      const elapsed = currentTime - startTime;
      let remaining = 5 * 60 * 1000 - elapsed;
      if (remaining < 0) remaining = 0;
      return formatTime(remaining);
    }
    return null;
  }, [currentTime, formatTime]);

  useEffect(() => {
    const socket = io(config.API_URL, {
      transports: ['websocket'],
      auth: { token: token || '' },
      reconnectionAttempts: 5,
      timeout: 10000,
    });

    socket.on('connect', () => {
      setSocketConnected(true);
      toast.success('Connected to live updates.');
      if (isAdmin) {
        socket.emit('join_admin');
      } else if (selectedStore) {
        socket.emit('join_store', selectedStore.id);
      }
      socket.emit('request_presence');
    });

    socket.on('disconnect', () => {
      setSocketConnected(false);
      toast.error('Disconnected from live updates.');
    });

    socket.on('new_order', (order) => {
      toast.info(`New order #${order.id} received`);
      setOrders((prev) => [order, ...prev]);
    });

    socket.on('order_status_update', ({ orderId, status, processingStartTime, bakingStartTime, readyForCollectionTime }) => {
      toast.info(`Order #${orderId} updated to ${capitalizeWords(status)}`);
      setOrders((prev) =>
        prev.map((ord) =>
          ord.id === orderId
            ? { ...ord, status, processingStartTime, bakingStartTime, readyForCollectionTime }
            : ord
        )
      );
    });

    socket.on('order_deleted', ({ orderId }) => {
      toast.info(`Order #${orderId} deleted`);
      setOrders((prev) => prev.filter((o) => o.id !== orderId));
    });

    socket.on('connect_error', (err) => {
      console.error('Socket error:', err.message);
      toast.error('Live updates connection failed.');
    });

    return () => {
      socket.disconnect();
    };
  }, [isAdmin, selectedStore, token, setOrders]);

  const onDragEndHandler = useCallback(
    async (result) => {
      const { source, destination } = result;
      if (!destination) return;
      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }

      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const draggedOrder = sourceColumn.orders[source.index];

      const currentStatus = draggedOrder.status.toLowerCase();
      const newStatus = destColumn.name.toLowerCase();

      const allowedTransitions = STATUS_TRANSITIONS[currentStatus] || [];
      if (!allowedTransitions.includes(newStatus)) {
        toast.error(`Cannot move order to ${destColumn.name} from ${sourceColumn.name}.`);
        return;
      }

      const updatedSourceOrders = Array.from(sourceColumn.orders);
      updatedSourceOrders.splice(source.index, 1);

      const updatedDestOrders = Array.from(destColumn.orders);
      updatedDestOrders.splice(destination.index, 0, {
        ...draggedOrder,
        status: destColumn.name,
      });

      setColumns((prev) => ({
        ...prev,
        [sourceColumn.name]: { ...sourceColumn, orders: updatedSourceOrders },
        [destColumn.name]: { ...destColumn, orders: updatedDestOrders },
      }));

      try {
        await updateOrder(draggedOrder.id, { status: destColumn.name }, token);
        toast.success(`Order #${draggedOrder.id} moved to ${destColumn.name}`);
      } catch (err) {
        console.error('Update status failed:', err);
        toast.error('Update status failed.');
        setColumns((prev) => ({
          ...prev,
          [sourceColumn.name]: { ...sourceColumn, orders: sourceColumn.orders },
          [destColumn.name]: { ...destColumn, orders: destColumn.orders },
        }));
      }
    },
    [columns, updateOrder, token]
  );

  const handleBulkActionHandler = useCallback((actionType) => {
    setBulkAction(actionType);
    setConfirmBulkAction(true);
  }, []);

  const confirmBulkActionHandler = useCallback(async () => {
    setConfirmBulkAction(false);
    const action = bulkAction;
    setBulkAction('');
    try {
      await bulkUpdateOrders(selectedBulkOrders, action, token);
      toast.success(`Bulk action (${capitalizeWords(action)}) applied.`);
      await loadOrders();
      setSelectedBulkOrders([]);
    } catch (err) {
      console.error('Bulk action error:', err);
      toast.error('Bulk action failed.');
    }
  }, [bulkAction, bulkUpdateOrders, loadOrders, selectedBulkOrders, token]);

  const cancelBulkActionHandler = useCallback(() => {
    setConfirmBulkAction(false);
    setBulkAction('');
  }, []);

  const handleSelectOrderHandler = useCallback((orderId) => {
    setSelectedBulkOrders((prev) =>
      prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
    );
  }, []);

  // Removed exportToCSV as it's unused
  /*
  const exportToCSV = useCallback(() => {
    const allOrders = Object.values(columns)
      .flatMap((c) => c.orders)
      .map((order) => ({
        'Order ID': order.id,
        Customer: order.user?.username || 'N/A',
        Store: order.store?.name || 'N/A',
        Total: formatCurrency(order.total),
        Status: capitalizeWords(order.status),
        Date: order.createdAt ? dayjs(order.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
      }));

    const csvData = Papa.unparse(allOrders);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'orders_export.csv');
    toast.success('CSV exported.');
  }, [columns]);
  */

  const toggleThemeMode = useCallback(() => {
    const newMode = themeMode === 'dark' ? 'light' : 'dark';
    setThemeMode(newMode);
    localStorage.setItem('themeMode', newMode);
  }, [themeMode]);

  const handleStoreSelectChange = useCallback(
    (e) => {
      const storeId = e.target.value;
      const store = stores.find((s) => s.id === parseInt(storeId, 10));
      setSelectedStore(store);
      setSelectedBulkOrders([]);
    },
    [stores, setSelectedStore]
  );

  const handleViewOrder = useCallback((order) => {
    setSelectedOrder(order);
    setIsOrderDetailsPanelOpen(true);
  }, []);

  const closeOrderDetailsPanel = useCallback(() => {
    setSelectedOrder(null);
    setIsOrderDetailsPanelOpen(false);
  }, []);

  const handleDownloadInvoice = useCallback(
    async (orderId) => {
      try {
        await downloadInvoice(orderId, token);
        toast.success('Invoice downloaded.');
      } catch (err) {
        console.error('Invoice download failed:', err);
        toast.error('Invoice download failed.');
      }
    },
    [downloadInvoice, token]
  );

  // Removed handleSendNotification as it's unused
  /*
  const handleSendNotification = useCallback(
    async (orderId, type) => {
      try {
        await sendNotification(orderId, type, token);
        toast.success('Notification sent.');
      } catch (err) {
        console.error('Notification send failed:', err);
        toast.error('Notification send failed.');
      }
    },
    [sendNotification, token]
  );
  */

  // Instead of calling trackOrder endpoint, replicate success navigation logic:
  const handleNavigateToSuccess = useCallback(
    async (orderId) => {
      try {
        const details = await fetchOrderDetails(orderId, token);
        if (details) {
          const { session_id: sessionId, orderType } = details;
          if (sessionId && orderType) {
            const queryParams = new URLSearchParams({ session_id: sessionId, orderId, orderType });
            navigate(`/success?${queryParams.toString()}`);
          } else {
            toast.error('Missing session info.');
          }
        } else {
          toast.error('Order details not found.');
        }
      } catch (err) {
        console.error('Fetch details failed:', err);
        toast.error('Fetch details failed.');
      }
    },
    [fetchOrderDetails, navigate, token]
  );

  const handleCancelOrder = useCallback(
    async (orderId) => {
      try {
        await updateOrder(orderId, { status: 'cancelled' }, token);
        toast.success('Order cancelled.');
        loadOrders();
      } catch (err) {
        console.error('Cancel order failed:', err);
        toast.error('Cancel order failed.');
      }
    },
    [updateOrder, loadOrders, token]
  );

  const handleDeleteOrder = useCallback(
    async (orderId) => {
      try {
        await deleteOrder(orderId, token);
        toast.success('Order deleted.');
        loadOrders();
      } catch (err) {
        console.error('Delete order failed:', err);
        toast.error('Delete order failed.');
      }
    },
    [deleteOrder, loadOrders, token]
  );

  const handleMarkAsBaking = useCallback(
    async (orderId) => {
      try {
        await updateOrder(orderId, { status: 'baking', bakingStartTime: dayjs().toISOString() }, token);
        toast.success(`Order #${orderId} marked as Baking.`);
        loadOrders();
      } catch (err) {
        console.error('Mark as Baking failed:', err);
        toast.error('Mark as Baking failed.');
      }
    },
    [updateOrder, loadOrders, token]
  );

  const handleMarkAsCollected = useCallback(
    async (orderId) => {
      try {
        await updateOrder(orderId, { status: 'collected', collectedTime: dayjs().toISOString() }, token);
        toast.success(`Order #${orderId} marked as Collected.`);
        loadOrders();
      } catch (err) {
        console.error('Mark as Collected failed:', err);
        toast.error('Mark as Collected failed.');
      }
    },
    [updateOrder, loadOrders, token]
  );

  const handleSearchInput = useCallback((e) => setSearchTerm(e.target.value), []);

  const filteredOrdersMemo = useMemo(() => {
    const currentFilterStatus = 'all'; // Replaced filterStatus state with a constant
    return Object.values(columns)
      .flatMap((c) => c.orders)
      .filter((order) => {
        const matchesStatus = currentFilterStatus === 'all' || order.status === currentFilterStatus;
        const matchesSearch =
          order.id.toString().includes(searchTerm.trim()) ||
          (order.user?.username &&
            order.user.username.toLowerCase().includes(searchTerm.trim().toLowerCase()));
        return matchesStatus && matchesSearch;
      })
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [columns, searchTerm]);

  const displayedColumns = useMemo(() => {
    if (isAdmin) {
      return columns;
    } else {
      return {
        'pending': columns['pending'],
        'processing': columns['processing'],
        'baking': columns['baking'],
        'ready for pickup': columns['ready for pickup'],
        'collected': columns['collected'],
        'cancelled': columns['cancelled'],
        'refunded': columns['refunded'],
        'deleted': columns['deleted'],
        'completed': columns['completed'],
      };
    }
  }, [columns, isAdmin]);

  const allStatusActions = ORDER_STATUSES.map((status) => ({
    type: status,
    name: `Mark as ${capitalizeWords(status)}`,
    icon: <FaCogs />,
    isAvailable: (count) => count > 0,
  }));

  const otherActions = [
    {
      type: 'delete',
      name: 'Delete',
      icon: <FaTrash />,
      isAvailable: (count) => count > 0,
    },
  ];

  const availableActions = [...otherActions, ...allStatusActions];

  // Instead of calling trackOrder endpoint, we now use handleNavigateToSuccess when clicking the track icon.
  const handleTrackOrder = handleNavigateToSuccess; // Just alias for consistency with OrderCard prop

  const theme = themeMode === 'dark' ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Suspense fallback={<div className="flex items-center justify-center h-screen"><CircularProgress /></div>}>
          <div
            className="min-h-screen transition-colors duration-300"
            style={{ background: theme.palette.background.default, color: theme.palette.text.primary }}
            aria-label="Orders Management Page"
          >
            <ToastContainer />

            <Header
              tabValue={tabValue}
              handleTabChange={handleTabChange}
              searchTerm={searchTerm}
              handleSearchInput={handleSearchInput}
              toggleTheme={toggleThemeMode}
              themeMode={themeMode}
              socketConnected={socketConnected}
              isAdmin={isAdmin}
              stores={stores}
              selectedStore={selectedStore}
              handleStoreSelect={handleStoreSelectChange}
              showPreviousDays={showPreviousDays}
              handleShowPreviousDaysToggle={setShowPreviousDays}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />

            <TabsPanel
              tabValue={tabValue}
              isAdmin={isAdmin}
              selectedBulkOrders={selectedBulkOrders}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              handleBulkAction={handleBulkActionHandler}
              columns={displayedColumns}
              getTimeLeft={getTimeLeft}
              handleSelectOrder={handleSelectOrderHandler}
              handleViewOrder={handleViewOrder}
              handleDownloadInvoice={handleDownloadInvoice}
              handleCancelOrder={handleCancelOrder}
              handleDeleteOrder={handleDeleteOrder}
              handleMarkAsBaking={handleMarkAsBaking}
              handleMarkAsCollected={handleMarkAsCollected}
              handleTrackOrder={handleTrackOrder} // Now this leads to success page navigation
              filteredOrders={filteredOrdersMemo}
              onDragEnd={onDragEndHandler}
            />

            <OrderDetailsPanel
              isOpen={isOrderDetailsPanelOpen}
              order={selectedOrder}
              handleClose={closeOrderDetailsPanel}
              isAdmin={isAdmin}
              handleCancelOrder={handleCancelOrder}
              handleDeleteOrder={handleDeleteOrder}
              handleTrackOrder={handleTrackOrder}
              ingredients={ingredients} // Pass ingredients here
            />

            {isAdmin && (
              <Dialog
                open={confirmBulkAction}
                onClose={cancelBulkActionHandler}
                aria-labelledby="bulk-action-confirmation-dialog"
              >
                <DialogTitle id="bulk-action-confirmation-dialog">Confirm Bulk Action</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    You are about to <strong>{capitalizeWords(bulkAction)}</strong> <strong>{selectedBulkOrders.length}</strong> order(s). This action cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelBulkActionHandler} color="primary" aria-label="Cancel Bulk Action">
                    Cancel
                  </Button>
                  <Button onClick={confirmBulkActionHandler} color="secondary" autoFocus aria-label="Confirm Bulk Action">
                    {loading ? <CircularProgress size={24} /> : 'Confirm'}
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {isAdmin && selectedBulkOrders.length > 0 && (
              <Suspense fallback={<CircularProgress />}>
                <BulkActionBar
                  handleBulkAction={handleBulkActionHandler}
                  availableActions={availableActions}
                  selectedCount={selectedBulkOrders.length}
                />
              </Suspense>
            )}
          </div>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default OrdersManagement;

