// frontend/src/components/contexts/CartContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from './UserContext';
import api from '../../api';
import logger from '../../utils/logger';
import { toast } from 'react-toastify';
// Removed unused getImageUrl import
// import { getImageUrl } from '../../utils/joinUrl'; // Ensure this path is correct
import { useDispatch } from 'react-redux';
import { addNotification } from '../../features/notifcation/notificationsSlice'; // Corrected import path
import { v4 as uuidv4 } from 'uuid'; // Import uuid

// Create CartContext with default values
const CartContext = createContext({
  cartItems: [],
  savedItems: [],
  orderType: 'pickup', // Default orderType
  setOrderType: () => {},
  fetchCart: () => {},
  fetchSavedItems: () => {},
  addItemToCart: () => {},
  removeItemFromCart: () => {},
  increaseIngredientQuantity: () => {},
  decreaseIngredientQuantity: () => {},
  moveToSaved: () => {},
  moveToCart: () => {},
  clearCart: () => {},
});

// Export useCart hook for easy consumption with context validation
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

// CartProvider component to wrap around parts of the app that need access to cart state
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [orderType, setOrderType] = useState('pickup'); // Default to 'pickup'
  const { user, token } = useUserContext();
  const dispatch = useDispatch(); // Initialize dispatch

  /**
   * Fetches cart items from the backend for authenticated users.
   * For unauthenticated users, fetches from local storage.
   */
  const fetchCart = useCallback(async () => {
    if (token) {
      try {
        const response = await api.get(`/api/cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCartItems(response.data.data);
        logger.info('Fetched cart items from backend');
      } catch (error) {
        logger.error('Failed to fetch cart items:', error);
        toast.error('Failed to fetch cart items. Please try again.');
      }
    } else {
      // Handle unauthenticated users by fetching from local storage
      const localCart = JSON.parse(localStorage.getItem('cart')) || [];
      setCartItems(localCart);
      logger.info('Fetched cart items from local storage for unauthenticated user');
    }
  }, [token]);

  /**
   * Fetches saved-for-later items from the backend for authenticated users.
   * For unauthenticated users, fetches from local storage.
   */
  const fetchSavedItems = useCallback(async () => {
    if (token) {
      try {
        const response = await api.get(`/api/cart/saved`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSavedItems(response.data.data);
        logger.info('Fetched saved items from backend');
      } catch (error) {
        logger.error('Failed to fetch saved items:', error);
        toast.error('Failed to fetch saved items. Please try again.');
      }
    } else {
      // Handle unauthenticated users by fetching from local storage
      const localSaved = JSON.parse(localStorage.getItem('savedItems')) || [];
      setSavedItems(localSaved);
      logger.info('Fetched saved items from local storage for unauthenticated user');
    }
  }, [token]);

  /**
   * Sets the order type and persists it.
   * For authenticated users, you might want to send it to the backend.
   * For simplicity, we'll handle it in the frontend context.
   * @param {string} type - 'pickup' or 'delivery'
   */
  const updateOrderType = useCallback(
    async (type) => {
      if (!['pickup', 'delivery'].includes(type)) {
        toast.error('Invalid order type selected.');
        return;
      }

      setOrderType(type);
      logger.info(`Order type set to: ${type}`);

      // Optionally, notify the backend about the order type
      // This depends on your backend implementation
      // For now, we'll handle it during checkout

      // Dispatch notification to Redux store
      const notification = {
        id: uuidv4(),
        message: `Order type set to ${type}.`,
        timestamp: new Date().toISOString(),
      };
      dispatch(addNotification(notification));

      toast.success(`Order type set to ${type}.`);
    },
    [dispatch]
  );

  /**
   * Adds an item to the cart.
   * For authenticated users, sends a POST request to the backend.
   * For unauthenticated users, updates local storage.
   * @param {Object} item - The item to add.
   * @param {number} item.productId - The product ID.
   * @param {string} item.size - The selected size.
   * @param {Array} [item.ingredients] - Array of ingredient objects.
   * @param {number} [item.quantity=1] - The quantity (optional, defaults to 1).
   * @param {number} [item.price] - Total price of the item.
   * @param {string} [item.imageUrl] - Image URL of the product.
   */
  const addItemToCart = useCallback(
    async (item) => {
      const { productId, size, ingredients = [], quantity = 1, price, imageUrl } = item;

      if (!productId || !size) {
        toast.error('Product ID and size are required to add to cart.');
        return;
      }

      if (token) {
        try {
          // Check if the item already exists in the cart based on productId, size, and ingredients
          const existingItem = cartItems.find(
            (ci) =>
              ci.productId === productId &&
              ci.attributes.size === size &&
              JSON.stringify(ci.attributes.ingredients) === JSON.stringify(ingredients)
          );

          if (existingItem) {
            // If item exists, increase its quantity
            const updatedItem = { ...existingItem, quantity: existingItem.quantity + quantity };
            await api.put(`/api/cart/${existingItem.id}`, updatedItem, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setCartItems((prevCart) =>
              prevCart.map((ci) => (ci.id === existingItem.id ? updatedItem : ci))
            );
            logger.info(`Increased quantity for cart item ID ${existingItem.id}`);

            // Dispatch notification to Redux store
            const notification = {
              id: uuidv4(),
              message: 'Item quantity updated in cart!',
              timestamp: new Date().toISOString(),
            };
            dispatch(addNotification(notification));

            toast.success('Item quantity updated in cart!');
          } else {
            // If item does not exist, add as new with attributes
            const response = await api.post(
              `/api/cart`,
              {
                productId,
                attributes: {
                  size,
                  ingredients: ingredients.map((ing) => ({
                    ingredientId: ing.id, // Ensure consistency
                    quantity: ing.quantity,
                  })),
                },
                quantity,
                price,
                imageUrl,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setCartItems((prevCart) => [...prevCart, response.data.data]);
            logger.info(`Added product ID ${productId} to cart for user ID ${user?.id || 'unknown'}`);

            // Dispatch notification to Redux store
            const notification = {
              id: uuidv4(),
              message: 'Item added to cart!',
              timestamp: new Date().toISOString(),
            };
            dispatch(addNotification(notification));

            toast.success('Item added to cart!');
          }
        } catch (error) {
          logger.error('Failed to add item to cart:', error);
          toast.error('Failed to add item to cart. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          // Check for duplicates based on productId, size, and ingredients
          const existingIndex = localCart.findIndex(
            (ci) =>
              ci.productId === productId &&
              ci.attributes.size === size &&
              JSON.stringify(ci.attributes.ingredients) === JSON.stringify(ingredients)
          );

          if (existingIndex !== -1) {
            // If exists, increase quantity
            localCart[existingIndex].quantity += quantity;

            // Dispatch notification to Redux store
            const notification = {
              id: uuidv4(),
              message: 'Item quantity updated in cart!',
              timestamp: new Date().toISOString(),
            };
            dispatch(addNotification(notification));

            toast.success('Item quantity updated in cart!');
          } else {
            // Else, add as new with attributes
            localCart.push({
              productId,
              attributes: {
                size,
                ingredients: ingredients.map((ing) => ({
                  ingredientId: ing.id, // Ensure consistency
                  quantity: ing.quantity,
                })),
              },
              quantity,
              price,
              imageUrl,
            });

            // Dispatch notification to Redux store
            const notification = {
              id: uuidv4(),
              message: 'Item added to cart!',
              timestamp: new Date().toISOString(),
            };
            dispatch(addNotification(notification));

            toast.success('Item added to cart!');
          }

          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);
          logger.info(`Added product ID ${productId} to local cart for unauthenticated user`);
        } catch (error) {
          logger.error('Failed to add item to local cart:', error);
          toast.error('Failed to add item to cart. Please try again.');
        }
      }
    },
    [token, cartItems, user?.id, dispatch]
  );

  /**
   * Removes an item from the cart.
   * For authenticated users, sends a DELETE request to the backend.
   * For unauthenticated users, updates local storage.
   * @param {Object} item - The item to remove.
   * @param {number} item.id - The ID of the cart item (for authenticated users).
   * @param {number} [item.index] - The index of the cart item (for unauthenticated users).
   */
  const removeItemFromCart = useCallback(
    async (item) => {
      if (token) {
        try {
          await api.delete(`/api/cart/${item.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setCartItems((prevCart) => prevCart.filter((ci) => ci.id !== item.id));
          logger.info(`Removed cart item ID ${item.id} for user ID ${user?.id || 'unknown'}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item removed from cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item removed from cart.');
        } catch (error) {
          logger.error(`Failed to remove cart item ID ${item.id}:`, error);
          toast.error('Failed to remove item from cart. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const updatedCart = localCart.filter((ci, index) => index !== item.index);
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          setCartItems(updatedCart);
          logger.info(`Removed cart item index ${item.index} from local cart for unauthenticated user`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item removed from cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item removed from cart.');
        } catch (error) {
          logger.error(`Failed to remove cart item index ${item.index} from local storage:`, error);
          toast.error('Failed to remove item from cart. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Increases the quantity of an ingredient in a cart item.
   * @param {Object} cartItem - The cart item containing the ingredient.
   * @param {number|string} ingredientId - The ID of the ingredient to increase.
   */
  const increaseIngredientQuantity = useCallback(
    async (cartItem, ingredientId) => {
      if (token) {
        try {
          // Find the ingredient in the cart item
          const updatedIngredients = cartItem.attributes.ingredients.map((ingredient) => {
            if (ingredient.ingredientId === ingredientId) {
              return { ...ingredient, quantity: ingredient.quantity + 1 };
            }
            return ingredient;
          });

          // Update the cart item on the server
          const response = await api.put(
            `/api/cart/${cartItem.id}`,
            { attributes: { ...cartItem.attributes, ingredients: updatedIngredients } },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Update local cart state
          setCartItems((prevCart) =>
            prevCart.map((item) => (item.id === cartItem.id ? response.data.data : item))
          );
          logger.info(
            `Increased quantity of ingredient ID ${ingredientId} in cart item ID ${cartItem.id} for user ID ${
              user?.id || 'unknown'
            }`
          );

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity increased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity increased.');
        } catch (error) {
          logger.error(`Failed to increase ingredient quantity:`, error);
          toast.error('Failed to increase ingredient quantity. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const itemIndex = cartItem.index;
          const item = localCart[itemIndex];

          // Update the ingredient quantity
          item.attributes.ingredients = item.attributes.ingredients.map((ingredient) => {
            if (ingredient.ingredientId === ingredientId) {
              return { ...ingredient, quantity: ingredient.quantity + 1 };
            }
            return ingredient;
          });

          // Save back to local storage and update state
          localCart[itemIndex] = item;
          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);

          logger.info(
            `Increased quantity of ingredient ID ${ingredientId} in local cart item index ${itemIndex} for unauthenticated user`
          );

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity increased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity increased.');
        } catch (error) {
          logger.error('Failed to increase ingredient quantity in local storage:', error);
          toast.error('Failed to increase ingredient quantity. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Decreases the quantity of an ingredient in a cart item.
   * If the quantity reaches zero, the ingredient is removed from the cart item.
   * @param {Object} cartItem - The cart item containing the ingredient.
   * @param {number|string} ingredientId - The ID of the ingredient to decrease.
   */
  const decreaseIngredientQuantity = useCallback(
    async (cartItem, ingredientId) => {
      if (token) {
        try {
          // Find the ingredient in the cart item
          const updatedIngredients = cartItem.attributes.ingredients
            .map((ingredient) => {
              if (ingredient.ingredientId === ingredientId) {
                if (ingredient.quantity > 1) {
                  return { ...ingredient, quantity: ingredient.quantity - 1 };
                }
                // If quantity is 1, it will be removed
                return null;
              }
              return ingredient;
            })
            .filter(Boolean); // Remove null values (ingredients with quantity 0)

          // Update the cart item on the server
          const response = await api.put(
            `/api/cart/${cartItem.id}`,
            { attributes: { ...cartItem.attributes, ingredients: updatedIngredients } },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Update local cart state
          setCartItems((prevCart) =>
            prevCart.map((item) => (item.id === cartItem.id ? response.data.data : item))
          );
          logger.info(
            `Decreased quantity of ingredient ID ${ingredientId} in cart item ID ${cartItem.id} for user ID ${
              user?.id || 'unknown'
            }`
          );

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity decreased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity decreased.');
        } catch (error) {
          logger.error(`Failed to decrease ingredient quantity:`, error);
          toast.error('Failed to decrease ingredient quantity. Please try again.');
        }
      } else {
        // Handle unauthenticated users by updating local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const itemIndex = cartItem.index;
          const item = localCart[itemIndex];

          // Update the ingredient quantity
          item.attributes.ingredients = item.attributes.ingredients
            .map((ingredient) => {
              if (ingredient.ingredientId === ingredientId) {
                if (ingredient.quantity > 1) {
                  return { ...ingredient, quantity: ingredient.quantity - 1 };
                }
                // If quantity is 1, it will be removed
                return null;
              }
              return ingredient;
            })
            .filter(Boolean); // Remove null values (ingredients with quantity 0)

          // Save back to local storage and update state
          localCart[itemIndex] = item;
          localStorage.setItem('cart', JSON.stringify(localCart));
          setCartItems(localCart);

          logger.info(
            `Decreased quantity of ingredient ID ${ingredientId} in local cart item index ${itemIndex} for unauthenticated user`
          );

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Ingredient quantity decreased.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.success('Ingredient quantity decreased.');
        } catch (error) {
          logger.error('Failed to decrease ingredient quantity in local storage:', error);
          toast.error('Failed to decrease ingredient quantity. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Moves an item to saved-for-later.
   * For authenticated users, updates the item via PUT request.
   * For unauthenticated users, moves the item in local storage.
   * @param {Object} item - The item to move.
   * @param {number} item.id - The ID of the cart item (for authenticated users).
   * @param {number} [item.index] - The index of the cart item (for unauthenticated users).
   */
  const moveToSaved = useCallback(
    async (item) => {
      if (token) {
        try {
          const response = await api.put(
            `/api/cart/${item.id}`,
            { isSavedForLater: true },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCartItems((prevCart) => prevCart.filter((ci) => ci.id !== item.id));
          setSavedItems((prevSaved) => [...prevSaved, response.data.data]);
          logger.info(`Moved cart item ID ${item.id} to saved for later for user ID ${user?.id || 'unknown'}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved to saved for later.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved to saved for later.');
        } catch (error) {
          logger.error(`Failed to move cart item ID ${item.id} to saved for later:`, error);
          toast.error('Failed to move item to saved for later. Please try again.');
        }
      } else {
        // Handle unauthenticated users by moving items in local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const localSaved = JSON.parse(localStorage.getItem('savedItems')) || [];

          const [itemToSave] = localCart.splice(item.index, 1);
          itemToSave.isSavedForLater = true;
          localSaved.push(itemToSave);

          localStorage.setItem('cart', JSON.stringify(localCart));
          localStorage.setItem('savedItems', JSON.stringify(localSaved));
          setCartItems(localCart);
          setSavedItems(localSaved);
          logger.info(`Moved cart item index ${item.index} to saved for later in local storage`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved to saved for later.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved to saved for later.');
        } catch (error) {
          logger.error(`Failed to move cart item index ${item.index} to saved for later in local storage:`, error);
          toast.error('Failed to move item to saved for later. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Moves an item from saved-for-later back to the cart.
   * For authenticated users, updates the item via PUT request.
   * For unauthenticated users, moves the item in local storage.
   * @param {Object} item - The item to move.
   * @param {number} item.id - The ID of the saved cart item (for authenticated users).
   * @param {number} [item.index] - The index of the saved cart item (for unauthenticated users).
   */
  const moveToCart = useCallback(
    async (item) => {
      if (token) {
        try {
          const response = await api.put(
            `/api/cart/${item.id}`,
            { isSavedForLater: false },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSavedItems((prevSaved) => prevSaved.filter((savedItem) => savedItem.id !== item.id));
          setCartItems((prevCart) => [...prevCart, response.data.data]);
          logger.info(`Moved saved cart item ID ${item.id} back to cart for user ID ${user?.id || 'unknown'}`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved back to cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved back to cart.');
        } catch (error) {
          logger.error(`Failed to move saved cart item ID ${item.id} back to cart:`, error);
          toast.error('Failed to move item back to cart. Please try again.');
        }
      } else {
        // Handle unauthenticated users by moving items in local storage
        try {
          const localCart = JSON.parse(localStorage.getItem('cart')) || [];
          const localSaved = JSON.parse(localStorage.getItem('savedItems')) || [];

          const [itemToMove] = localSaved.splice(item.index, 1);
          itemToMove.isSavedForLater = false;
          localCart.push(itemToMove);

          localStorage.setItem('cart', JSON.stringify(localCart));
          localStorage.setItem('savedItems', JSON.stringify(localSaved));
          setCartItems(localCart);
          setSavedItems(localSaved);
          logger.info(`Moved saved cart item index ${item.index} back to cart in local storage`);

          // Dispatch notification to Redux store
          const notification = {
            id: uuidv4(),
            message: 'Item moved back to cart.',
            timestamp: new Date().toISOString(),
          };
          dispatch(addNotification(notification));

          toast.info('Item moved back to cart.');
        } catch (error) {
          logger.error(`Failed to move saved cart item index ${item.index} back to cart in local storage:`, error);
          toast.error('Failed to move item back to cart. Please try again.');
        }
      }
    },
    [token, user?.id, dispatch]
  );

  /**
   * Clears all items from the cart.
   * For authenticated users, sends a DELETE request to the backend.
   * For unauthenticated users, clears local storage.
   */
  const clearCart = useCallback(async () => {
    if (token) {
      try {
        await api.delete(`/api/cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCartItems([]);
        logger.info(`Cleared cart for user ID ${user?.id || 'unknown'}`);

        // Dispatch notification to Redux store
        const notification = {
          id: uuidv4(),
          message: 'Cart cleared.',
          timestamp: new Date().toISOString(),
        };
        dispatch(addNotification(notification));

        toast.info('Cart cleared.');
      } catch (error) {
        logger.error('Failed to clear cart:', error);
        toast.error('Failed to clear cart. Please try again.');
      }
    } else {
      // Handle unauthenticated users by clearing local storage
      try {
        localStorage.removeItem('cart');
        setCartItems([]);
        logger.info('Cleared local cart for unauthenticated user');

        // Dispatch notification to Redux store
        const notification = {
          id: uuidv4(),
          message: 'Cart cleared.',
          timestamp: new Date().toISOString(),
        };
        dispatch(addNotification(notification));

        toast.info('Cart cleared.');
      } catch (error) {
        logger.error('Failed to clear local cart:', error);
        toast.error('Failed to clear cart. Please try again.');
      }
    }
  }, [token, user?.id, dispatch]);

  /**
   * Fetches both cart and saved items when the user or token changes.
   */
  useEffect(() => {
    if (token && user) {
      fetchCart();
      fetchSavedItems();
    }
  }, [token, user, fetchCart, fetchSavedItems]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        savedItems,
        orderType,
        setOrderType: updateOrderType,
        fetchCart,
        fetchSavedItems,
        addItemToCart,
        removeItemFromCart,
        increaseIngredientQuantity,
        decreaseIngredientQuantity,
        moveToSaved,
        moveToCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Define PropTypes for type checking
CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CartContext;
