// frontend/src/components/RecommendedProducts.js

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { getImageUrl } from '../utils/joinUrl';

const RecommendedProducts = ({ products }) => {
  if (products.length === 0) return null;

  return (
    <div className="mt-16">
      <h2 className="text-3xl font-bold text-center mb-8 text-blue-500">Recommended for You</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product) => (
          <motion.div
            key={product.id}
            className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow"
            whileHover={{ scale: 1.05 }}
          >
            <Link to={`/product/${product.slug}`}>
              <img
                src={getImageUrl(product.imageUrl)}
                alt={product.name}
                className="w-full h-48 object-cover"
                loading="lazy"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 hover:text-blue-500 transition-colors">{product.name}</h3>
                <p className="text-lg font-bold text-gray-700 dark:text-gray-300">£{parseFloat(product.price).toFixed(2)}</p>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

RecommendedProducts.propTypes = {
  products: PropTypes.array.isRequired,
};

export default memo(RecommendedProducts);
