// src/components/FacebookLoginButton.js

import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaFacebookF, FaSpinner } from 'react-icons/fa';

const FacebookLoginButton = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID, // Use your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v13.0', // Use the latest version
      });
    };
  }, []);

  const handleFacebookLogin = () => {
    setIsLoading(true);
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          // Send the accessToken to your backend for verification and token generation
          axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/users/facebook-login`, { accessToken })
            .then((res) => {
              if (res.data && res.data.token) {
                localStorage.setItem('token', res.data.token);
                toast.success('Logged in with Facebook successfully!');
                // Optionally, redirect the user
                window.location.href = '/'; // Adjust the redirect path as needed
              } else {
                throw new Error('Invalid response from server.');
              }
            })
            .catch((err) => {
              console.error('Facebook login error:', err);
              toast.error('Facebook login failed. Please try again.');
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          console.error('User cancelled login or did not fully authorize.');
          toast.error('Facebook login failed. Please try again.');
          setIsLoading(false);
        }
      },
      { scope: 'email,public_profile' }
    );
  };

  return (
    <button
      type="button"
      onClick={handleFacebookLogin}
      className={`w-full py-2 px-4 border border-blue-600 rounded-md shadow-sm flex items-center justify-center bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition duration-300 ${
        isLoading ? 'cursor-not-allowed opacity-50' : ''
      }`}
      aria-label="Continue with Facebook"
      disabled={isLoading}
    >
      <FaFacebookF className="w-5 h-5 mr-3 text-blue-600" />
      {isLoading ? (
        <FaSpinner className="animate-spin text-blue-600" />
      ) : (
        <span className="text-blue-600 font-medium">Continue with Facebook</span>
      )}
    </button>
  );
};

export default FacebookLoginButton;
