// frontend/src/components/CartItem.js

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getImageUrl } from '../utils/joinUrl';
import ConfirmationModal from './ConfirmationModal';

const CartItem = ({
  item,
  products,
  onRemove,
  onIncreaseQuantity,
  onDecreaseQuantity,
  onIncreaseIngredient,
  onDecreaseIngredient,
}) => {
  const product = products.find((p) => p.id === item.productId);

  if (!product) {
    return null;
  }

  const handleRemove = () => {
    onRemove(item);
  };

  const calculateItemTotal = () => {
    let total = parseFloat(product.price) * item.quantity;

    if (item.attributes?.ingredients) {
      item.attributes.ingredients.forEach((ing) => {
        const ingredient = product.ingredients.find((i) => i.id === ing.ingredientId);
        if (ingredient) {
          total += parseFloat(ingredient.price) * ing.quantity * item.quantity;
        }
      });
    }

    return total.toFixed(2);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col lg:flex-row items-center bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
    >
      {/* Product Image and Details */}
      <Link to={`/product/${product.slug}`} className="flex-shrink-0">
        <img
          src={getImageUrl(product.imageUrl)}
          alt={product.name}
          className="w-24 h-24 object-cover rounded"
          loading="lazy"
        />
      </Link>
      <div className="ml-0 lg:ml-6 flex-1">
        <Link to={`/product/${product.slug}`}>
          <h2 className="text-xl font-semibold hover:underline">{product.name}</h2>
        </Link>
        {item.attributes?.size && (
          <p className="text-sm text-gray-600 dark:text-gray-400">Size: {item.attributes.size}</p>
        )}
        {item.attributes?.ingredients && item.attributes.ingredients.length > 0 && (
          <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Ingredients:
            <ul className="list-disc list-inside">
              {item.attributes.ingredients.map((ing) => {
                const ingredient = product.ingredients.find((i) => i.id === ing.ingredientId);
                return (
                  <li key={ing.ingredientId} className="flex items-center justify-between">
                    <span>{ingredient ? ingredient.name : 'Unknown Ingredient'}</span>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => onDecreaseIngredient(item, ing.ingredientId)}
                        className="p-1 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        aria-label={`Decrease ${ingredient ? ingredient.name : 'ingredient'} quantity`}
                      >
                        <FaMinus size={12} />
                      </button>
                      <span>{ing.quantity}</span>
                      <button
                        onClick={() => onIncreaseIngredient(item, ing.ingredientId)}
                        className="p-1 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        aria-label={`Increase ${ingredient ? ingredient.name : 'ingredient'} quantity`}
                      >
                        <FaPlus size={12} />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      {/* Quantity Controls and Total Price */}
      <div className="mt-4 lg:mt-0 flex flex-col items-center space-y-4 lg:space-y-2">
        {/* Quantity Controls */}
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onDecreaseQuantity(item)}
            className="p-2 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            aria-label="Decrease quantity"
          >
            <FaMinus />
          </button>
          <span className="font-bold">{item.quantity}</span>
          <button
            onClick={() => onIncreaseQuantity(item)}
            className="p-2 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            aria-label="Increase quantity"
          >
            <FaPlus />
          </button>
        </div>
        {/* Total Price */}
        <div>
          <span className="font-semibold">£{calculateItemTotal()}</span>
        </div>
        {/* Remove Item */}
        <button
          onClick={handleRemove}
          className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
          aria-label="Remove item"
        >
          <FaTrash />
        </button>
      </div>
    </motion.div>
  );
};

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onIncreaseQuantity: PropTypes.func.isRequired,
  onDecreaseQuantity: PropTypes.func.isRequired,
  onIncreaseIngredient: PropTypes.func.isRequired,
  onDecreaseIngredient: PropTypes.func.isRequired,
};

export default memo(CartItem);
