// frontend/src/components/CreateIngredientModal.js

import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

const CreateIngredientModal = ({ isOpen, onClose, onCreate }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic Validation
    if (!name.trim() || !price.trim()) {
      toast.error('Please fill out all fields.');
      return;
    }

    const priceValue = parseFloat(price);
    if (isNaN(priceValue) || priceValue <= 0) {
      toast.error('Please enter a valid positive number for price.');
      return;
    }

    // Call the onCreate prop with the new ingredient data
    await onCreate({
      name: name.trim(),
      price: priceValue.toFixed(2),
    });

    // Reset fields and close modal
    setName('');
    setPrice('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-300 hover:text-white text-xl focus:outline-none"
          aria-label="Close Modal"
        >
          <FaTimes />
        </button>

        <h3 className="text-xl font-bold mb-4 text-white">Create New Ingredient</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Ingredient Name */}
          <div>
            <label className="block text-white font-semibold">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., Cheese"
            />
          </div>

          {/* Ingredient Price */}
          <div>
            <label className="block text-white font-semibold">Price (£)</label>
            <input
              type="number"
              step="0.01"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full p-2 mt-1 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              placeholder="e.g., 1.50"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              aria-label="Create Ingredient"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateIngredientModal;
