// frontend/src/pages/ViewCart.js

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../components/contexts/StoreContext';
import { useCart } from '../components/contexts/CartContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FaMinus,
  FaPlus,
  FaTrash,
  FaShoppingCart,
  FaHeart,
  FaShareAlt,
} from 'react-icons/fa';
import { getImageUrl } from '../utils/joinUrl';
import CartItem from '../components/CartItem';
import CartSummary from '../components/CartSummary';
import EmptyCart from '../components/EmptyCart';
import RecommendedProducts from '../components/RecommendedProducts';
import ConfirmationModal from '../components/ConfirmationModal';
// import OrderTypeSelector from '../components/OrderTypeSelector'; // Removed import

const ViewCart = () => {
  const {
    products,
    fetchProducts,
    relatedProducts,
    fetchRelatedProducts,
  } = useStore();

  const {
    cartItems,
    fetchCart,
    addItemToCart,
    removeItemFromCart,
    increaseIngredientQuantity,
    decreaseIngredientQuantity,
    clearCart,
    moveToSaved,
    moveToCart,
    // orderType, // Removed
    // setOrderType, // Removed
  } = useCart();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [redeemPoints, setRedeemPoints] = useState(false);
  const [loyaltyPoints, setLoyaltyPoints] = useState(100);
  const [modal, setModal] = useState({ isOpen: false, item: null, action: null });

  /**
   * Initialize the cart and related products.
   */
  useEffect(() => {
    const initialize = async () => {
      try {
        await fetchProducts({ page: 1, limit: 100 });
        await fetchRelatedProducts();
        await fetchCart();
      } catch (error) {
        console.error('Initialization Error:', error);
        toast.error('Failed to load cart or products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    initialize();
  }, [fetchProducts, fetchRelatedProducts, fetchCart]);

  /**
   * Apply discount based on the provided code.
   */
  const applyDiscount = useCallback(
    (code) => {
      const trimmedCode = code.trim().toUpperCase();
      switch (trimmedCode) {
        case 'SAVE10':
          setDiscount(10);
          toast.success('£10 discount applied!');
          break;
        case 'FREESHIP':
          setDiscount(5);
          toast.success('£5 discount applied!');
          break;
        default:
          setDiscount(0);
          toast.error('Invalid discount code.');
      }
    },
    []
  );

  /**
   * Toggle loyalty points redemption.
   */
  const toggleRedeemPoints = useCallback(() => {
    setRedeemPoints((prev) => !prev);
  }, []);

  /**
   * Calculate the total amount for the cart.
   */
  const calculateCartTotal = useCallback(() => {
    const total = cartItems.reduce((acc, item) => {
      const product = products.find((p) => p.id === item.productId);
      if (!product) return acc;

      let itemTotal = parseFloat(product.price) * item.quantity;

      if (item.attributes?.ingredients) {
        item.attributes.ingredients.forEach((ing) => {
          const ingredient = product.ingredients.find((i) => i.id === ing.ingredientId);
          if (ingredient) {
            itemTotal += parseFloat(ingredient.price) * ing.quantity * item.quantity;
          }
        });
      }

      return acc + itemTotal;
    }, 0);

    // Removed delivery fee logic

    return total;
  }, [cartItems, products]);

  /**
   * Handle proceeding to checkout.
   */
  const proceedToCheckout = useCallback(() => {
    if (cartItems.length === 0) {
      toast.error('Your cart is empty.');
      return;
    }
    navigate('/checkout');
  }, [cartItems.length, navigate]);

  /**
   * Handle removal of a cart item with confirmation.
   */
  const handleRemoveItem = useCallback((item) => {
    setModal({ isOpen: true, item, action: 'remove' });
  }, []);

  /**
   * Handle clearing the entire cart with confirmation.
   */
  const handleClearCart = useCallback(() => {
    setModal({ isOpen: true, item: null, action: 'clear' });
  }, []);

  /**
   * Confirm modal action.
   */
  const confirmAction = useCallback(async () => {
    const { item, action } = modal;

    if (action === 'remove' && item) {
      try {
        await removeItemFromCart(item);
        toast.success('Item removed from cart!');
      } catch (error) {
        console.error('Error removing item:', error);
        toast.error('Failed to remove item from cart.');
      }
    }

    if (action === 'clear') {
      try {
        await clearCart();
        toast.success('Cart cleared!');
      } catch (error) {
        console.error('Error clearing cart:', error);
        toast.error('Failed to clear cart.');
      }
    }

    setModal({ isOpen: false, item: null, action: null });
  }, [modal, removeItemFromCart, clearCart]);

  /**
   * Cancel modal action.
   */
  const cancelAction = useCallback(() => {
    setModal({ isOpen: false, item: null, action: null });
  }, []);

  /**
   * Memoized total calculation.
   */
  const totalAmount = useMemo(() => calculateCartTotal(), [calculateCartTotal]);

  /**
   * Memoized final total after discounts and loyalty points.
   */
  const finalTotal = useMemo(() => {
    const pointsValue = redeemPoints ? loyaltyPoints * 0.01 : 0;
    return (totalAmount - discount - pointsValue).toFixed(2);
  }, [totalAmount, discount, redeemPoints, loyaltyPoints]);

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 min-h-screen">
      <ToastContainer />
      <ConfirmationModal
        isOpen={modal.isOpen}
        onConfirm={confirmAction}
        onCancel={cancelAction}
        title={modal.action === 'remove' ? 'Remove Item' : 'Clear Cart'}
        message={
          modal.action === 'remove'
            ? 'Are you sure you want to remove this item from your cart?'
            : 'Are you sure you want to clear your entire cart?'
        }
      />

      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex items-center justify-center mb-8"
      >
        <FaShoppingCart className="text-4xl mr-2 text-blue-500" />
        <h1 className="text-4xl font-bold">Your Shopping Cart</h1>
      </motion.div>

      {/* Order Type Indicator */}
      <div className="flex items-center justify-center mb-6">
        <span className="px-4 py-1 bg-green-100 text-green-800 rounded-full text-sm font-semibold">
          Collection Only
        </span>
      </div>

      {loading ? (
        // Loading Spinner
        <div className="flex justify-center items-center">
          <ClipLoader color="#1D4ED8" loading={loading} size={150} />
        </div>
      ) : cartItems.length > 0 ? (
        // Cart Content
        <div className="flex flex-col lg:flex-row">
          {/* Cart Items */}
          <div className="lg:w-2/3 mr-0 lg:mr-8">
            <div className="space-y-6">
              <AnimatePresence>
                {cartItems.map((item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    products={products}
                    onRemove={handleRemoveItem}
                    onIncreaseQuantity={addItemToCart}
                    onDecreaseQuantity={decreaseIngredientQuantity}
                    onIncreaseIngredient={increaseIngredientQuantity}
                    onDecreaseIngredient={decreaseIngredientQuantity}
                  />
                ))}
              </AnimatePresence>
            </div>
            {/* Clear Cart Button */}
            <div className="mt-6 flex justify-end">
              <button
                onClick={handleClearCart}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                aria-label="Clear entire cart"
              >
                Clear Cart
              </button>
            </div>
          </div>

          {/* Cart Summary */}
          <div className="lg:w-1/3">
            {/* <OrderTypeSelector orderType={orderType} setOrderType={setOrderType} /> */} {/* Removed */}
            <CartSummary
              total={totalAmount}
              discount={discount}
              redeemPoints={redeemPoints}
              loyaltyPoints={loyaltyPoints}
              finalTotal={finalTotal}
              onApplyDiscount={applyDiscount}
              onToggleRedeemPoints={toggleRedeemPoints}
              onProceedToCheckout={proceedToCheckout}
              orderType="collection" // Fixed order type to 'collection'
            />
          </div>
        </div>
      ) : (
        // Empty Cart State
        <EmptyCart />
      )}

      {/* Recommended Products */}
      <RecommendedProducts products={relatedProducts} />
    </div>
  );
};

export default ViewCart;
