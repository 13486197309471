// frontend/src/utils/joinUrl.js

import config from '../components/contexts/config'; // Adjust the path based on your project structure

/**
 * Utility function to safely join multiple URL parts.
 * Ensures that there is exactly one slash between each part.
 * @param  {...string} parts - The URL segments to join.
 * @returns {string} - The concatenated URL.
 */
export const joinUrl = (...parts) => {
  return parts
    .map((part, index) => {
      if (index === 0) {
        // Remove trailing slashes from the first part
        return part.replace(/\/+$/, '');
      } else {
        // Remove leading and trailing slashes from subsequent parts
        return part.replace(/^\/+|\/+$/g, '');
      }
    })
    .filter(Boolean)
    .join('/');
};

/**
 * Utility function to get full image URL
 * @param {string} imagePath - Path to the image
 * @returns {string} - Full image URL
 */
export const getImageUrl = (imagePath) => {
  if (!imagePath) return '/default-image.jpg'; // Fallback to a default image

  // If imagePath is an absolute URL, return it as is
  if (/^(http|https):\/\//i.test(imagePath)) {
    return imagePath;
  }

  return joinUrl(config.API_URL, imagePath);
};
