// src/pages/Store.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useStore } from '../components/contexts/StoreContext';
import { useCart } from '../components/contexts/CartContext';
import { useUserContext } from '../components/contexts/UserContext';
import StoreHeader from '../components/StoreHeader';
import {
  FaTimes,
  FaShoppingCart,
  FaStar,
  FaRegEye,
  FaThLarge,
  FaList,
  FaBars, // Hamburger menu icon for mobile
} from 'react-icons/fa';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify'; // Removed ToastContainer import
import debounce from 'lodash.debounce';
import config from '../components/contexts/config';
import { useLocation, useParams } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { joinUrl } from '../utils/joinUrl';
import ProductCard from '../components/ProductCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Autosuggest from 'react-autosuggest';
import AddToCartModal from '../components/AddToCartModal';
import CatBar from '../components/CatBar'; // Sidebar component
import { useDispatch } from 'react-redux';
// Removed the incorrect import
// import { removeNotification } from '../features/notifcation/notificationsSlice'; // Corrected path
// Removed the addNotification and uuidv4 imports
// import { addNotification } from '../features/notifcation/notificationsSlice'; // Correct path
// import { v4 as uuidv4 } from 'uuid'; // Import uuid

// Define Autosuggest theme for consistent styling
const autosuggestTheme = {
  container: 'relative',
  containerOpen:
    'absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1',
  input:
    'w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-pink-500',
  inputOpen: '',
  inputFocused: '',
  suggestionsContainer:
    'absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-y-auto',
  suggestionsContainerOpen: '',
  suggestionsList: 'list-none p-0 m-0',
  suggestion: 'px-4 py-2 cursor-pointer hover:bg-gray-100',
  suggestionHighlighted: 'bg-gray-200',
  sectionContainer: '',
  sectionContainerFirst: '',
  sectionTitle: '',
};

const Store = () => {
  // Destructure necessary functions and states from StoreContext
  const {
    products,
    fetchProducts,
    fetchStoreProductsPublic, // Added for unauthenticated fetching
    fetchStoreDetailsPublic, // Added for unauthenticated fetching
    fetchStoreDetails, // For authenticated fetching
    relatedProducts,
    fetchRelatedProducts,
    reviews,
    fetchProductReviews,
    submitReview,
    userReview,
    setUserReview,
    checkUserPurchase,
    fetchUserReview,
    addItemToWishlist,
    loading,
    error,
    pagination,
    searchResults,
    searchProducts,
    setSearchResults,
    deleteProduct,
    updateProduct,
    fetchProductWithIngredientsById,
    getProductWithIngredients,
    // popularCategories, // Removed as categories are now derived from products
  } = useStore();

  const { addItemToCart } = useCart();
  const { user } = useUserContext();
  const location = useLocation();
  const { id: storeId } = useParams();

  const dispatch = useDispatch(); // Initialize dispatch

  // Local state management
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [userHasPurchased, setUserHasPurchased] = useState(false);
  const [showSocialProof, setShowSocialProof] = useState(false);
  const [recentProduct, setRecentProduct] = useState(null);
  const [store, setStore] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [featuredProducts, setFeaturedProducts] = useState([]);

  // Search Bar States
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // View Mode State
  const [viewMode, setViewMode] = useState('grid'); // Default to 'grid'

  // CatBar (Sidebar) State for Mobile
  const [isCatBarOpen, setIsCatBarOpen] = useState(false);

  // Utility function to check if a URL is absolute
  const isAbsoluteUrl = (url) => /^https?:\/\//i.test(url);

  /**
   * Fetch categories, store details, and featured products on component mount
   */
  useEffect(() => {
    const getStoreDetails = async () => {
      setIsLoadingDetails(true);
      try {
        if (user) {
          // Authenticated user
          const storeDetails = await fetchStoreDetails(storeId);
          setStore(storeDetails);
          // Assuming storeDetails has a featuredProducts array
          if (storeDetails.featuredProducts) {
            setFeaturedProducts(storeDetails.featuredProducts);
          }
        } else {
          // Unauthenticated user
          const storeDetails = await fetchStoreDetailsPublic(storeId);
          setStore(storeDetails);
          // Assuming storeDetails has a featuredProducts array
          if (storeDetails.featuredProducts) {
            setFeaturedProducts(storeDetails.featuredProducts);
          }
          // Fetch products publicly
          await fetchStoreProductsPublic(storeId, {
            page: currentPage,
            limit: 12,
          });
        }
      } catch (error) {
        toast.error(`Failed to fetch store details: ${error.message}`);
        console.error('Error fetching store details:', error);
      } finally {
        setIsLoadingDetails(false);
      }
    };

    getStoreDetails();
  }, [
    fetchStoreDetails,
    fetchStoreDetailsPublic,
    fetchStoreProductsPublic,
    storeId,
    user,
    currentPage,
  ]);

  /**
   * Debounce fetchProducts to prevent excessive API calls
   */
  const debouncedFetchProducts = useCallback(
    debounce(() => {
      if (user) {
        // Authenticated user
        fetchProducts({
          storeId,
          categoryId: selectedCategory,
          sortOption: '', // No sort option
          page: currentPage,
          limit: 12,
        });
      } else {
        // Unauthenticated user
        fetchStoreProductsPublic(storeId, {
          categoryId: selectedCategory,
          sortOption: '',
          page: currentPage,
          limit: 12,
        });
      }
    }, 500),
    [
      fetchProducts,
      fetchStoreProductsPublic,
      storeId,
      selectedCategory,
      currentPage,
      user,
    ]
  );

  /**
   * Fetch products when storeId, selectedCategory, or currentPage changes
   */
  useEffect(() => {
    debouncedFetchProducts();
  }, [debouncedFetchProducts]);

  /**
   * Handle URL changes to fetch products based on search query
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('query');

    if (query && query.trim() !== '') {
      searchProducts(query);
      setCurrentPage(1);
    } else {
      if (user) {
        fetchProducts({
          storeId,
          categoryId: selectedCategory,
          sortOption: '',
          page: currentPage,
          limit: 12,
        });
      } else {
        fetchStoreProductsPublic(storeId, {
          categoryId: selectedCategory,
          sortOption: '',
          page: currentPage,
          limit: 12,
        });
      }
      setSearchResults([]);
    }
  }, [
    location.search,
    searchProducts,
    fetchProducts,
    fetchStoreProductsPublic,
    storeId,
    selectedCategory,
    currentPage,
    setSearchResults,
    user,
  ]);

  /**
   * Handle category change from CatBar
   */
  const handleCategoryChange = (categoryId) => {
    const newCategory = categoryId === 'all' ? '' : categoryId;
    setSelectedCategory(newCategory);
    setCurrentPage(1);
    const params = new URLSearchParams(location.search);
    params.delete('query');
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${params.toString()}`
    );

    // No need to refetch products as all products are already fetched
    // Instead, rely on the filtered view based on selectedCategory
  };

  /**
   * Define the handlePageChange function for pagination
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(location.search);
    params.delete('query');
    window.history.replaceState(
      {},
      '',
      `${location.pathname}?${params.toString()}`
    );
    // No need to refetch products as all products are already fetched
  };

  /**
   * Handle Wishlist Addition
   */
  const handleWishlist = async (productId) => {
    if (!user) {
      toast.error('You must be logged in to add items to the wishlist.');
      return;
    }
    try {
      await addItemToWishlist(productId);
      toast.info('Product added to wishlist!');
    } catch (error) {
      toast.error(`Failed to add to wishlist: ${error.message}`);
      console.error('Error adding to wishlist:', error);
    }
  };

  /**
   * Live Search with Autocomplete Handlers
   */
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : products.filter(
          (prod) =>
            prod.name.toLowerCase().includes(inputValue) ||
            (prod.description &&
              prod.description.toLowerCase().includes(inputValue))
        );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValueFn = (suggestion) => suggestion.name;

  const renderSuggestionFn = (suggestion) => (
    <div
      className="cursor-pointer"
      onClick={() => handleProductClick(suggestion)}
    >
      {suggestion.name}
    </div>
  );

  const handleSearchChange = (event, { newValue }) => {
    setSearchValue(newValue);
  };

  /**
   * Social Proof Notification Logic
   */
  useEffect(() => {
    const simulateRecentPurchase = () => {
      if (products.length === 0) return;

      const interval = setInterval(() => {
        const randomProduct =
          products[Math.floor(Math.random() * products.length)];
        setRecentProduct(randomProduct);
        setShowSocialProof(true);
        setTimeout(() => {
          setShowSocialProof(false);
        }, 5000);
      }, 10000); // Every 10 seconds

      return () => clearInterval(interval);
    };

    simulateRecentPurchase();
  }, [products]);

  /**
   * Slider Settings for Featured Products Carousel
   */
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(4, featuredProducts.length),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: Math.min(3, featuredProducts.length),
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, featuredProducts.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, featuredProducts.length),
          slidesToScroll: 1,
        },
      },
    ],
  };

  /**
   * Handle product click to view details in modal
   */
  const handleProductClick = useCallback(
    async (product) => {
      console.log('Product clicked:', product);
      setIsLoadingDetails(true);
      try {
        // Fetch full product details including ingredients
        const fullProductDetails = await fetchProductWithIngredientsById(
          product.id
        );
        console.log('Selected Product Details:', fullProductDetails);
        if (!fullProductDetails) {
          throw new Error('Failed to fetch product details');
        }
        // Instead of opening a global modal, this is now handled within ProductCard
        // No action needed here
        await fetchRelatedProducts(product.id);
        await fetchProductReviews(product.id);

        if (user) {
          const hasPurchased = await checkUserPurchase(product.id, user.id);
          setUserHasPurchased(hasPurchased);
          if (hasPurchased) {
            const userReviewData = await fetchUserReview(product.id, user.id);
            if (userReviewData) {
              setUserReview({
                rating: userReviewData.rating,
                comment: userReviewData.comment,
              });
            }
          }
        }

        // Reset size and ingredients when opening a new modal
        // (This is handled within ProductCard)
      } catch (error) {
        console.error('Error in handleProductClick:', error);
        toast.error(`Failed to fetch product details: ${error.message}`);
      } finally {
        setIsLoadingDetails(false);
      }
    },
    [
      fetchProductWithIngredientsById,
      fetchRelatedProducts,
      fetchProductReviews,
      user,
      checkUserPurchase,
      fetchUserReview,
      setUserReview,
    ]
  );

  /**
   * Group products by category for organized display
   */
  const groupedProducts = useMemo(() => {
    if (products.length === 0) return {};

    return products.reduce((acc, product) => {
      const categoryId = product.categoryId || (product.category && product.category.id);
      const categoryName = product.categoryName || (product.category && product.category.name);

      if (!categoryId || !categoryName) return acc; // Skip products without category

      if (!acc[categoryId]) {
        acc[categoryId] = {
          categoryId,
          categoryName,
          products: [],
        };
      }

      acc[categoryId].products.push(product);
      return acc;
    }, {});
  }, [products]);

  /**
   * Toggle CatBar (Sidebar) for Mobile Devices
   */
  const toggleCatBar = () => {
    setIsCatBarOpen((prev) => !prev);
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-gray-100">
      {/* Scrolling Promotional Marquee */}
      <Marquee
        gradient={false}
        speed={60}
        className="bg-gradient-to-r from-pink-500 to-purple-500 text-white font-semibold py-3 text-md"
        pauseOnHover={true}
      >
        <span className="mx-8">🎉 15% OFF on all orders above £15! 🎉</span>
        <span className="mx-8">Free delivery on orders over £30!</span>
        <span className="mx-8">New seasonal desserts available now!</span>
      </Marquee>

      {/* Main Content with Flex Layout */}
      <div className="flex">
        {/* CatBar (Sidebar) */}
        <CatBar
          categories={useMemo(() => {
            return Object.values(groupedProducts).map((group) => ({
              id: group.categoryId,
              name: group.categoryName,
            }));
          }, [groupedProducts])}
          // popularCategories={popularCategories} // Removed as categories are derived
          selectedCategory={selectedCategory}
          onSelectCategory={handleCategoryChange}
          isOpen={isCatBarOpen}
          toggleSidebar={toggleCatBar}
          // Optional: Pass favorites and toggle function if implementing favorites
          favorites={[]} // Example: []
          onToggleFavorite={null} // Example: null
        />

        {/* Main Content Area */}
        <main className="flex-1 container mx-auto px-6 py-8">
          {/* Mobile Hamburger Menu */}
          <div className="flex items-center justify-between mb-4 md:hidden">
            <h1 className="text-2xl font-bold">Store</h1>
            <button
              onClick={toggleCatBar}
              className="text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white focus:outline-none"
              aria-label="Open Categories"
            >
              <FaBars size={24} />
            </button>
          </div>

          {/* Store Header */}
          {store && <StoreHeader store={store} />}

          {/* Featured Products Carousel */}
          {featuredProducts.length > 0 && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4 dark:text-gray-300">Featured Products</h2>
              <Slider {...sliderSettings}>
                {featuredProducts.map((product) => (
                  <div key={product.id} className="px-2">
                    <ProductCard
                      product={product}
                      handleWishlist={user ? () => handleWishlist(product.id) : null}
                      isAdmin={user && user.role === 'admin'}
                      viewMode="grid" // Featured products are shown in grid view
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {/* View Toggle Buttons */}
          <div className="flex items-center mb-6">
            <span className="text-gray-700 font-semibold mr-4 dark:text-gray-300">
              View:
            </span>
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded-l-md ${
                viewMode === 'grid'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
              } focus:outline-none`}
              aria-label="Grid View"
            >
              <FaThLarge />
            </button>
            <button
              onClick={() => setViewMode('list')}
              className={`p-2 rounded-r-md ${
                viewMode === 'list'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
              } focus:outline-none`}
              aria-label="List View"
            >
              <FaList />
            </button>
          </div>

          {/* Search Bar */}
          <div className="mb-6">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValueFn}
              renderSuggestion={renderSuggestionFn}
              inputProps={{
                placeholder: 'Search for delicious desserts...',
                value: searchValue,
                onChange: handleSearchChange,
                className: autosuggestTheme.input,
              }}
              theme={autosuggestTheme}
            />
          </div>

          {/* Products Grid/List */}
          <div className="space-y-8">
            {Object.keys(groupedProducts).map((categoryId) => {
              const category = groupedProducts[categoryId];
              if (category.products.length === 0) return null; // Skip categories with no products

              // If a specific category is selected, skip others
              if (selectedCategory && selectedCategory !== categoryId) return null;

              return (
                <div key={categoryId}>
                  {/* Category Header */}
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-300">
                    {category.categoryName}
                  </h2>

                  {/* Products */}
                  <div
                    className={`${
                      viewMode === 'grid'
                        ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'
                        : 'space-y-4'
                    }`}
                  >
                    {category.products.map((product) => (
                      <ProductCard
                        key={product.id}
                        product={product}
                        handleWishlist={user ? () => handleWishlist(product.id) : null}
                        isAdmin={user && user.role === 'admin'}
                        viewMode={viewMode} // Pass viewMode as a prop
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Pagination */}
          {pagination.totalPages > 1 && (
            <div className="flex justify-center mt-8 space-x-2">
              {Array.from({ length: pagination.totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-4 py-2 rounded-md border ${
                    index + 1 === pagination.currentPage
                      ? 'bg-pink-500 text-white'
                      : 'bg-white text-pink-500 hover:bg-pink-100 dark:bg-gray-700 dark:text-pink-400 dark:hover:bg-gray-600'
                  } transition-colors`}
                  aria-label={`Go to page ${index + 1}`}
                  type="button"
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}

          {/* Social Proof Notification */}
          <AnimatePresence>
            {showSocialProof && recentProduct && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
                className="fixed bottom-4 left-4 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 p-4 rounded-lg shadow-lg z-50 flex items-center space-x-4 max-w-sm"
              >
                <img
                  src={
                    recentProduct.imageUrl
                      ? isAbsoluteUrl(recentProduct.imageUrl)
                        ? recentProduct.imageUrl
                        : joinUrl(config.API_URL, recentProduct.imageUrl)
                      : '/default-image.png'
                  }
                  alt={recentProduct.name}
                  className="w-16 h-16 object-contain rounded-lg"
                />
                <div>
                  <p className="font-bold">{recentProduct.name}</p>
                  <p className="text-sm">was just ordered!</p>
                </div>
                <button
                  className="ml-auto text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white focus:outline-none"
                  onClick={() => setShowSocialProof(false)}
                  aria-label="Close Notification"
                >
                  <FaTimes className="w-4 h-4" />
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </main>
      </div>
    </div>
  );
};

export default Store;
