// src/components/PaymentsManagement.jsx

import React, { useReducer, useEffect, useMemo } from 'react'; // Removed useCallback
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FaSearch,
  FaSort,
  FaTimesCircle,
  FaCheckCircle,
  FaSpinner,
  FaDownload,
  FaChevronLeft,
  FaChevronRight,
  FaMoon,
  FaSun,
} from 'react-icons/fa';
import debounce from 'lodash.debounce';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentsManagement = () => {
  // Initial State for useReducer
  const initialState = {
    payments: [],
    loading: true,
    error: null,
    filters: {
      search: '',
      status: '',
      sort: '',
      startDate: null,
      endDate: null,
    },
    currentPage: 1,
    itemsPerPage: 10,
    totalPages: 1,
    selectedPayment: null,
    isRefundModalOpen: false,
    isDetailModalOpen: false,
    isBulkRefundModalOpen: false,
    bulkRefundCount: 0,
    selectedPayments: [],
    isExporting: false,
    isBulkRefunding: false,
    isDarkMode: false,
    refundReason: '',
    customRefundReason: '',
  };

  // Reducer Function
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_PAYMENTS':
        return { ...state, payments: action.payload.payments, totalPages: action.payload.totalPages, loading: false };
      case 'SET_LOADING':
        return { ...state, loading: action.payload };
      case 'SET_ERROR':
        return { ...state, error: action.payload, loading: false };
      case 'SET_FILTERS':
        return { ...state, filters: { ...state.filters, ...action.payload }, currentPage: 1 };
      case 'SET_CURRENT_PAGE':
        return { ...state, currentPage: action.payload };
      case 'SELECT_PAYMENT':
        return {
          ...state,
          selectedPayments: state.selectedPayments.includes(action.payload)
            ? state.selectedPayments.filter((id) => id !== action.payload)
            : [...state.selectedPayments, action.payload],
        };
      case 'SELECT_ALL_PAYMENTS':
        return {
          ...state,
          selectedPayments: action.payload.selected
            ? state.payments.map((p) => p.paymentIntentId)
            : [],
        };
      case 'OPEN_REFUND_MODAL':
        return { ...state, isRefundModalOpen: true, selectedPayment: action.payload, refundReason: '', customRefundReason: '' };
      case 'CLOSE_REFUND_MODAL':
        return { ...state, isRefundModalOpen: false, selectedPayment: null, refundReason: '', customRefundReason: '' };
      case 'OPEN_DETAIL_MODAL':
        return { ...state, isDetailModalOpen: true, selectedPayment: action.payload };
      case 'CLOSE_DETAIL_MODAL':
        return { ...state, isDetailModalOpen: false, selectedPayment: null };
      case 'OPEN_BULK_REFUND_MODAL':
        return { ...state, isBulkRefundModalOpen: true, bulkRefundCount: state.selectedPayments.length };
      case 'CLOSE_BULK_REFUND_MODAL':
        return { ...state, isBulkRefundModalOpen: false, bulkRefundCount: 0 };
      case 'SET_EXPORTING':
        return { ...state, isExporting: action.payload };
      case 'SET_BULK_REFUNDING':
        return { ...state, isBulkRefunding: action.payload };
      case 'TOGGLE_DARK_MODE':
        return { ...state, isDarkMode: !state.isDarkMode };
      case 'SET_REFUND_REASON':
        return { ...state, refundReason: action.payload };
      case 'SET_CUSTOM_REFUND_REASON':
        return { ...state, customRefundReason: action.payload };
      default:
        return state;
    }
  };

  // Initialize Reducer
  const [state, dispatch] = useReducer(reducer, initialState);

  // Hooks for URL Synchronization
  const location = useLocation();
  const navigate = useNavigate();

  // Utility to parse query parameters
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  // Effect to initialize filters from URL
  useEffect(() => {
    const query = getQueryParams();
    const search = query.get('search') || '';
    const status = query.get('status') || '';
    const sort = query.get('sort') || '';
    const startDate = query.get('startDate') ? new Date(query.get('startDate')) : null;
    const endDate = query.get('endDate') ? new Date(query.get('endDate')) : null;
    const currentPage = parseInt(query.get('page')) || 1;

    dispatch({
      type: 'SET_FILTERS',
      payload: { search, status, sort, startDate, endDate },
    });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to sync filters and pagination to URL
  useEffect(() => {
    const params = new URLSearchParams();
    if (state.filters.search) params.set('search', state.filters.search);
    if (state.filters.status) params.set('status', state.filters.status);
    if (state.filters.sort) params.set('sort', state.filters.sort);
    if (state.filters.startDate) params.set('startDate', state.filters.startDate.toISOString());
    if (state.filters.endDate) params.set('endDate', state.filters.endDate.toISOString());
    if (state.currentPage !== 1) params.set('page', state.currentPage);

    navigate({ search: params.toString() }, { replace: true });
  }, [state.filters, state.currentPage, navigate]);

  // Effect to handle dark mode on initial load
  useEffect(() => {
    const savedDarkMode = JSON.parse(localStorage.getItem('darkMode'));
    if (savedDarkMode !== null) {
      if (savedDarkMode !== state.isDarkMode) {
        dispatch({ type: 'TOGGLE_DARK_MODE' });
      }
    } else {
      // If no preference saved, check system preference
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDark !== state.isDarkMode) {
        dispatch({ type: 'TOGGLE_DARK_MODE' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to apply dark mode class
  useEffect(() => {
    if (state.isDarkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('darkMode', true);
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('darkMode', false);
    }
  }, [state.isDarkMode]);

  // **Removed useCallback for fetchPayments**

  // Debounced fetchPayments defined within useEffect
  useEffect(() => {
    const fetchPayments = debounce(async () => {
      dispatch({ type: 'SET_LOADING', payload: true });
      dispatch({ type: 'SET_ERROR', payload: null });
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/payments`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          params: {
            ...state.filters,
            page: state.currentPage,
            limit: state.itemsPerPage,
          },
        });
        dispatch({
          type: 'SET_PAYMENTS',
          payload: { payments: response.data.data, totalPages: response.data.pagination.totalPages },
        });
      } catch (error) {
        console.error('Failed to fetch payments:', error);
        dispatch({ type: 'SET_ERROR', payload: 'Failed to load payments.' });
        toast.error('Failed to load payments. Please try again.');
      }
    }, 500);

    fetchPayments();

    // Cleanup debounce on unmount or when dependencies change
    return () => {
      fetchPayments.cancel();
    };
  }, [state.filters, state.currentPage, state.itemsPerPage]);

  // Handlers for Filters
  const handleSearchChange = (e) => dispatch({ type: 'SET_FILTERS', payload: { search: e.target.value } });

  const handleSortChange = (sortKey) => {
    let direction = 'asc';
    if (state.filters.sort === `${sortKey}_asc`) {
      direction = 'desc';
    }
    dispatch({ type: 'SET_FILTERS', payload: { sort: `${sortKey}_${direction}` } });
  };

  const handleStatusChange = (e) => dispatch({ type: 'SET_FILTERS', payload: { status: e.target.value } });

  // Handlers for Pagination
  const handlePageChange = (page) => {
    if (page < 1 || page > state.totalPages) return;
    dispatch({ type: 'SET_CURRENT_PAGE', payload: page });
  };

  // Handlers for Payment Selection
  const handleSelectPayment = (paymentId) => dispatch({ type: 'SELECT_PAYMENT', payload: paymentId });

  const handleSelectAllPayments = (e) => {
    dispatch({
      type: 'SELECT_ALL_PAYMENTS',
      payload: { selected: e.target.checked },
    });
  };

  // Handlers for Modals
  const openRefundModal = (payment) => dispatch({ type: 'OPEN_REFUND_MODAL', payload: payment });
  const closeRefundModal = () => dispatch({ type: 'CLOSE_REFUND_MODAL' });
  const openDetailModal = (payment) => dispatch({ type: 'OPEN_DETAIL_MODAL', payload: payment });
  const closeDetailModal = () => dispatch({ type: 'CLOSE_DETAIL_MODAL' });
  const openBulkRefundModal = () => dispatch({ type: 'OPEN_BULK_REFUND_MODAL' });
  const closeBulkRefundModal = () => dispatch({ type: 'CLOSE_BULK_REFUND_MODAL' });

  // Handler for Single Refund
  const handleSingleRefund = async () => {
    if (!state.refundReason) {
      toast.error('Please select a refund reason.');
      return;
    }

    if (state.refundReason === 'other' && !state.customRefundReason.trim()) {
      toast.error('Please provide a custom refund reason.');
      return;
    }

    try {
      const payload = {
        paymentIntentId: state.selectedPayment.paymentIntentId,
        reason: state.refundReason === 'other' ? 'requested_by_customer' : state.refundReason,
      };

      if (state.refundReason === 'other') {
        payload.customReason = state.customRefundReason;
      }

      // Optimistic UI Update
      const updatedPayments = state.payments.map((payment) =>
        payment.paymentIntentId === state.selectedPayment.paymentIntentId
          ? { ...payment, status: 'refunded' }
          : payment
      );
      dispatch({ type: 'SET_PAYMENTS', payload: { payments: updatedPayments, totalPages: state.totalPages } });
      closeRefundModal();
      toast.success('Refund processed successfully!');

      // Make API Call
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/refund`, payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (!response.data.success) {
        // Revert UI Update on Failure
        const revertedPayments = state.payments.map((payment) =>
          payment.paymentIntentId === state.selectedPayment.paymentIntentId
            ? { ...payment, status: 'succeeded' } // Assuming previous status was 'succeeded'
            : payment
        );
        dispatch({ type: 'SET_PAYMENTS', payload: { payments: revertedPayments, totalPages: state.totalPages } });
        toast.error(response.data.message || 'Failed to process refund.');
      }
    } catch (error) {
      console.error('Failed to process refund:', error);
      toast.error(error.response?.data?.message || 'Failed to process refund.');
    }
  };

  // Handler for Bulk Refund
  const handleBulkRefund = async () => {
    dispatch({ type: 'SET_BULK_REFUNDING', payload: true });
    try {
      // Optimistic UI Update
      const updatedPayments = state.payments.map((payment) =>
        state.selectedPayments.includes(payment.paymentIntentId)
          ? { ...payment, status: 'refunded' }
          : payment
      );
      dispatch({ type: 'SET_PAYMENTS', payload: { payments: updatedPayments, totalPages: state.totalPages } });
      closeBulkRefundModal();
      toast.success(`Refunds processed for ${state.bulkRefundCount} payments!`);

      // Make API Calls
      const refundPromises = state.selectedPayments.map((paymentId) =>
        axios.post(
          `${process.env.REACT_APP_API_URL}/payments/refund`,
          { paymentIntentId: paymentId, reason: 'requested_by_customer' },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        )
      );

      const results = await Promise.allSettled(refundPromises);

      const successes = results.filter((result) => result.status === 'fulfilled' && result.value.data.success).length;
      const failures = results.filter(
        (result) => result.status === 'rejected' || (result.value && !result.value.data.success)
      ).length;

      if (failures > 0) {
        // Revert UI Updates for failed refunds
        const revertedPayments = state.payments.map((payment) =>
          state.selectedPayments.includes(payment.paymentIntentId) && !results.find((r) => r.value?.data?.paymentIntentId === payment.paymentIntentId && r.value.data.success)
            ? { ...payment, status: 'succeeded' } // Assuming previous status was 'succeeded'
            : payment
        );
        dispatch({ type: 'SET_PAYMENTS', payload: { payments: revertedPayments, totalPages: state.totalPages } });
        toast.error(`Failed to process ${failures} refunds.`);
      } else {
        toast.success(`Successfully processed ${successes} refunds.`);
      }

      dispatch({ type: 'SET_BULK_REFUNDING', payload: false });
      dispatch({ type: 'SELECT_ALL_PAYMENTS', payload: { selected: false } });
    } catch (error) {
      console.error('Failed to process bulk refund:', error);
      toast.error('Failed to process bulk refund.');
      dispatch({ type: 'SET_BULK_REFUNDING', payload: false });
    }
  };

  // Handler for Export
  const handleExport = async () => {
    dispatch({ type: 'SET_EXPORTING', payload: true });
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/payments/export`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { ...state.filters },
        responseType: 'blob', // Important for handling binary data
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payments.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Payments exported successfully!');
    } catch (error) {
      console.error('Failed to export payments:', error);
      toast.error('Failed to export payments.');
    } finally {
      dispatch({ type: 'SET_EXPORTING', payload: false });
    }
  };

  // Toggle Dark Mode
  const toggleDarkMode = () => dispatch({ type: 'TOGGLE_DARK_MODE' });

  // Memoized payments data to prevent unnecessary re-renders
  const memoizedPayments = useMemo(() => state.payments, [state.payments]);

  return (
    <div className="p-6 bg-gray-100 dark:bg-gray-900 min-h-screen transition-colors duration-300">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-4xl font-extrabold text-gray-800 dark:text-white">Payments Management</h1>
        <button
          onClick={toggleDarkMode}
          className="flex items-center justify-center p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          aria-label="Toggle Dark Mode"
        >
          {state.isDarkMode ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-gray-800" />}
        </button>
      </div>

      {/* Filters Section */}
      <div className="mb-6 bg-white dark:bg-gray-800 shadow rounded-lg p-6 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
        {/* Left Side: Search and Filters */}
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6 w-full">
          {/* Search Input */}
          <div className="w-full md:w-1/3">
            <label htmlFor="search" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
              Search Payments
            </label>
            <div className="relative">
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-300" />
              <input
                type="text"
                id="search"
                value={state.filters.search}
                onChange={handleSearchChange}
                placeholder="Search by User or Payment ID..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              />
            </div>
          </div>

          {/* Date Range Picker */}
          <div className="flex space-x-4 w-full md:w-1/3">
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                Start Date
              </label>
              <DatePicker
                selected={state.filters.startDate}
                onChange={(date) => dispatch({ type: 'SET_FILTERS', payload: { startDate: date } })}
                selectsStart
                startDate={state.filters.startDate}
                endDate={state.filters.endDate}
                placeholderText="Select Start Date"
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                isClearable
              />
            </div>
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                End Date
              </label>
              <DatePicker
                selected={state.filters.endDate}
                onChange={(date) => dispatch({ type: 'SET_FILTERS', payload: { endDate: date } })}
                selectsEnd
                startDate={state.filters.startDate}
                endDate={state.filters.endDate}
                minDate={state.filters.startDate}
                placeholderText="Select End Date"
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                isClearable
              />
            </div>
          </div>

          {/* Status Filter */}
          <div className="w-full md:w-1/6">
            <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
              Payment Status
            </label>
            <select
              id="status"
              value={state.filters.status}
              onChange={handleStatusChange}
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            >
              <option value="">All Statuses</option>
              <option value="succeeded">Succeeded</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
              <option value="refunded">Refunded</option>
            </select>
          </div>

          {/* Sort Buttons */}
          <div className="w-full md:w-1/3 flex space-x-2">
            <button
              onClick={() => handleSortChange('amount')}
              className={`flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white ${
                state.filters.sort.startsWith('amount')
                  ? 'bg-primary-dark'
                  : 'bg-primary hover:bg-primary-dark'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
            >
              <FaSort className="mr-2" /> Amount
              {state.filters.sort === 'amount_asc' ? ' ↑' : state.filters.sort === 'amount_desc' ? ' ↓' : ''}
            </button>
            <button
              onClick={() => handleSortChange('createdAt')}
              className={`flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white ${
                state.filters.sort.startsWith('createdAt')
                  ? 'bg-primary-dark'
                  : 'bg-primary hover:bg-primary-dark'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
            >
              <FaSort className="mr-2" /> Date
              {state.filters.sort === 'createdAt_asc' ? ' ↑' : state.filters.sort === 'createdAt_desc' ? ' ↓' : ''}
            </button>
          </div>
        </div>

        {/* Bulk Actions */}
        <div className="flex space-x-4 w-full md:w-auto">
          {/* Export Button */}
          <button
            onClick={handleExport}
            disabled={state.isExporting}
            className={`flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white ${
              state.isExporting ? 'bg-green-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          >
            {state.isExporting ? <FaSpinner className="animate-spin mr-2" /> : <FaDownload className="mr-2" />} Export
          </button>

          {/* Bulk Refund Button */}
          <button
            onClick={openBulkRefundModal}
            disabled={state.selectedPayments.length === 0 || state.isBulkRefunding}
            className={`flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white ${
              state.selectedPayments.length === 0 || state.isBulkRefunding
                ? 'bg-red-400 cursor-not-allowed'
                : 'bg-red-500 hover:bg-red-600'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
          >
            {state.isBulkRefunding ? <FaSpinner className="animate-spin mr-2" /> : <FaTimesCircle className="mr-2" />} Bulk Refund
          </button>
        </div>
      </div>

      {/* Payments Table */}
      <div className="overflow-x-auto bg-white dark:bg-gray-800 shadow rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              {/* Checkbox for Bulk Selection */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                <input
                  type="checkbox"
                  checked={state.selectedPayments.length === state.payments.length && state.payments.length > 0}
                  onChange={handleSelectAllPayments}
                  className="h-4 w-4 text-primary border-gray-300 dark:border-gray-600 rounded focus:ring-primary"
                  aria-label="Select all payments"
                />
              </th>
              {/* Payment ID */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Payment ID
              </th>
              {/* Order ID */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Order ID
              </th>
              {/* User */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                User
              </th>
              {/* Amount */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Amount
              </th>
              {/* Status */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Status
              </th>
              {/* Actions */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {state.loading ? (
              // Loading Skeletons
              Array.from({ length: state.itemsPerPage }).map((_, index) => (
                <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Skeleton height={20} width={20} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Skeleton height={20} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Skeleton height={20} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Skeleton height={20} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Skeleton height={20} width={60} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Skeleton height={20} width={80} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Skeleton height={30} width={80} />
                  </td>
                </tr>
              ))
            ) : state.error ? (
              <tr>
                <td colSpan="7" className="py-10 text-center text-red-500">
                  {state.error}
                </td>
              </tr>
            ) : state.payments.length === 0 ? (
              <tr>
                <td colSpan="7" className="py-10 text-center text-gray-500 dark:text-gray-300">
                  No payments found.
                </td>
              </tr>
            ) : (
              memoizedPayments.map((payment) => (
                <tr key={payment.paymentIntentId} className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors">
                  {/* Checkbox */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={state.selectedPayments.includes(payment.paymentIntentId)}
                      onChange={() => handleSelectPayment(payment.paymentIntentId)}
                      className="h-4 w-4 text-primary border-gray-300 dark:border-gray-600 rounded focus:ring-primary"
                      aria-label={`Select payment ${payment.paymentIntentId}`}
                    />
                  </td>
                  {/* Payment ID */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    {payment.paymentIntentId}
                  </td>
                  {/* Order ID */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-primary dark:text-primary-light">
                    {payment.order ? (
                      <a href={`/orders/${payment.order.id}`} className="underline hover:text-primary-dark">
                        {payment.order.id}
                      </a>
                    ) : (
                      <span className="text-gray-400">N/A</span>
                    )}
                  </td>
                  {/* User */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    {payment.user.email}
                  </td>
                  {/* Amount */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    £{payment.amount}
                  </td>
                  {/* Status */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        payment.status === 'succeeded'
                          ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-200'
                          : payment.status === 'pending'
                          ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-200'
                          : payment.status === 'failed'
                          ? 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-200'
                          : payment.status === 'refunded'
                          ? 'bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-200'
                          : 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200'
                      }`}
                    >
                      {payment.status.charAt(0).toUpperCase() + payment.status.slice(1)}
                    </span>
                  </td>
                  {/* Actions */}
                  <td className="px-6 py-4 whitespace-nowrap flex space-x-2">
                    {/* Details Button */}
                    <button
                      onClick={() => openDetailModal(payment)}
                      className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg shadow-sm text-black bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:text-gray-100 dark:bg-blue-600 dark:hover:bg-blue-700"
                      aria-label={`View details of payment ${payment.paymentIntentId}`}
                    >
                      <FaSearch className="mr-1" /> Details
                    </button>
                    {/* Refund Button */}
                    {payment.status !== 'refunded' && (
                      <button
                        onClick={() => openRefundModal(payment)}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        aria-label={`Refund payment ${payment.paymentIntentId}`}
                      >
                        <FaTimesCircle className="mr-1" /> Refund
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {!state.loading && state.payments.length > 0 && (
        <div className="flex justify-between items-center mt-6">
          {/* Previous Button */}
          <button
            onClick={() => handlePageChange(state.currentPage - 1)}
            disabled={state.currentPage === 1}
            className={`flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white ${
              state.currentPage === 1
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-primary hover:bg-primary-dark'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
          >
            <FaChevronLeft className="mr-2" /> Previous
          </button>

          {/* Page Info */}
          <span className="text-sm text-gray-700 dark:text-gray-300">
            Page {state.currentPage} of {state.totalPages}
          </span>

          {/* Next Button */}
          <button
            onClick={() => handlePageChange(state.currentPage + 1)}
            disabled={state.currentPage === state.totalPages}
            className={`flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white ${
              state.currentPage === state.totalPages
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-primary hover:bg-primary-dark'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
          >
            Next <FaChevronRight className="ml-2" />
          </button>
        </div>
      )}

      {/* Payment Details Modal */}
      <Transition appear show={state.isDetailModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeDetailModal}>
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-30">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-lg">
                <div className="flex justify-between items-center mb-6">
                  <Dialog.Title as="h3" className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                    Payment Details
                  </Dialog.Title>
                  <button onClick={closeDetailModal} className="text-gray-400 hover:text-gray-600 focus:outline-none">
                    <FaTimesCircle size={24} />
                  </button>
                </div>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">Payment ID:</span>
                    <span className="text-gray-900 dark:text-gray-100">{state.selectedPayment?.paymentIntentId}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">Order ID:</span>
                    {state.selectedPayment?.order ? (
                      <a href={`/orders/${state.selectedPayment.order.id}`} className="text-primary underline hover:text-primary-dark">
                        {state.selectedPayment.order.id}
                      </a>
                    ) : (
                      <span className="text-gray-400">N/A</span>
                    )}
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">User:</span>
                    <span className="text-gray-900 dark:text-gray-100">{state.selectedPayment?.user.email}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">Amount:</span>
                    <span className="text-gray-900 dark:text-gray-100">£{state.selectedPayment?.amount}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">Status:</span>
                    <span className="capitalize text-gray-900 dark:text-gray-100">{state.selectedPayment?.status}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">Date:</span>
                    <span className="text-gray-900 dark:text-gray-100">{new Date(state.selectedPayment?.createdAt).toLocaleString()}</span>
                  </div>
                  {state.selectedPayment?.receiptUrl && (
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-900 dark:text-gray-100">Receipt:</span>
                      <a
                        href={state.selectedPayment.receiptUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary underline hover:text-primary-dark"
                      >
                        View Receipt
                      </a>
                    </div>
                  )}
                  {/* Additional Details */}
                  {state.selectedPayment?.description && (
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-900 dark:text-gray-100">Description:</span>
                      <span className="text-gray-900 dark:text-gray-100">{state.selectedPayment.description}</span>
                    </div>
                  )}
                  {state.selectedPayment?.metadata && (
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-900 dark:text-gray-100">Metadata:</span>
                      <pre className="text-sm text-gray-700 dark:text-gray-300">{JSON.stringify(state.selectedPayment.metadata, null, 2)}</pre>
                    </div>
                  )}
                </div>
                <button
                  onClick={closeDetailModal}
                  className="mt-6 w-full bg-gray-500 dark:bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Close
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Refund Modal */}
      <Transition appear show={state.isRefundModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeRefundModal}>
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-30">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-lg">
                <div className="flex justify-between items-center mb-6">
                  <Dialog.Title as="h3" className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                    Process Refund
                  </Dialog.Title>
                  <button onClick={closeRefundModal} className="text-gray-400 hover:text-gray-600 focus:outline-none">
                    <FaTimesCircle size={24} />
                  </button>
                </div>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">Payment ID:</span>
                    <span className="text-gray-900 dark:text-gray-100">{state.selectedPayment?.paymentIntentId}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-900 dark:text-gray-100">Order ID:</span>
                    {state.selectedPayment?.order ? (
                      <a href={`/orders/${state.selectedPayment.order.id}`} className="text-primary underline hover:text-primary-dark">
                        {state.selectedPayment.order.id}
                      </a>
                    ) : (
                      <span className="text-gray-400">N/A</span>
                    )}
                  </div>

                  {/* Refund Reason Selection */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                      Refund Reason
                    </label>
                    <select
                      value={state.refundReason}
                      onChange={(e) => dispatch({ type: 'SET_REFUND_REASON', payload: e.target.value })}
                      className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                    >
                      <option value="">Select Refund Reason</option>
                      <option value="duplicate">Duplicate</option>
                      <option value="fraudulent">Fraudulent</option>
                      <option value="requested_by_customer">Requested by Customer</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  {/* Custom Refund Reason */}
                  {state.refundReason === 'other' && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                        Custom Refund Reason
                      </label>
                      <textarea
                        value={state.customRefundReason}
                        onChange={(e) => dispatch({ type: 'SET_CUSTOM_REFUND_REASON', payload: e.target.value })}
                        className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                        placeholder="Provide a custom refund reason..."
                        rows="4"
                      />
                    </div>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end space-x-4 mt-6">
                  <button
                    onClick={closeRefundModal}
                    className="px-4 py-2 bg-gray-500 dark:bg-gray-600 text-white rounded-lg hover:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSingleRefund}
                    className={`flex items-center px-4 py-2 bg-red-600 dark:bg-red-700 text-white rounded-lg hover:bg-red-700 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ${
                      !state.refundReason ||
                      (state.refundReason === 'other' && !state.customRefundReason.trim())
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                    disabled={!state.refundReason || (state.refundReason === 'other' && !state.customRefundReason.trim())}
                  >
                    <FaCheckCircle className="mr-2" /> Confirm Refund
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Bulk Refund Confirmation Modal */}
      <Transition appear show={state.isBulkRefundModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeBulkRefundModal}>
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-30">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-lg">
                <Dialog.Title as="h3" className="text-2xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                  Confirm Bulk Refund
                </Dialog.Title>
                <p className="text-sm text-gray-500 dark:text-gray-300">
                  Are you sure you want to process refunds for <span className="font-semibold">{state.bulkRefundCount}</span> payments? This action cannot be undone.
                </p>

                <div className="flex justify-end space-x-4 mt-6">
                  <button
                    onClick={closeBulkRefundModal}
                    className="px-4 py-2 bg-gray-500 dark:bg-gray-600 text-white rounded-lg hover:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleBulkRefund}
                    className={`flex items-center px-4 py-2 bg-red-600 dark:bg-red-700 text-white rounded-lg hover:bg-red-700 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ${
                      state.isBulkRefunding ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={state.isBulkRefunding}
                  >
                    {state.isBulkRefunding ? <FaSpinner className="animate-spin mr-2" /> : <FaCheckCircle className="mr-2" />} Confirm Refund
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Toast Notifications */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
    </div>
  );
};

export default PaymentsManagement;
