// src/utils/convertUnits.js

/**
 * Converts miles to kilometers.
 * @param {number} miles - Distance in miles.
 * @returns {number} - Distance in kilometers.
 */
export const milesToKilometers = (miles) => {
    return miles * 1.60934;
  };
  