// frontend/src/components/StoreHeader.js

import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import PropTypes from 'prop-types';

const StoreHeader = ({ store }) => {
  return (
    <div className="bg-black text-white py-2 px-4 w-full flex items-center justify-between">
      <div className="flex items-center space-x-4">
        {store.logoUrl && (
          <img
            src={store.logoUrl}
            alt={`${store.name} Logo`}
            className="h-8 w-auto object-contain"
          />
        )}
        <span className="font-semibold text-lg">{store.name}</span>
        <div className="hidden md:flex items-center space-x-2">
          <FaMapMarkerAlt className="text-yellow-500" />
          <span>{`${store.address}, ${store.city}, ${store.postcode}`}</span>
        </div>
      </div>
      <div className="flex items-center space-x-4 text-sm">
        <div className="flex items-center space-x-1">
          <FaPhoneAlt className="text-green-500" />
          <a href={`tel:${store.contactNumber}`} className="underline">
            {store.contactNumber}
          </a>
        </div>
        <div className="flex items-center space-x-1">
          <FaEnvelope className="text-blue-500" />
          <a href={`mailto:${store.email}`} className="underline">
            {store.email}
          </a>
        </div>
        {store.openingHours && (
          <div className="hidden md:flex items-center space-x-1">
            <FaClock className="text-purple-500" />
            <span>{store.openingHours}</span>
          </div>
        )}
      </div>
    </div>
  );
};

StoreHeader.propTypes = {
  store: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    contactNumber: PropTypes.string,
    email: PropTypes.string,
    openingHours: PropTypes.string,
    logoUrl: PropTypes.string,
  }).isRequired,
};

export default StoreHeader;
