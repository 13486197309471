// src/components/ProductPage.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../components/contexts/StoreContext';
import { useCart } from '../components/contexts/CartContext';
import { useUserContext } from '../components/contexts/UserContext';
import { FaShoppingCart, FaHeart, FaStar } from 'react-icons/fa';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IngredientsSelection from '../components/IngredientsSelection';

const ProductPage = () => {
  const { id } = useParams();
  const { user } = useUserContext(); // Removed 'token' from destructuring
  const { addItemToCart, addItemToWishlist } = useCart();
  const {
    fetchProductById,
    productDetails,
    fetchRelatedProducts,
    relatedProducts,
    fetchProductReviews,
    reviews,
    submitReview,
    checkUserPurchase,
    fetchUserReview,
    ingredients: allIngredients,
  } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [userReview, setUserReview] = useState({ rating: 0, comment: '' });
  const [userHasPurchased, setUserHasPurchased] = useState(false);

  useEffect(() => {
    const initializeProductPage = async () => {
      setIsLoading(true);
      const product = await fetchProductById(id);
      await fetchRelatedProducts(id);
      await fetchProductReviews(id);
      if (user) {
        const hasPurchased = await checkUserPurchase(id, user.id);
        setUserHasPurchased(hasPurchased);
        if (hasPurchased) {
          const review = await fetchUserReview(id, user.id);
          if (review) {
            setUserReview({ rating: review.rating, comment: review.comment });
          }
        }
      }

      // Initialize selectedIngredients with product's default ingredients
      if (product && product.ingredients) {
        setSelectedIngredients(product.ingredients.map((ing) => ing.id));
      }

      setIsLoading(false);
    };

    initializeProductPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  /**
   * Handle adding the product to the cart
   */
  const handleAddToCart = () => {
    if (!selectedSize) {
      toast.error('Please select a size.');
      return;
    }

    // Find selected ingredients details
    const selectedIngredientsDetails = allIngredients.filter((ing) =>
      selectedIngredients.includes(ing.id)
    );

    // Calculate total price including selected ingredients
    let totalPrice = parseFloat(productDetails.price);
    selectedIngredientsDetails.forEach((ingredient) => {
      totalPrice += parseFloat(ingredient.price || 0);
    });

    const item = {
      productId: productDetails.id,
      name: productDetails.name,
      size: selectedSize,
      ingredients: selectedIngredientsDetails.map((ing) => ({
        id: ing.id,
        name: ing.name,
        price: ing.price,
      })),
      quantity: 1,
      price: totalPrice.toFixed(2),
      imageUrl: productDetails.imageUrl,
    };
    addItemToCart(item);
    toast.success(`${productDetails.name} (Size: ${selectedSize}) added to cart!`, {
      position: 'bottom-right',
      autoClose: 3000,
    });

    // Reset selections after adding to cart
    setSelectedSize('');
    setSelectedIngredients([]);
  };

  /**
   * Handle adding the product to the wishlist
   */
  const handleWishlist = () => {
    if (!user) {
      toast.error('You must be logged in to add items to wishlist.');
      return;
    }

    addItemToWishlist(productDetails.id);
    toast.info(`${productDetails.name} added to wishlist!`);
  };

  /**
   * Handle submitting a review
   */
  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    if (!userHasPurchased) {
      toast.error('You must purchase the product before reviewing.');
      return;
    }

    if (userReview.rating === 0 || userReview.comment.trim() === '') {
      toast.error('Please provide a rating and comment.');
      return;
    }

    try {
      await submitReview(id, user.id, userReview);
      toast.success('Review submitted successfully!');
      fetchProductReviews(id);
      setUserReview({ rating: 0, comment: '' });
    } catch (error) {
      toast.error('Failed to submit review.');
    }
  };

  /**
   * Handle star rating clicks
   */
  const handleStarClick = (rating) => {
    setUserReview({ ...userReview, rating });
  };

  /**
   * Toggle ingredient selection
   */
  // Removed 'handleIngredientToggle' as it's unused

  /**
   * Loading state
   */
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color={"#123abc"} loading={isLoading} size={150} />
      </div>
    );
  }

  /**
   * Render the product page
   */
  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      {productDetails ? (
        <div className="border rounded-lg p-4 shadow-lg bg-gray-800 text-white">
          <div className="flex flex-col md:flex-row">
            {/* Product Image */}
            <div className="md:w-1/2 flex items-center justify-center">
              <Zoom>
                <img
                  src={`${process.env.REACT_APP_API_URL}${productDetails.imageUrl}`}
                  alt={productDetails.name}
                  className="object-contain h-64 w-full rounded-lg"
                />
              </Zoom>
            </div>

            {/* Product Details */}
            <div className="md:w-1/2 md:pl-8">
              <h1 className="text-3xl font-bold mb-4">{productDetails.name}</h1>
              <p className="mb-4">{productDetails.description}</p>
              <p className="text-lg font-semibold mb-4">£{parseFloat(productDetails.price).toFixed(2)}</p>

              {/* Select Size */}
              {productDetails.sizeOptions && productDetails.sizeOptions.length > 0 && (
                <div className="mb-4">
                  <label className="mr-2 font-semibold">Select Size:</label>
                  <div className="flex flex-wrap">
                    {JSON.parse(productDetails.sizeOptions).map((size, index) => (
                      <button
                        key={index}
                        onClick={() => setSelectedSize(size)}
                        disabled={false} // Implement stock checks if necessary
                        className={`m-1 p-2 rounded border ${
                          selectedSize === size ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                        }`}
                        aria-label={`Select size ${size}`}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {/* Select Ingredients */}
              {productDetails.ingredients && productDetails.ingredients.length > 0 && (
                <div className="mb-4">
                  <IngredientsSelection
                    ingredients={productDetails.ingredients}
                    selectedIngredients={selectedIngredients}
                    setSelectedIngredients={setSelectedIngredients}
                  />
                </div>
              )}

              {/* Action Buttons */}
              <div className="flex space-x-4">
                <button
                  onClick={handleAddToCart}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                  aria-label="Add to Cart"
                >
                  <FaShoppingCart className="mr-2" /> Add to Cart
                </button>
                <button
                  onClick={handleWishlist}
                  className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded flex items-center"
                  aria-label="Add to Wishlist"
                >
                  <FaHeart className="mr-2" /> Wishlist
                </button>
              </div>
            </div>
          </div>

          {/* Related Products */}
          {relatedProducts && relatedProducts.length > 0 && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">Related Products</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {relatedProducts.map((related) => (
                  <div key={related.id} className="border rounded-lg p-4 bg-gray-700">
                    <Zoom>
                      <img
                        src={`${process.env.REACT_APP_API_URL}${related.imageUrl}`}
                        alt={related.name}
                        className="object-contain h-32 w-full rounded-lg mb-2"
                      />
                    </Zoom>
                    <h3 className="text-xl font-bold">{related.name}</h3>
                    <p className="text-lg font-semibold">£{parseFloat(related.price).toFixed(2)}</p>
                    {/* Optionally, add buttons for quick actions */}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Reviews Section */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Reviews</h2>
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <div key={review.id} className="mb-4 bg-gray-700 p-4 rounded-lg">
                  <div className="flex items-center">
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        className={index < review.rating ? 'text-yellow-500' : 'text-gray-300'}
                        size={14}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p>{review.comment}</p>
                </div>
              ))
            ) : (
              <p>No reviews yet.</p>
            )}

            {/* Submit Review */}
            {user && userHasPurchased && (
              <div className="mt-6">
                <h3 className="text-xl font-bold mb-2">Add Your Review</h3>
                <form onSubmit={handleReviewSubmit} className="space-y-4">
                  <div className="flex items-center">
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        className={
                          index < userReview.rating
                            ? 'text-yellow-500 cursor-pointer'
                            : 'text-gray-300 cursor-pointer'
                        }
                        onClick={() => handleStarClick(index + 1)}
                      />
                    ))}
                  </div>
                  <div>
                    <textarea
                      value={userReview.comment}
                      onChange={(e) => setUserReview({ ...userReview, comment: e.target.value })}
                      className="w-full p-2 bg-gray-700 text-white rounded"
                      placeholder="Write your review here..."
                      required
                      aria-label="Write your review"
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit Review
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>Product not found.</p>
      )}
    </div>
  );
};

export default ProductPage;
