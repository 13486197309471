// src/components/BranchesList.js

import React from 'react';

const BranchesList = ({ branches }) => {
  return (
    <div className="mt-12 text-center">
      <h3 className="text-2xl font-semibold text-gray-800 mb-4">Our Branches</h3>
      <p className="text-gray-600">
        {branches.join(', ')}
      </p>
    </div>
  );
};

export default BranchesList;
