// src/components/contexts/OrderContext.js

import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStore } from './StoreContext';
import { useUserContext } from './UserContext';
import api from '../../api';
import logger from '../../utils/logger';
import { toast } from 'react-toastify';

// Create OrderContext
const OrderContext = createContext();

// Custom hook for consuming OrderContext
export const useOrder = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }
  return context;
};

// OrderProvider component
export const OrderProvider = ({ children }) => {
  // Destructure only 'token' from useUserContext to fix ESLint error
  const { token } = useUserContext();
  
  // Destructure 'setOrders' from StoreContext
  const { setOrders } = useStore(); // Assuming StoreContext manages orders
  
  // Local state
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Fetch order details by ID.
   * @param {number} orderId - The ID of the order to fetch.
   * @returns {Promise<Object|null>} - The fetched order or null if failed.
   */
  const fetchOrder = useCallback(
    async (orderId) => {
      if (!token) {
        logger.warn('fetchOrder called without a valid token.');
        setError('Authentication required to fetch order.');
        toast.error('You must be logged in to view orders.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedOrder = response.data.data;
        setOrder(fetchedOrder);
        return fetchedOrder;
      } catch (err) {
        logger.error('Failed to fetch order:', err);
        setError('Failed to fetch order');
        toast.error('Failed to fetch order details.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Create a new order.
   * @param {Object} orderData - The order data.
   * @returns {Promise<Object|null>} - The created order or null if failed.
   */
  const createOrder = useCallback(
    async (orderData) => {
      if (!orderData) {
        setError('Order data is required');
        toast.error('Order data is required.');
        return null;
      }

      if (!token) {
        logger.warn('createOrder called without a valid token.');
        setError('Authentication required to create order.');
        toast.error('You must be logged in to create an order.');
        return null;
      }

      // Validate orderType
      if (!orderData.orderType || !['pickup', 'delivery'].includes(orderData.orderType)) {
        setError('Valid orderType is required');
        toast.error('Please select a valid order type (pickup or delivery).');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating order with data:', orderData);
        const response = await api.post('/orders', orderData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const newOrder = response.data.data;
        setOrder(newOrder);
        setOrders((prevOrders) => [newOrder, ...prevOrders]);
        logger.info(`Order created successfully: Order ID ${newOrder.id}`);
        toast.success('Order created successfully!');
        return newOrder;
      } catch (err) {
        logger.error('Failed to create order:', err);
        setError('Failed to create order');
        toast.error('Failed to create order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, setOrders]
  );

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        fetchOrder,
        createOrder,
        loading,
        error,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

// PropTypes for type checking
OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderContext;
