// External Libraries
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  lazy,
  Suspense,
} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  FaSearch,
  FaDownload,
  FaTrash,
  FaEnvelope,
  FaTruck,
  FaFileExport,
  FaSun,
  FaMoon,
  FaEye,
  FaTimes,
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useNavigate } from 'react-router-dom';

// Internal Modules
import { useStore } from './contexts/StoreContext';
import { useUserContext } from './contexts/UserContext';
import config from './contexts/config';
import { joinUrl } from '../utils/joinUrl';
import isAbsoluteUrl from '../utils/isAbsoluteUrl';
import { formatCurrency } from '../utils/formatCurrency';

// Utilities
import { io } from 'socket.io-client';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

// Dynamic Imports
const OrderDetails = lazy(() => import('./OrderDetails'));

/**
 * Orders Component
 */
const Orders = () => {
  const {
    orders,
    fetchOrders,
    updateOrder,
    deleteOrder,
    downloadInvoice,
    sendNotification,
    fetchOrderDetails,
    bulkUpdateOrders,
    loading: ordersLoading,
  } = useStore();

  const { user } = useUserContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortModel, setSortModel] = useState([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [showSocialProof, setShowSocialProof] = useState(false);
  const [recentOrder, setRecentOrder] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [themeMode, setThemeMode] = useState('light'); // 'light' or 'dark'
  const [confirmBulkAction, setConfirmBulkAction] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  // Initialize WebSocket connection for real-time updates
  useEffect(() => {
    const socket = io(config.SOCKET_URL); // Ensure SOCKET_URL is defined in config

    socket.on('orderUpdated', (updatedOrder) => {
      fetchOrders(); // Refresh orders when an order is updated
      toast.info(`Order #${updatedOrder.id} has been updated.`);
    });

    socket.on('orderCreated', (newOrder) => {
      fetchOrders(); // Refresh orders when a new order is created
      toast.success(`New Order #${newOrder.id} has been placed.`);
    });

    socket.on('orderDeleted', (deletedOrderId) => {
      fetchOrders(); // Refresh orders when an order is deleted
      toast.warn(`Order #${deletedOrderId} has been deleted.`);
    });

    return () => {
      socket.disconnect();
    };
  }, [fetchOrders]);

  /**
   * Fetch orders on component mount
   */
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  /**
   * Handle search input with debounce
   */
  const [debouncedSearch, setDebouncedSearch] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 500); // 500ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  /**
   * Handle filter change
   */
  const handleFilterStatus = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  /**
   * Handle sort change
   */
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  /**
   * Filter and sort orders using useMemo for performance
   */
  const filteredAndSortedOrders = useMemo(() => {
    let filtered = orders;

    // Filter by status
    if (filterStatus !== 'all') {
      filtered = filtered.filter((order) => order.status === filterStatus);
    }

    // Search by Order ID
    if (debouncedSearch.trim() !== '') {
      filtered = filtered.filter((order) =>
        order.id.toString().includes(debouncedSearch.trim())
      );
    }

    return filtered;
  }, [orders, filterStatus, debouncedSearch]);

  /**
   * Pagination logic
   */
  const itemsPerPage = 10;

  const paginatedOrders = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return filteredAndSortedOrders.slice(start, start + itemsPerPage);
  }, [filteredAndSortedOrders, currentPage]);

  /**
   * Handle page change
   */
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  /**
   * Handle viewing order details
   */
  const handleViewOrder = useCallback(
    async (orderId) => {
      try {
        const orderDetails = await fetchOrderDetails(orderId);
        if (orderDetails) {
          setSelectedOrder(orderDetails);
          setIsOrderDetailsModalOpen(true);
        }
      } catch (error) {
        console.error(`Failed to fetch details for order ID ${orderId}:`, error);
        toast.error('Failed to fetch order details.');
      }
    },
    [fetchOrderDetails]
  );

  /**
   * Handle downloading invoice
   */
  const handleDownloadInvoice = useCallback(
    async (orderId) => {
      try {
        const invoiceBlob = await downloadInvoice(orderId);
        saveAs(invoiceBlob, `invoice_order_${orderId}.pdf`);
        toast.success('Invoice downloaded successfully.');
      } catch (error) {
        console.error(
          `Failed to download invoice for order ID ${orderId}:`,
          error
        );
        toast.error('Failed to download invoice.');
      }
    },
    [downloadInvoice]
  );

  /**
   * Handle sending notification
   */
  const handleSendNotification = useCallback(
    async (orderId, type) => {
      try {
        await sendNotification(orderId, type);
        toast.success('Notification sent successfully.');
      } catch (error) {
        console.error(
          `Failed to send ${type} notification for order ID ${orderId}:`,
          error
        );
        toast.error('Failed to send notification.');
      }
    },
    [sendNotification]
  );

  /**
   * Handle tracking order - Modified to navigate to Success page
   */
  const handleNavigateToSuccess = useCallback(
    async (orderId) => {
      try {
        const details = await fetchOrderDetails(orderId);
        console.log('Order Details:', details); // Debugging
        if (details) {
          const sessionId = details.session_id; // Ensure 'session_id' exists
          const orderType = details.orderType; // Ensure 'orderType' exists

          if (sessionId && orderType) {
            const queryParams = new URLSearchParams({
              session_id: sessionId,
              orderId: orderId,
              orderType: orderType,
            });

            console.log(
              `Navigating to /success?${queryParams.toString()}`
            ); // Debugging
            navigate(`/success?${queryParams.toString()}`);
          } else {
            toast.error('Missing session information for tracking.');
          }
        } else {
          toast.error('Order details not found.');
        }
      } catch (error) {
        console.error(`Failed to fetch details for order ID ${orderId}:`, error);
        toast.error('Failed to fetch order details.');
      }
    },
    [fetchOrderDetails, navigate]
  );

  /**
   * Handle canceling order
   */
  const handleCancelOrder = useCallback(
    async (orderId) => {
      if (window.confirm('Are you sure you want to cancel this order?')) {
        try {
          await updateOrder(orderId, { status: 'cancelled' });
          toast.success('Order cancelled successfully.');
          fetchOrders(); // Refresh orders
        } catch (error) {
          console.error(`Failed to cancel order ID ${orderId}:`, error);
          toast.error('Failed to cancel order.');
        }
      }
    },
    [updateOrder, fetchOrders]
  );

  /**
   * Handle deleting order
   */
  const handleDeleteOrder = useCallback(
    async (orderId) => {
      if (window.confirm('Are you sure you want to delete this order?')) {
        try {
          await deleteOrder(orderId);
          toast.success('Order deleted successfully.');
          fetchOrders(); // Refresh orders
        } catch (error) {
          console.error(`Failed to delete order ID ${orderId}:`, error);
          toast.error('Failed to delete order.');
        }
      }
    },
    [deleteOrder, fetchOrders]
  );

  /**
   * Handle bulk actions
   */
  const handleBulkAction = async () => {
    if (!bulkAction || selectedOrders.length === 0) {
      toast.error('Please select an action and at least one order.');
      return;
    }

    setConfirmBulkAction(true);
  };

  const confirmBulkActionHandler = async () => {
    setConfirmBulkAction(false);
    if (bulkAction === 'delete') {
      if (window.confirm('Are you sure you want to delete selected orders?')) {
        try {
          await bulkUpdateOrders(selectedOrders, 'deleted'); // Ensure 'deleted' is a valid status
          toast.success('Selected orders deleted successfully.');
          fetchOrders();
          setSelectedOrders([]);
        } catch (error) {
          console.error('Failed to delete selected orders:', error);
          toast.error('Failed to delete selected orders.');
        }
      }
    } else {
      try {
        await bulkUpdateOrders(selectedOrders, bulkAction);
        toast.success(`Selected orders updated to ${bulkAction}.`);
        fetchOrders();
        setSelectedOrders([]);
      } catch (error) {
        console.error(
          `Failed to update selected orders to ${bulkAction}:`,
          error
        );
        toast.error(`Failed to update selected orders to ${bulkAction}.`);
      }
    }
  };

  const cancelBulkActionHandler = () => {
    setConfirmBulkAction(false);
  };

  /**
   * Export orders to CSV
   */
  const exportToCSV = () => {
    const csvData = Papa.unparse(
      filteredAndSortedOrders.map((order) => ({
        'Order ID': order.id,
        Customer: order.user?.username || 'N/A',
        Total: formatCurrency(order.total),
        Status: order.status,
        Date: new Date(order.createdAt).toLocaleString(),
      }))
    );

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'orders_export.csv');
    toast.success('Orders exported to CSV successfully.');
  };

  /**
   * Toggle Theme Mode
   */
  const toggleTheme = () => {
    setThemeMode((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  /**
   * Social Proof Notification Logic
   * Displays a notification when a recent order is placed.
   */
  useEffect(() => {
    if (orders.length > 0) {
      const recent = orders[0];
      setRecentOrder(recent);
      setShowSocialProof(true);
      const timer = setTimeout(() => {
        setShowSocialProof(false);
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [orders]);

  /**
   * Define columns for DataGrid
   */
  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'Order ID', width: 100 },
      { field: 'customer', headerName: 'Customer', width: 150 },
      { field: 'total', headerName: 'Total', width: 100, type: 'number' },
      { field: 'status', headerName: 'Status', width: 130 },
      { field: 'date', headerName: 'Date', width: 180 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 350,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <div className="flex space-x-2">
            <Tooltip title="View Details">
              <IconButton
                color="primary"
                onClick={() => handleViewOrder(params.row.id)}
                aria-label={`View details of order ${params.row.id}`}
              >
                <FaEye />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Invoice">
              <IconButton
                color="success"
                onClick={() => handleDownloadInvoice(params.row.id)}
                aria-label={`Download invoice for order ${params.row.id}`}
              >
                <FaDownload />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send Email Notification">
              <IconButton
                color="warning"
                onClick={() => handleSendNotification(params.row.id, 'email')}
                aria-label={`Send email notification for order ${params.row.id}`}
              >
                <FaEnvelope />
              </IconButton>
            </Tooltip>
            <Tooltip title="Track Order">
              <IconButton
                color="info"
                onClick={() => handleNavigateToSuccess(params.row.id)}
                aria-label={`Track order ${params.row.id}`}
              >
                <FaTruck />
              </IconButton>
            </Tooltip>
            {user?.role === 'admin' && (
              <>
                <Tooltip title="Cancel Order">
                  <IconButton
                    color="secondary"
                    onClick={() => handleCancelOrder(params.row.id)}
                    aria-label={`Cancel order ${params.row.id}`}
                  >
                    <FaTimes />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Order">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteOrder(params.row.id)}
                    aria-label={`Delete order ${params.row.id}`}
                  >
                    <FaTrash />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        ),
      },
    ],
    [
      handleViewOrder,
      handleDownloadInvoice,
      handleSendNotification,
      handleNavigateToSuccess,
      handleCancelOrder,
      handleDeleteOrder,
      user,
    ]
  );

  /**
   * Prepare rows for DataGrid
   * Including sessionId and orderType if available
   */
  const rows = useMemo(() => {
    return paginatedOrders.map((order) => ({
      id: order.id,
      customer: order.user?.username || 'N/A',
      total: order.total,
      status: order.status.charAt(0).toUpperCase() + order.status.slice(1),
      date: new Date(order.createdAt).toLocaleString(),
      sessionId: order.session_id, // Ensure 'session_id' exists in order data
      orderType: order.orderType, // Ensure 'orderType' exists in order data
    }));
  }, [paginatedOrders]);

  /**
   * Handle theme mode classes
   */
  const themeClasses = useMemo(
    () => (themeMode === 'dark' ? 'dark' : 'light'),
    [themeMode]
  );

  return (
    <div
      className={`min-h-screen ${
        themeClasses === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'
      } transition-colors duration-300`}
    >
      <ToastContainer />
      <div className="container mx-auto px-4 py-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-4xl font-bold">Manage Orders</h1>
          <div className="flex items-center space-x-4">
            {/* Export to CSV Button - Visible Only to Admins */}
            {user?.role === 'admin' && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<FaFileExport />}
                onClick={exportToCSV}
                aria-label="Export Orders to CSV"
              >
                Export CSV
              </Button>
            )}
            {/* Theme Toggle */}
            <FormControlLabel
              control={
                <Switch
                  checked={themeMode === 'dark'}
                  onChange={toggleTheme}
                  name="themeToggle"
                  color="default"
                  aria-label="Toggle Dark Mode"
                />
              }
              label={themeMode === 'dark' ? <FaMoon /> : <FaSun />}
            />
          </div>
        </div>

        {/* Search, Filter, and Sort Section */}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6 space-y-4 md:space-y-0">
          {/* Search Input */}
          <div className="flex items-center">
            <FaSearch className="text-gray-500 mr-2" />
            <input
              type="text"
              placeholder="Search by Order ID..."
              value={searchTerm}
              onChange={handleSearch}
              className="border border-gray-300 rounded-md p-2 bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Search Orders by ID"
            />
          </div>

          {/* Status Filter */}
          <div className="flex items-center">
            <label htmlFor="statusFilter" className="mr-2 font-medium">
              Filter by Status:
            </label>
            <select
              id="statusFilter"
              value={filterStatus}
              onChange={handleFilterStatus}
              className="border border-gray-300 rounded-md p-2 bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Filter Orders by Status"
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="shipped">Shipped</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>

          {/* Sort Dropdown */}
          <div className="flex items-center">
            <label htmlFor="sortOption" className="mr-2 font-medium">
              Sort by:
            </label>
            <select
              id="sortOption"
              value={sortModel[0]?.field}
              onChange={(e) =>
                setSortModel([
                  { field: e.target.value, sort: sortModel[0]?.sort || 'asc' },
                ])
              }
              className="border border-gray-300 rounded-md p-2 bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Sort Orders"
            >
              <option value="createdAt">Date</option>
              <option value="status">Status</option>
              <option value="total">Total</option>
            </select>
          </div>
        </div>

        {/* Bulk Actions - Visible Only to Admins */}
        {user?.role === 'admin' && (
          <div className="flex items-center mb-6 space-x-4">
            <select
              value={bulkAction}
              onChange={(e) => setBulkAction(e.target.value)}
              className="border border-gray-300 rounded-md p-2 bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Select Bulk Action"
            >
              <option value="">Bulk Actions</option>
              <option value="processing">Mark as Processing</option>
              <option value="shipped">Mark as Shipped</option>
              <option value="delivered">Mark as Delivered</option>
              <option value="cancelled">Mark as Cancelled</option>
              <option value="delete">Delete</option>
            </select>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBulkAction}
              disabled={!bulkAction || selectedOrders.length === 0}
              aria-label="Apply Bulk Action"
            >
              Apply
            </Button>
          </div>
        )}

        {/* Orders DataGrid */}
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={itemsPerPage}
            rowsPerPageOptions={[itemsPerPage]}
            checkboxSelection
            disableSelectionOnClick
            sortingOrder={['desc', 'asc']}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onSelectionModelChange={(newSelection) => {
              setSelectedOrders(newSelection);
            }}
            pagination
            paginationMode="server"
            onPageChange={(params) => {
              handlePageChange(params.page + 1);
            }}
            rowCount={filteredAndSortedOrders.length}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={ordersLoading}
            aria-label="Orders DataGrid"
          />
        </div>

        {/* Confirmation Dialog for Bulk Actions */}
        <Dialog
          open={confirmBulkAction}
          onClose={cancelBulkActionHandler}
          aria-labelledby="bulk-action-confirmation-dialog"
        >
          <DialogTitle id="bulk-action-confirmation-dialog">
            Confirm Bulk Action
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to {bulkAction} the selected orders? This
              action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelBulkActionHandler} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmBulkActionHandler} color="secondary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Order Details Modal */}
        <Suspense fallback={<div>Loading...</div>}>
          <AnimatePresence>
            {isOrderDetailsModalOpen && selectedOrder && (
              <OrderDetails
                orderId={selectedOrder.id}
                onClose={() => setIsOrderDetailsModalOpen(false)}
              />
            )}
          </AnimatePresence>
        </Suspense>

        {/* Social Proof Notification */}
        <AnimatePresence>
          {showSocialProof && recentOrder && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="fixed bottom-4 left-4 bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-4 rounded-lg shadow-lg z-50 flex items-center space-x-4"
              aria-live="polite"
            >
              <Tooltip title="Zoom Image">
                <Zoom>
                  <img
                    src={
                      recentOrder.orderProducts[0].product.imageUrl
                        ? isAbsoluteUrl(recentOrder.orderProducts[0].product.imageUrl)
                          ? recentOrder.orderProducts[0].product.imageUrl
                          : joinUrl(
                              config.API_URL,
                              recentOrder.orderProducts[0].product.imageUrl
                            )
                        : '/default-image.png'
                    }
                    alt={recentOrder.orderProducts[0].product.name}
                    className="w-16 h-16 object-cover rounded-lg"
                  />
                </Zoom>
              </Tooltip>
              <div>
                <p className="font-bold">
                  {recentOrder.orderProducts[0].product.name}
                </p>
                <p className="text-sm">was just ordered!</p>
              </div>
              <button
                className="ml-auto text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={() => setShowSocialProof(false)}
                aria-label="Close Social Proof Notification"
              >
                <FaTimes className="w-4 h-4" />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

/**
 * Custom Loading Overlay for DataGrid
 */
const CustomLoadingOverlay = () => (
  <div className="flex justify-center items-center h-full">
    <ClipLoader color="#3f51b5" loading={true} size={50} />
  </div>
);

/**
 * Custom No Rows Overlay for DataGrid
 */
const CustomNoRowsOverlay = () => (
  <div className="flex justify-center items-center h-full">
    <p className="text-gray-500">No orders found.</p>
  </div>
);

export default Orders;
