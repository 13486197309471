// frontend/src/components/AddToCartModal.js

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useCart } from './contexts/CartContext';
import { FaTimes, FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import IngredientsSelection from './IngredientsSelection';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { addNotification } from '../features/notifcation/notificationsSlice'; // Corrected import path
import { v4 as uuidv4 } from 'uuid'; // Import uuid

// Configure React Modal's appElement for accessibility
Modal.setAppElement('#root');

const AddToCartModal = ({ isOpen, onRequestClose, product }) => {
  const { addItemToCart } = useCart();
  const dispatch = useDispatch(); // Initialize dispatch

  // State for selected size and ingredients with quantities
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedIngredients, setSelectedIngredients] = useState([]); // [{ id, quantity }, ...]

  // Price Breakdown State
  const [priceBreakdown, setPriceBreakdown] = useState({
    basePrice: 0,
    sizePrice: 0,
    ingredientsPrice: 0,
    total: 0,
  });

  // Reset selections when product changes or modal is opened
  useEffect(() => {
    if (product && isOpen) {
      // Load from localStorage if available
      const savedSize = localStorage.getItem(`product-${product.id}-size`);
      const savedIngredients = JSON.parse(localStorage.getItem(`product-${product.id}-ingredients`)) || [];
      setSelectedSize(savedSize || '');
      setSelectedIngredients(savedIngredients);
    }
  }, [product, isOpen]);

  // Update localStorage whenever selections change
  useEffect(() => {
    if (product) {
      localStorage.setItem(`product-${product.id}-size`, selectedSize);
      localStorage.setItem(`product-${product.id}-ingredients`, JSON.stringify(selectedIngredients));
    }
  }, [selectedSize, selectedIngredients, product]);

  const handleSizeChange = useCallback((e) => {
    setSelectedSize(e.target.value);
  }, []);

  const getSizePrice = useCallback((size) => {
    const sizePrices = {
      Small: 0,
      Medium: 1.5,
      Large: 3,
    };
    return sizePrices[size] || 0;
  }, []);

  // Calculate price breakdown
  useEffect(() => {
    if (!product) {
      setPriceBreakdown({
        basePrice: 0,
        sizePrice: 0,
        ingredientsPrice: 0,
        total: 0,
      });
      return;
    }

    const basePrice = parseFloat(product.price) || 0;
    const sizePrice = selectedSize ? getSizePrice(selectedSize) : 0;
    const ingredientsPrice = selectedIngredients.reduce((acc, ing) => {
      const ingredient = product.ingredients.find((item) => item.id === ing.id);
      const ingredientPrice = ingredient?.price ? parseFloat(ingredient.price) : 0;
      return acc + (ingredientPrice * ing.quantity);
    }, 0);
    const total = basePrice + sizePrice + ingredientsPrice;

    setPriceBreakdown({
      basePrice,
      sizePrice,
      ingredientsPrice,
      total,
    });
  }, [product, selectedSize, selectedIngredients, getSizePrice]);

  const handleAddToCart = () => {
    if (product.sizeOptions && product.sizeOptions.length > 0 && !selectedSize) {
      toast.error('Please select a size.');
      return;
    }

    const finalIngredients = selectedIngredients
      .filter((ing) => ing.quantity > 0)
      .map((ing) => ({
        id: ing.id,
        quantity: ing.quantity,
      }));

    const cartItem = {
      productId: product.id,
      name: product.name,
      price: parseFloat(product.price),
      size: selectedSize,
      ingredients: finalIngredients,
      imageUrl: product.imageUrl,
      totalPrice: parseFloat(priceBreakdown.total.toFixed(2)),
    };

    addItemToCart(cartItem);
    toast.success('Product added to cart!');

    // Dispatch notification to Redux store
    const notification = {
      id: uuidv4(), // Generate unique ID
      message: 'Product added to cart!',
      timestamp: new Date().toISOString(),
    };
    dispatch(addNotification(notification));

    onRequestClose();
  };

  const sizeOptions = useMemo(() => {
    let options = [];
    if (product && product.sizeOptions) {
      if (typeof product.sizeOptions === 'string') {
        try {
          const parsed = JSON.parse(product.sizeOptions);
          if (Array.isArray(parsed)) {
            options = parsed;
          } else {
            console.warn('sizeOptions is not an array');
          }
        } catch (error) {
          console.error('Error parsing sizeOptions:', error);
        }
      } else if (Array.isArray(product.sizeOptions)) {
        options = product.sizeOptions;
      } else {
        console.warn('sizeOptions is neither a string nor an array');
      }
    }
    return options;
  }, [product]);

  const ingredientsList = useMemo(() => {
    const ingredients = product && Array.isArray(product.ingredients) ? product.ingredients : [];
    return ingredients;
  }, [product]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Add to Cart"
          className={`max-w-5xl mx-auto mt-10 bg-white rounded-2xl shadow-lg p-8 relative overflow-hidden transition-colors duration-300`}
          overlayClassName="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 overflow-auto"
          closeTimeoutMS={300}
          role="dialog"
          aria-modal="true"
          aria-labelledby="add-to-cart-modal-title"
        >
          {/* Close Icon */}
          <button
            type="button" // Ensure type="button"
            onClick={onRequestClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close Modal"
          >
            <FaTimes size={24} />
          </button>

          {product ? (
            <motion.div
              className="flex flex-col lg:flex-row"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              {/* Product Image */}
              <div className="lg:w-1/3 flex justify-center items-center">
                <img
                  src={
                    /^https?:\/\//i.test(product.imageUrl)
                      ? product.imageUrl
                      : `${process.env.REACT_APP_API_URL}${product.imageUrl}`
                  }
                  alt={product.name}
                  className="w-64 h-64 object-contain rounded-xl shadow-md"
                  loading="lazy"
                />
              </div>

              {/* Product Details and Customization */}
              <div className="lg:w-2/3 lg:ml-12 mt-8 lg:mt-0">
                {/* Product Name */}
                <h2 id="add-to-cart-modal-title" className="text-3xl font-extrabold text-gray-800 mb-6">
                  {product.name}
                </h2>

                {/* Size Selection */}
                {sizeOptions.length > 0 && (
                  <div className="mb-6">
                    <label htmlFor="size-select" className="block text-lg font-medium text-gray-700 mb-2">
                      Select Size:
                    </label>
                    <div className="relative">
                      <select
                        id="size-select"
                        value={selectedSize}
                        onChange={handleSizeChange}
                        className="block appearance-none w-full bg-gray-50 border border-gray-300 hover:border-pink-500 px-4 py-3 pr-10 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors duration-200"
                        aria-label="Select Size"
                      >
                        <option value="">Choose a size</option>
                        {sizeOptions.map((size, idx) => (
                          <option key={idx} value={size}>
                            {size} {getSizePrice(size) > 0 && `(+£${getSizePrice(size).toFixed(2)})`}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M5.516 7.548L10 12.04l4.484-4.492L15.516 9l-5 5-5-5z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}

                {/* Ingredients Selection */}
                <IngredientsSelection
                  ingredients={ingredientsList}
                  selectedIngredients={selectedIngredients}
                  setSelectedIngredients={setSelectedIngredients}
                />

                {/* Price Breakdown */}
                <div className="mb-6">
                  <h3 className="text-xl font-semibold text-gray-700 mb-2">Price Breakdown:</h3>
                  <ul className="space-y-1 text-gray-700">
                    <li className="flex justify-between">
                      <span>Base Price:</span>
                      <span>£{priceBreakdown.basePrice.toFixed(2)}</span>
                    </li>
                    {priceBreakdown.sizePrice > 0 && (
                      <li className="flex justify-between">
                        <span>Size ({selectedSize}):</span>
                        <span>£{priceBreakdown.sizePrice.toFixed(2)}</span>
                      </li>
                    )}
                    {priceBreakdown.ingredientsPrice > 0 && (
                      <li className="flex justify-between">
                        <span>Ingredients:</span>
                        <span>£{priceBreakdown.ingredientsPrice.toFixed(2)}</span>
                      </li>
                    )}
                    <li className="flex justify-between font-bold text-gray-800">
                      <span>Total:</span>
                      <span>£{priceBreakdown.total.toFixed(2)}</span>
                    </li>
                  </ul>
                </div>

                {/* Action Buttons */}
                <div className="flex items-center space-x-4">
                  {/* Cancel Icon Button */}
                  <button
                    type="button" // Ensure type="button"
                    onClick={onRequestClose}
                    className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
                    aria-label="Cancel"
                  >
                    <FaTimes size={20} />
                  </button>

                  {/* Add to Cart Icon Button */}
                  <button
                    type="button" // Ensure type="button"
                    onClick={handleAddToCart}
                    className="flex items-center px-4 py-2 bg-pink-600 text-white rounded-full hover:bg-pink-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-pink-500"
                    aria-label="Add to Cart"
                  >
                    <FaShoppingCart size={20} />
                  </button>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.p
              className="text-gray-700 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              Loading product details...
            </motion.p>
          )}
        </Modal>
      )}
    </AnimatePresence>
  );
};

// Define PropTypes for type checking
AddToCartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sizeOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    ingredients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        imageUrl: PropTypes.string, // Optional: If you have images for ingredients
        category: PropTypes.string.isRequired, // Added category for better filtering
      })
    ),
  }).isRequired,
};

export default React.memo(AddToCartModal);
