// src/pages/Success.js

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useCart } from '../components/contexts/CartContext';
import { useStore } from '../components/contexts/StoreContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FaCheckCircle,
  FaStar,
  FaTimes,
  FaInfoCircle,
  FaHeadset,
  FaLeaf,
  FaDownload,
  FaRedo,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProgressTracker from '../components/ProgressTracker'; // Import the ProgressTracker component
import { io } from 'socket.io-client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // Import the duration plugin

dayjs.extend(duration); // Enable the duration functionality

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get('session_id');
  const rawOrderId = query.get('orderId');
  const orderId = Number(rawOrderId); // Ensure orderId is a number
  const orderType = query.get('orderType'); // Should be 'pickup' only
  const { clearCart, addToCart } = useCart();
  const { orders, fetchOrderDetails, ingredients } = useStore();

  const [currentStep, setCurrentStep] = useState(1); // Start at step 1
  const [orderDetails, setOrderDetails] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [recommendations, setRecommendations] = useState([]);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [estimatedPickup, setEstimatedPickup] = useState(null);
  const [timeLeft, setTimeLeft] = useState('');

  // Define status mapping for progress tracker
  const statusMapping = {
    'ordered': 1,
    'preparing order': 2,
    'processing': 2, // Added 'processing' to align with backend
    'baking': 3,
    'ready for pickup': 4,
    'collected': 5,
    'completed': 5,
  };

  // Initialize Socket.IO connection
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, {
      transports: ['websocket'],
      auth: {
        token: localStorage.getItem('token'), // Adjust based on your auth implementation
      },
      reconnectionAttempts: 5,
      timeout: 10000,
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to Socket.IO server');
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server');
    });

    // Clean up on unmount
    return () => newSocket.close();
  }, []);

  // Handle Socket.IO events
  useEffect(() => {
    if (!socket) return;

    const handleOrderStatusUpdate = (data) => {
      if (data.orderId !== orderId) return; // Ensure the update is for the current order

      console.log('Received order status update:', data);

      setOrderDetails((prevDetails) => ({
        ...prevDetails,
        status: data.status,
        processingStartTime: data.processingStartTime,
        bakingStartTime: data.bakingStartTime,
        readyForCollectionTime: data.readyForCollectionTime,
      }));

      const newStep = statusMapping[data.status.toLowerCase()] || 1;
      if (newStep !== currentStep) {
        setCurrentStep(newStep);
        toast.info(`Order status updated to: ${capitalizeWords(data.status)}`);
      }

      // If you still want to update the pickup time based on status, you can keep this logic
      // Otherwise, comment it out to keep the pickup time fixed at 45 minutes
      /*
      if (data.status.toLowerCase() === 'ready for pickup' && data.readyForCollectionTime) {
        const estimated = dayjs(data.readyForCollectionTime).add(10, 'minute');
        setEstimatedPickup(estimated.toDate());
        toast.info(`Estimated pickup time updated to: ${estimated.format('HH:mm')}`);
      }
      */
    };

    socket.on('order_status_update', handleOrderStatusUpdate);

    // Clean up the listener on unmount
    return () => {
      socket.off('order_status_update', handleOrderStatusUpdate);
    };
  }, [socket, orderId, currentStep, statusMapping]);

  // Initialize and fetch order details
  useEffect(() => {
    const handleClearCartAndFetchOrder = async () => {
      if (sessionId && orderId && orderType === 'pickup') {
        try {
          // Clear the cart
          await clearCart();
          toast.success('Your cart has been cleared!');

          // Fetch initial order details
          const fetchedOrder = await fetchOrderDetails(orderId);
          console.log('Fetched Order Details:', fetchedOrder);
          if (fetchedOrder) {
            setOrderDetails(fetchedOrder);
            const step = statusMapping[fetchedOrder.status.toLowerCase()] || 1;
            setCurrentStep(step);

            // Set estimated pickup time to 45 minutes from order creation
            const createdAt = dayjs(fetchedOrder.createdAt);
            const estimated = createdAt.add(45, 'minute');
            setEstimatedPickup(estimated.toDate());

            toast.success('Order successfully placed!');
            setIsLoading(false);
          } else {
            toast.error('Failed to fetch order details.');
            navigate('/'); // Redirect to home or an appropriate page
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error('Failed to process your order. Please contact support.');
          navigate('/'); // Redirect to home or an appropriate page
        }
      } else {
        toast.error('Invalid session. Unable to confirm your order.');
        navigate('/'); // Redirect to home or an appropriate page
      }
    };

    handleClearCartAndFetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, orderId, orderType, clearCart, navigate, fetchOrderDetails]);

  // Watch for order status updates from StoreContext's orders state
  useEffect(() => {
    if (orderId && orders.length > 0) {
      const updatedOrder = orders.find((o) => o.id === orderId);
      if (updatedOrder) {
        console.log('Updated Order:', updatedOrder);
        setOrderDetails(updatedOrder);
        const newStep = statusMapping[updatedOrder.status.toLowerCase()] || 1;
        if (newStep !== currentStep) {
          setCurrentStep(newStep);
          toast.info(`Order status updated to: ${updatedOrder.status}`);

          // If you still want to update the pickup time based on status, you can keep this logic
          // Otherwise, comment it out to keep the pickup time fixed at 45 minutes
          /*
          if (updatedOrder.status.toLowerCase() === 'ready for pickup' && updatedOrder.readyForCollectionTime) {
            const estimated = dayjs(updatedOrder.readyForCollectionTime).add(10, 'minute');
            setEstimatedPickup(estimated.toDate());
            console.log(`Estimated pickup time set to ${estimated.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`);
          }
          */
        } else {
          console.log('No change in order status.');
        }
      } else {
        console.warn(`Order ID ${orderId} not found in StoreContext's orders state.`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, orderId]);

  // Fetch personalized recommendations
  useEffect(() => {
    const fetchRecommendations = async () => {
      if (orderDetails) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/recommendations`, {
            params: { orderId: orderDetails.id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (response.data && response.data.products) {
            setRecommendations(response.data.products);
            console.log('Fetched recommendations:', response.data.products);
          }
        } catch (error) {
          console.error('Failed to fetch recommendations:', error);
        }
      }
    };

    fetchRecommendations();
  }, [orderDetails]);

  // Handle feedback submission
  const handleFeedbackSubmit = async () => {
    if (rating === 0) {
      toast.error('Please provide a rating.');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/feedback`,
        {
          orderId: orderDetails.id,
          rating,
          feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success('Thank you for your feedback!');
      setFeedback('');
      setRating(0);
      setIsFeedbackOpen(false);
    } catch (error) {
      console.error('Failed to submit feedback:', error);
      toast.error('Failed to submit feedback. Please try again.');
    }
  };

  // Settings for the recommendations carousel
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(recommendations.length, 4),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(recommendations.length, 3),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(recommendations.length, 2),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Countdown Timer Effect
  useEffect(() => {
    if (!estimatedPickup) return;

    const interval = setInterval(() => {
      const now = dayjs();
      const pickupTime = dayjs(estimatedPickup);
      const diff = pickupTime.diff(now);

      if (diff <= 0) {
        setTimeLeft('Ready for Pickup!');
        clearInterval(interval);
      } else {
        const dur = dayjs.duration(diff);
        const minutes = Math.floor(dur.asMinutes());
        const seconds = dur.seconds();
        setTimeLeft(`${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [estimatedPickup]);

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-gray-100 p-6 relative">
      <ToastContainer />

      {/* Loading Indicator */}
      {isLoading && (
        <div className="flex items-center justify-center w-full h-full">
          <motion.div
            className="flex items-center justify-center w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"
            aria-label="Loading"
          />
        </div>
      )}

      {/* Success Content */}
      {!isLoading && orderDetails && (
        <>
          {/* Success Message */}
          <AnimatePresence>
            {currentStep >= 1 && (
              <motion.div
                className="flex flex-col items-center text-center mb-8 bg-white p-8 rounded-lg shadow-md max-w-md w-full relative z-10"
                initial={{ opacity: 0, scale: 0.9, y: -30 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.9, y: -30 }}
                transition={{ duration: 0.5 }}
              >
                <FaCheckCircle className="text-blue-600 text-6xl mb-4" />
                <motion.h1
                  className="text-3xl font-semibold text-gray-800 mb-2"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                >
                  Order Successful!
                </motion.h1>
                <motion.p
                  className="text-gray-600 mb-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                >
                  Thank you for your purchase. Your order is being processed and will be ready for pickup by <strong>{dayjs(estimatedPickup).format('HH:mm')}</strong>.
                </motion.p>
                <motion.p
                  className="text-sm text-gray-500 mb-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6, duration: 0.5 }}
                >
                  <strong>Order ID:</strong> {orderId || 'N/A'}
                </motion.p>
                <motion.p
                  className="text-sm text-gray-500 mb-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.8, duration: 0.5 }}
                >
                  <strong>Order Type:</strong> {orderType ? orderType.charAt(0).toUpperCase() + orderType.slice(1) : 'N/A'}
                </motion.p>
                <motion.button
                  onClick={() => setIsFeedbackOpen(true)}
                  className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-transform transform hover:scale-105 flex items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.0, duration: 0.5 }}
                  aria-label="Provide Feedback"
                >
                  <FaStar className="mr-2" />
                  Provide Feedback
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Enhanced Order Progress Tracker */}
          <ProgressTracker currentStep={currentStep} />

          {/* Order Summary with Product Images and Ingredients */}
          <motion.div
            className="w-full max-w-3xl bg-white p-8 rounded-lg shadow-md mt-10"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Order Summary</h2>
            <ul className="space-y-4">
              {orderDetails.orderProducts.map((item) => {
                // Extract ingredientIds from additionalOptions
                const ingredientIds = item.additionalOptions?.map(option => option.ingredientId) || [];

                // Find the product's ingredients from the StoreContext's ingredients state
                const productIngredients = ingredients.filter((ing) =>
                  ingredientIds.includes(ing.id)
                );

                return (
                  <li key={item.id} className="flex items-start justify-between">
                    <div className="flex items-start">
                      <img
                        src={item.product.imageUrl}
                        alt={item.product.name}
                        className="w-16 h-16 object-cover rounded-lg mr-6"
                        loading="lazy"
                      />
                      <div>
                        <span className="block font-medium text-gray-700">{item.product.name}</span>
                        <span className="text-sm text-gray-500">Quantity: {item.quantity}</span>
                        {/* Display Ingredients */}
                        {productIngredients.length > 0 && (
                          <div className="mt-2">
                            <span className="text-sm font-semibold text-gray-600">Ingredients:</span>
                            <ul className="list-disc list-inside text-sm text-gray-500">
                              {productIngredients.map((ing) => (
                                <li key={ing.id}>{ing.name}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <span className="font-semibold text-gray-700">£{((item.price || 0) * (item.quantity || 0)).toFixed(2)}</span>
                  </li>
                );
              })}
              <li className="flex justify-between font-medium text-gray-700">
                <span>Subtotal</span>
                <span>£{(orderDetails.subtotal || 0).toFixed(2)}</span>
              </li>
              <li className="flex justify-between font-medium text-gray-700">
                <span>Collection Fee</span>
                <span>£{((orderDetails.collectionFee || 0) / 100).toFixed(2)}</span>
              </li>
              <li className="flex justify-between font-bold text-lg text-gray-800">
                <span>Total</span>
                <span>£{(orderDetails.total || 0).toFixed(2)}</span>
              </li>
            </ul>
            <div className="flex flex-col sm:flex-row items-center justify-between mt-6 space-y-4 sm:space-y-0 sm:space-x-4">
              <Link
                to={`/orders/${orderId}/receipt`}
                className="flex items-center justify-center px-6 py-2 bg-yellow-600 text-white rounded-full hover:bg-yellow-700 transition-transform transform hover:scale-105 flex items-center"
                aria-label="Download Receipt"
              >
                <FaDownload className="mr-2" />
                Download Receipt
              </Link>
              <button
                onClick={() => {
                  // Implement reorder functionality
                  addToCart(orderDetails.orderProducts);
                  navigate('/cart');
                  toast.success('Items added to your cart!');
                }}
                className="flex items-center justify-center px-6 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 transition-transform transform hover:scale-105 flex items-center"
                aria-label="Reorder Items"
              >
                <FaRedo className="mr-2" />
                Reorder
              </button>
            </div>
          </motion.div>

          {/* Personalized Recommendations Carousel */}
          {recommendations.length > 0 && (
            <motion.div
              className="w-full max-w-5xl bg-white p-8 rounded-lg shadow-md mt-12"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7, duration: 0.5 }}
            >
              <h2 className="text-2xl font-semibold text-gray-800 mb-6">You Might Also Like</h2>
              <Slider {...sliderSettings}>
                {recommendations.map((product) => (
                  <div key={product.id} className="px-4">
                    <Link
                      to={`/product/${product.id}`}
                      className="flex flex-col items-center bg-gray-100 p-6 rounded-lg hover:shadow-lg transition-shadow h-full"
                    >
                      <img
                        src={product.imageUrl}
                        alt={product.name}
                        className="w-20 h-20 object-cover mb-4 rounded-full border-2 border-gray-200"
                        loading="lazy"
                      />
                      <span className="font-medium text-gray-700 text-center">{product.name}</span>
                      <span className="text-green-600 font-semibold">£{(product.price || 0).toFixed(2)}</span>
                    </Link>
                  </div>
                ))}
              </Slider>
            </motion.div>
          )}

          {/* Estimated Pickup Time and Countdown Timer */}
          <motion.div
            className="w-full max-w-3xl bg-blue-100 p-4 rounded-lg shadow-md mt-12 flex flex-col items-start"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.0, duration: 0.5 }}
          >
            <div className="flex items-center mb-2">
              <FaInfoCircle className="text-blue-600 text-2xl mr-4" />
              <p className="text-gray-700 text-lg">
                Estimated Pickup Time: <strong>{dayjs(estimatedPickup).format('HH:mm')}</strong>
              </p>
            </div>
            {/* Countdown Timer */}
            {timeLeft && (
              <div className="flex items-center">
                <FaInfoCircle className="text-yellow-600 text-xl mr-2" />
                <p className="text-gray-700">
                  Time remaining until pickup: <strong>{timeLeft}</strong>
                </p>
              </div>
            )}
          </motion.div>

          {/* Discount Code Banner */}
          <motion.div
            className="w-full max-w-3xl bg-gradient-to-r from-green-400 to-green-500 p-4 rounded-lg shadow-md mt-6 flex items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.3, duration: 0.5 }}
          >
            <FaStar className="text-white text-2xl mr-4" />
            <p className="text-white">
              Use code <strong>SAVE10</strong> on your next purchase to save 10%!
            </p>
          </motion.div>

          {/* Loyalty Points Banner */}
          <motion.div
            className="w-full max-w-3xl bg-purple-100 p-4 rounded-lg shadow-md mt-6 flex items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.9, duration: 0.5 }}
          >
            <FaStar className="text-purple-600 text-2xl mr-4" />
            <p className="text-gray-700">
              You have earned <strong>{orderDetails.loyaltyPointsEarned || 0}</strong> loyalty points from this purchase.{' '}
              <Link to="/loyalty" className="text-purple-600 underline">
                Learn how to redeem points
              </Link>
              .
            </p>
          </motion.div>

          {/* Customer Support Contact */}
          <motion.div
            className="w-full max-w-3xl bg-gray-100 p-4 rounded-lg shadow-md mt-6 flex items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.6, duration: 0.5 }}
          >
            <FaHeadset className="text-gray-600 text-2xl mr-4" />
            <p className="text-gray-700">
              Need assistance? <Link to="/support" className="text-blue-600 underline">Contact our support team</Link>.
            </p>
          </motion.div>

          {/* Eco-Friendly Practices Banner */}
          <motion.div
            className="w-full max-w-3xl bg-green-100 p-4 rounded-lg shadow-md mt-6 flex items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2.1, duration: 0.5 }}
          >
            <FaLeaf className="text-green-600 text-2xl mr-4" />
            <p className="text-gray-700">
              We use eco-friendly packaging to ensure our desserts are as good for the planet as they are for you.
            </p>
          </motion.div>

          {/* Thank You Message */}
          <motion.div
            className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-md mt-12 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2.3, duration: 0.5 }}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Thank You!</h2>
            <p className="text-gray-600">
              Our team is dedicated to providing you with the best desserts. If you have any questions or need assistance, feel free to{' '}
              <Link to="/support" className="text-blue-600 underline">contact us</Link>.
            </p>
          </motion.div>

          {/* Social Media Engagement */}
          <motion.div
            className="w-full max-w-3xl bg-white p-4 rounded-lg shadow-md mt-6 flex justify-center space-x-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2.4, duration: 0.5 }}
          >
            <a href="https://facebook.com/yourpage" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebookF className="text-blue-600 text-2xl hover:text-blue-800 transition-colors" />
            </a>
            <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitter className="text-blue-400 text-2xl hover:text-blue-600 transition-colors" />
            </a>
            <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram className="text-pink-600 text-2xl hover:text-pink-800 transition-colors" />
            </a>
            <a href="https://linkedin.com/company/yourcompany" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedinIn className="text-blue-700 text-2xl hover:text-blue-900 transition-colors" />
            </a>
          </motion.div>

          {/* Print-Friendly Design - Print Button */}
          <motion.div
            className="w-full max-w-3xl bg-white p-4 rounded-lg shadow-md mt-6 flex justify-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2.5, duration: 0.5 }}
          >
            <button
              onClick={() => window.print()}
              className="flex items-center justify-center px-6 py-3 bg-gray-600 text-white rounded-full hover:bg-gray-700 transition-transform transform hover:scale-105"
              aria-label="Print Order Summary"
            >
              <FaDownload className="mr-2" />
              Print
            </button>
          </motion.div>
        </>
      )}

      {/* Feedback Modal */}
      <AnimatePresence>
        {isFeedbackOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <button
                onClick={() => setIsFeedbackOpen(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                aria-label="Close Feedback Modal"
              >
                <FaTimes size={24} />
              </button>
              <h2 className="text-2xl font-semibold text-gray-800 mb-6">We Value Your Feedback</h2>
              <div className="flex items-center mb-6">
                {[...Array(5)].map((star, index) => {
                  const currentRating = index + 1;
                  return (
                    <FaStar
                      key={index}
                      className={`cursor-pointer text-3xl ${
                        currentRating <= rating ? 'text-yellow-400' : 'text-gray-300'
                      }`}
                      onClick={() => setRating(currentRating)}
                      aria-label={`Rate ${currentRating} star${currentRating > 1 ? 's' : ''}`}
                    />
                  );
                })}
              </div>
              <textarea
                className="w-full p-4 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Leave your feedback here..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                aria-label="Feedback"
                rows="5"
              ></textarea>
              <button
                onClick={handleFeedbackSubmit}
                className="w-full px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-transform transform hover:scale-105 flex items-center justify-center"
              >
                <FaStar className="mr-2" />
                Submit Feedback
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Utility function to capitalize each word
const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export default Success;
