// src/utils/formatCurrency.js

/**
 * Formats a value into a currency string with two decimal places.
 * Defaults to '£0.00' if the input is invalid.
 * @param {string|number} value - The value to format.
 * @param {string} currencySymbol - The currency symbol to prepend.
 * @returns {string} - The formatted currency string.
 */
export const formatCurrency = (value, currencySymbol = '£') => {
    if (value === null || value === undefined) {
      console.warn('formatCurrency called with null or undefined value.');
      return `${currencySymbol}0.00`;
    }
    const number = Number(value);
    if (isNaN(number)) {
      console.warn(`formatCurrency received a non-numeric value: ${value}`);
      return `${currencySymbol}0.00`;
    }
    return `${currencySymbol}${number.toFixed(2)}`;
  };
  