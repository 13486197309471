// src/constants/orderStatuses.js

export const ORDER_STATUSES = [
    'pending',
    'processing',
    'baking',
    'ready for pickup',
    'collected',
    'completed',
    'refunded',
    'cancelled',
    'deleted',
  ];
  
  export const STATUS_TRANSITIONS = {
    'pending': ['processing', 'cancelled'],
    'processing': ['baking', 'cancelled'],
    'baking': ['ready for pickup', 'cancelled'], // Note: "ready for pickup" here
    'ready for pickup': ['collected', 'cancelled'],
    'collected': [],
    'completed': [],
    'refunded': [],
    'cancelled': [],
    'deleted': [],
  };
  