// src/components/PartnerLogo.js

import React from 'react';
import { motion } from 'framer-motion';

const PartnerLogo = ({ src, alt, location, href }) => {
  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
      <motion.a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Visit ${alt} page`}
        whileHover={{ scale: 1.1 }}
        className="flex flex-col items-center"
      >
        <img src={src} alt={alt} className="h-16 mb-4 object-contain" />
        <span className="text-gray-700 font-medium">{location}</span>
      </motion.a>
    </div>
  );
};

export default PartnerLogo;
