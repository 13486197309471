// frontend/src/components/CartSummary.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CartSummary = ({
  total,
  discount,
  redeemPoints,
  loyaltyPoints,
  finalTotal,
  onApplyDiscount,
  onToggleRedeemPoints,
  onProceedToCheckout,
  orderType,
}) => {
  const [discountCode, setDiscountCode] = useState('');

  const handleApplyDiscount = () => {
    onApplyDiscount(discountCode);
    setDiscountCode('');
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">Order Summary</h2>

      <div className="flex justify-between mb-2">
        <span>Subtotal:</span>
        <span>£{total.toFixed(2)}</span>
      </div>

      {orderType === 'delivery' && (
        <div className="flex justify-between mb-2">
          <span>Delivery Fee:</span>
          <span>£5.00</span>
        </div>
      )}

      {discount > 0 && (
        <div className="flex justify-between mb-2">
          <span>Discount:</span>
          <span>-£{discount.toFixed(2)}</span>
        </div>
      )}

      {redeemPoints && (
        <div className="flex justify-between mb-2">
          <span>Loyalty Points:</span>
          <span>-£{(loyaltyPoints * 0.01).toFixed(2)}</span>
        </div>
      )}

      <hr className="my-4" />

      <div className="flex justify-between text-xl font-bold">
        <span>Total:</span>
        <span>£{finalTotal}</span>
      </div>

      {/* Discount Code */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Apply Discount Code</h3>
        <div className="flex items-center">
          <input
            type="text"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            placeholder="Enter discount code"
            className="flex-1 px-4 py-2 border rounded-l-lg focus:outline-none"
          />
          <button
            onClick={handleApplyDiscount}
            className="px-4 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600 transition-colors"
          >
            Apply
          </button>
        </div>
      </div>

      {/* Redeem Loyalty Points */}
      <div className="mt-4 flex items-center">
        <input
          type="checkbox"
          checked={redeemPoints}
          onChange={onToggleRedeemPoints}
          className="form-checkbox h-5 w-5 text-blue-600"
          id="redeemPoints"
        />
        <label htmlFor="redeemPoints" className="ml-2">
          Redeem {loyaltyPoints} Loyalty Points (£{(loyaltyPoints * 0.01).toFixed(2)})
        </label>
      </div>

      {/* Proceed to Checkout Button */}
      <button
        onClick={onProceedToCheckout}
        className="mt-6 w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors text-lg font-semibold"
      >
        Proceed to Checkout
      </button>
    </div>
  );
};

CartSummary.propTypes = {
  total: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  redeemPoints: PropTypes.bool.isRequired,
  loyaltyPoints: PropTypes.number.isRequired,
  finalTotal: PropTypes.string.isRequired,
  onApplyDiscount: PropTypes.func.isRequired,
  onToggleRedeemPoints: PropTypes.func.isRequired,
  onProceedToCheckout: PropTypes.func.isRequired,
  orderType: PropTypes.oneOf(['pickup', 'delivery']).isRequired,
};

export default CartSummary;
