// frontend/src/components/Overview.js

import React, { useEffect, useState, useRef } from 'react'; // Removed useMemo
import axios from 'axios';
import { Bar, Line, Pie } from 'react-chartjs-2'; // Removed Doughnut
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  FaBoxOpen,
  FaUsers,
  FaMoneyBillWave,
  FaChartLine,
  FaUserPlus,
} from 'react-icons/fa';
import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const Overview = () => {
  // State to hold metrics and chart data
  const [metrics, setMetrics] = useState({
    totalSales: 0,
    totalOrders: 0,
    totalUsers: 0,
    averageOrderValue: 0,
    newUsersThisMonth: 0,
  });

  const [recentOrders, setRecentOrders] = useState([]);
  const [topProducts, setTopProducts] = useState([]);

  const [monthlySalesData, setMonthlySalesData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Monthly Sales',
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        fill: false,
      },
    ],
  });

  const [ordersStatusData, setOrdersStatusData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Orders by Status',
        data: [],
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
      },
    ],
  });

  const [loading, setLoading] = useState(true);
  // Removed 'error' state as it's unused
  // const [error, setError] = useState(false);

  // Ref to hold the socket instance
  const socketRef = useRef(null);

  // Function to fetch initial data
  const fetchInitialData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/dashboard/summary`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );

      // Check if response.data and response.data.data exist
      if (response.data && response.data.data) {
        const data = response.data.data;

        // Safely parse numerical values, defaulting to 0 if undefined or invalid
        const totalSales = Number(data.totalSales) || 0;
        const totalOrders = Number(data.totalOrders) || 0;
        const totalUsers = Number(data.totalUsers) || 0;
        const averageOrderValue = Number(data.averageOrderValue) || 0;
        const newUsersThisMonth = Number(data.newUsersThisMonth) || 0;

        setMetrics({
          totalSales,
          totalOrders,
          totalUsers,
          averageOrderValue,
          newUsersThisMonth,
        });

        // Set recentOrders
        setRecentOrders(data.recentOrders || []);

        // Set topProducts with parsed totalsold
        const parsedTopProducts = (data.topProducts || []).map((product) => ({
          ...product,
          totalsold: Number(product.totalsold) || 0,
        }));
        setTopProducts(parsedTopProducts);

        // Set monthlySalesData
        if (data.monthlySales && data.monthlySales.labels && data.monthlySales.data) {
          const salesData = data.monthlySales.data.map((value) => Number(value) || 0);
          setMonthlySalesData({
            labels: data.monthlySales.labels,
            datasets: [
              {
                label: 'Monthly Sales',
                data: salesData,
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
              },
            ],
          });
        }

        // Set ordersStatusData
        if (data.ordersStatusDistribution && data.ordersStatusDistribution.labels && data.ordersStatusDistribution.data) {
          setOrdersStatusData({
            labels: data.ordersStatusDistribution.labels,
            datasets: [
              {
                label: 'Orders by Status',
                data: data.ordersStatusDistribution.data,
                backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
              },
            ],
          });
        }

        setLoading(false);
      } else {
        throw new Error('Invalid data structure received from the API.');
      }
    } catch (error) {
      console.error('Failed to fetch initial overview data:', error);
      toast.error('Failed to load overview data.');
      // Removed setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch initial data on mount
    fetchInitialData();

    // Initialize Socket.io connection with auth token
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication token not found. Please log in.');
      return;
    }

    socketRef.current = io(process.env.REACT_APP_API_URL, {
      auth: {
        token: token,
      },
      transports: ['websocket'],
    });

    const socket = socketRef.current;

    // Handle connection
    socket.on('connect', () => {
      console.log('Connected to overview socket');
      toast.success('Live overview updates connected.');
    });

    // Listen for overview updates
    socket.on('overviewUpdate', (data) => {
      console.log('Received overview update:', data);

      // Safely parse and set metrics
      const totalSales = Number(data.totalSales) || 0;
      const totalOrders = Number(data.totalOrders) || 0;
      const totalUsers = Number(data.totalUsers) || 0;
      const averageOrderValue = Number(data.averageOrderValue) || 0;
      const newUsersThisMonth = Number(data.newUsersThisMonth) || 0;

      setMetrics({
        totalSales,
        totalOrders,
        totalUsers,
        averageOrderValue,
        newUsersThisMonth,
      });

      // Update recentOrders if provided
      if (data.recentOrders) {
        setRecentOrders(data.recentOrders);
      }

      // Update topProducts if provided
      if (data.topProducts) {
        const parsedTopProducts = data.topProducts.map((product) => ({
          ...product,
          totalsold: Number(product.totalsold) || 0,
        }));
        setTopProducts(parsedTopProducts);
      }

      // Update monthlySalesData if provided
      if (data.monthlySales && data.monthlySales.labels && data.monthlySales.data) {
        const salesData = data.monthlySales.data.map((value) => Number(value) || 0);
        setMonthlySalesData({
          labels: data.monthlySales.labels,
          datasets: [
            {
              label: 'Monthly Sales',
              data: salesData,
              borderColor: 'rgba(75, 192, 192, 1)',
              fill: false,
            },
          ],
        });
      }

      // Update ordersStatusData if provided
      if (data.ordersStatusDistribution && data.ordersStatusDistribution.labels && data.ordersStatusDistribution.data) {
        setOrdersStatusData({
          labels: data.ordersStatusDistribution.labels,
          datasets: [
            {
              label: 'Orders by Status',
              data: data.ordersStatusDistribution.data,
              backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
            },
          ],
        });
      }
    });

    // Handle disconnection
    socket.on('disconnect', () => {
      console.log('Disconnected from overview socket');
      toast.error('Live overview updates disconnected.');
    });

    // Handle connection errors
    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err.message);
      toast.error('Failed to connect to live overview updates.');
    });

    // Cleanup on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  // Removed memoizedSalesData and memoizedOrdersStatusData as they're unused
  /*
  const memoizedSalesData = useMemo(() => monthlySalesData, [monthlySalesData]);
  const memoizedOrdersStatusData = useMemo(() => ordersStatusData, [ordersStatusData]);
  */

  // Responsive chart options
  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'currentColor',
        },
      },
      title: {
        display: true,
        text: 'Monthly Sales Trend',
        color: 'currentColor',
      },
    },
    scales: {
      y: {
        ticks: {
          color: 'currentColor',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      x: {
        ticks: {
          color: 'currentColor',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          color: 'currentColor',
        },
      },
      title: {
        display: true,
        text: 'Orders by Status',
        color: 'currentColor',
      },
    },
    scales: {
      y: {
        ticks: {
          color: 'currentColor',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      x: {
        ticks: {
          color: 'currentColor',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Top Selling Products',
      },
    },
  };

  return (
    <div className="space-y-8">
      {/* Metrics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
        {/* Total Sales */}
        <div className="bg-blue-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaMoneyBillWave className="text-3xl mr-4" aria-hidden="true" />
          <div>
            <h2 className="text-xl">Total Sales</h2>
            {loading ? (
              <Skeleton width={100} height={32} />
            ) : (
              <p className="text-2xl font-bold">
                £{metrics.totalSales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </p>
            )}
          </div>
        </div>

        {/* Total Orders */}
        <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaBoxOpen className="text-3xl mr-4" aria-hidden="true" />
          <div>
            <h2 className="text-xl">Total Orders</h2>
            {loading ? (
              <Skeleton width={80} height={32} />
            ) : (
              <p className="text-2xl font-bold">{metrics.totalOrders.toLocaleString()}</p>
            )}
          </div>
        </div>

        {/* Total Users */}
        <div className="bg-yellow-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaUsers className="text-3xl mr-4" aria-hidden="true" />
          <div>
            <h2 className="text-xl">Total Users</h2>
            {loading ? (
              <Skeleton width={80} height={32} />
            ) : (
              <p className="text-2xl font-bold">{metrics.totalUsers.toLocaleString()}</p>
            )}
          </div>
        </div>

        {/* Average Order Value */}
        <div className="bg-purple-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaChartLine className="text-3xl mr-4" aria-hidden="true" />
          <div>
            <h2 className="text-xl">Avg. Order Value</h2>
            {loading ? (
              <Skeleton width={100} height={32} />
            ) : (
              <p className="text-2xl font-bold">
                £{metrics.averageOrderValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </p>
            )}
          </div>
        </div>

        {/* New Users This Month */}
        <div className="bg-indigo-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaUserPlus className="text-3xl mr-4" aria-hidden="true" />
          <div>
            <h2 className="text-xl">New Users This Month</h2>
            {loading ? (
              <Skeleton width={80} height={32} />
            ) : (
              <p className="text-2xl font-bold">{metrics.newUsersThisMonth.toLocaleString()}</p>
            )}
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Monthly Sales Trend Line Chart */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          {loading ? (
            <Skeleton height={300} />
          ) : (
            <Line data={monthlySalesData} options={lineChartOptions} aria-label="Monthly Sales Trend Chart" />
          )}
        </div>

        {/* Orders by Status Pie Chart */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          {loading ? (
            <Skeleton height={300} />
          ) : (
            <Pie data={ordersStatusData} options={pieChartOptions} aria-label="Orders by Status Chart" />
          )}
        </div>
      </div>

      {/* Top Selling Products */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl mb-4">Top Selling Products</h3>
        {loading ? (
          <Skeleton height={400} />
        ) : (
          <Bar
            data={{
              labels: topProducts.map((product) => product.name),
              datasets: [
                {
                  label: 'Units Sold',
                  data: topProducts.map((product) => product.totalsold),
                  backgroundColor: 'rgba(255, 99, 132, 0.6)',
                },
              ],
            }}
            options={barChartOptions}
            aria-label="Top Selling Products Chart"
          />
        )}
      </div>

      {/* Recent Orders Table */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg overflow-x-auto">
        <h3 className="text-2xl mb-4">Recent Orders</h3>
        {loading ? (
          <Skeleton count={5} height={40} />
        ) : recentOrders.length === 0 ? (
          <p>No recent orders available.</p>
        ) : (
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  Order ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  Total (£)
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {recentOrders.map((order) => (
                <tr key={order.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                    #{order.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                    {order.user?.username || 'Unknown'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                    £{Number(order.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadge(order.status)}`}>
                      {capitalizeFirstLetter(order.status)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {new Date(order.createdAt).toLocaleDateString(undefined, {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

// Helper function to capitalize first letter
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Helper function to get badge color based on status
const getStatusBadge = (status) => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'bg-yellow-100 text-yellow-800';
    case 'completed':
      return 'bg-green-100 text-green-800';
    case 'cancelled':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

export default Overview;
