// src/components/AppleLoginButton.js

import React, { useState, useEffect } from 'react';
import AppleLogin from 'react-apple-login';
import { useUserContext } from './contexts/UserContext';
import { toast } from 'react-toastify';
import { FaApple, FaSpinner } from 'react-icons/fa';

const AppleLoginButton = () => {
  const { loginWithApple } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = async (response) => {
    setIsLoading(true);
    try {
      const identityToken = response.authorization.id_token;
      await loginWithApple(identityToken);
      // Redirect handled in UserContext
    } catch (error) {
      console.error('Apple login error:', error);
      toast.error('Apple login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const onFailure = (error) => {
    console.error('Apple login failed:', error);
    toast.error('Apple login failed. Please try again.');
  };

  return (
    <div className="w-full">
      <AppleLogin
        clientId="com.yourapp.clientid" // Replace with your Apple Client ID
        redirectURI="https://yourapp.com/apple-auth" // Replace with your Redirect URI
        responseType="code id_token"
        responseMode="form_post"
        usePopup={true}
        designProp={{
          height: 44,
          width: '100%',
          color: 'black',
          border: false,
          type: 'sign-in',
          border_radius: 8,
          scale: 1,
          locale: 'en_US',
        }}
        onSuccess={onSuccess}
        onFailure={onFailure}
        render={(props) => (
          <button
            type="button"
            onClick={props.onClick}
            className={`w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm flex items-center justify-center bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition duration-300 ${
              isLoading ? 'cursor-not-allowed opacity-50' : ''
            }`}
            aria-label="Continue with Apple"
            disabled={isLoading}
          >
            {/* Apple Icon */}
            <FaApple className="w-5 h-5 mr-3 text-white" />
            {isLoading ? (
              <FaSpinner className="animate-spin text-white" />
            ) : (
              <span className="text-white font-medium">Continue with Apple</span>
            )}
          </button>
        )}
      />
    </div>
  );
};

export default AppleLoginButton;
