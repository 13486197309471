// frontend/src/disableToast.js

import { toast } from 'react-toastify';

/**
 * Overrides the toast functions to prevent any notifications from being shown.
 */
const disableToastNotifications = () => {
  // List of toast methods to override
  const toastMethods = ['success', 'error', 'info', 'warn', 'warning', 'default', 'dark'];

  toastMethods.forEach((method) => {
    toast[method] = () => {}; // Override with empty function
  });

  // Optionally, override other toast methods
  toast.dismiss = () => {};
  toast.configure = () => {};
};

export default disableToastNotifications;
