// frontend/src/components/StoreManagement.js

import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api';
import { motion } from 'framer-motion';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';

// Map container style
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

// Default center (you can change this to a default location)
const defaultCenter = {
  lat: 51.5074, // London's latitude
  lng: -0.1278, // London's longitude
};

// Form component for adding/editing stores
const StoreManagement = () => {
  const [stores, setStores] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    postcode: '',
    latitude: null,
    longitude: null,
    contactNumber: '',
    email: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingStoreId, setEditingStoreId] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markerPosition, setMarkerPosition] = useState(null);

  // Load Google Maps script
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  // Fetch all stores on mount
  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    try {
      const response = await api.get('/stores'); // Updated endpoint
      setStores(response.data.data);
    } catch (error) {
      toast.error('Failed to fetch stores.');
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle map click to set marker position
  const handleMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    setFormData((prevData) => ({
      ...prevData,
      latitude: lat,
      longitude: lng,
    }));
  }, []);

  // Handle marker drag end to update position
  const handleMarkerDragEnd = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    setFormData((prevData) => ({
      ...prevData,
      latitude: lat,
      longitude: lng,
    }));
  }, []);

  // Handle form submission for adding/editing stores
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['name', 'address', 'city', 'postcode', 'latitude', 'longitude'];
    for (let field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Please fill in the ${field} field.`);
        return;
      }
    }

    try {
      if (isEditing) {
        await api.put(`/stores/${editingStoreId}`, formData);
        toast.success('Store updated successfully!');
      } else {
        await api.post('/stores', formData);
        toast.success('Store added successfully!');
      }
      setFormData({
        name: '',
        address: '',
        city: '',
        postcode: '',
        latitude: null,
        longitude: null,
        contactNumber: '',
        email: '',
      });
      setMarkerPosition(null);
      setIsEditing(false);
      setEditingStoreId(null);
      fetchStores();
    } catch (error) {
      toast.error('Failed to save store.');
    }
  };

  // Handle edit button click
  const handleEdit = (store) => {
    setIsEditing(true);
    setEditingStoreId(store.id);
    setFormData({
      name: store.name,
      address: store.address,
      city: store.city,
      postcode: store.postcode,
      latitude: store.latitude || null,
      longitude: store.longitude || null,
      contactNumber: store.contactNumber || '',
      email: store.email || '',
    });
    setMarkerPosition({
      lat: store.latitude || defaultCenter.lat,
      lng: store.longitude || defaultCenter.lng,
    });
    setMapCenter({
      lat: store.latitude || defaultCenter.lat,
      lng: store.longitude || defaultCenter.lng,
    });
  };

  // Handle delete button click
  const handleDelete = async (storeId) => {
    try {
      await api.delete(`/stores/${storeId}`);
      toast.success('Store deleted successfully!');
      fetchStores();
    } catch (error) {
      toast.error('Failed to delete store.');
    }
  };

  // Geocode address when address fields change
  useEffect(() => {
    const geocodeAddress = async () => {
      if (!formData.address || !formData.city || !formData.postcode) return;
      try {
        const geocodeResponse = await api.get(
          'https://maps.googleapis.com/maps/api/geocode/json',
          {
            params: {
              address: `${formData.address}, ${formData.city}, ${formData.postcode}`,
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            },
          }
        );

        if (geocodeResponse.data.results.length > 0) {
          const location = geocodeResponse.data.results[0].geometry.location;
          setMarkerPosition({ lat: location.lat, lng: location.lng });
          setMapCenter({ lat: location.lat, lng: location.lng });
          setFormData((prevData) => ({
            ...prevData,
            latitude: location.lat,
            longitude: location.lng,
          }));
        }
      } catch (error) {
        console.error('Failed to geocode address:', error);
      }
    };

    geocodeAddress();
  }, [formData.address, formData.city, formData.postcode]);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Store Management</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Form Inputs */}
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Store Name"
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Address"
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          placeholder="City"
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
        <input
          type="text"
          name="postcode"
          value={formData.postcode}
          onChange={handleChange}
          placeholder="Postcode"
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
        {/* Map Component */}
        {isLoaded ? (
          <div className="w-full h-96 mb-4">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={mapCenter}
              zoom={15}
              onClick={handleMapClick}
            >
              {markerPosition && (
                <Marker
                  position={markerPosition}
                  draggable={true}
                  onDragEnd={handleMarkerDragEnd}
                />
              )}
            </GoogleMap>
          </div>
        ) : (
          <div>Loading map...</div>
        )}
        {/* Read-only Latitude and Longitude */}
        <input
          type="text"
          name="latitude"
          value={formData.latitude || ''}
          placeholder="Latitude"
          className="w-full p-2 border border-gray-300 rounded"
          readOnly
        />
        <input
          type="text"
          name="longitude"
          value={formData.longitude || ''}
          placeholder="Longitude"
          className="w-full p-2 border border-gray-300 rounded"
          readOnly
        />
        <input
          type="text"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleChange}
          placeholder="Contact Number"
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          className="w-full p-2 border border-gray-300 rounded"
        />
        {/* Submit Button */}
        <motion.button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded"
          whileHover={{ scale: 1.05 }}
        >
          {isEditing ? 'Update Store' : 'Add Store'}
        </motion.button>
      </form>

      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-2">Existing Stores</h3>
        <ul className="space-y-2">
          {stores.map((store) => (
            <li
              key={store.id}
              className="p-4 bg-gray-100 rounded flex justify-between items-center"
            >
              <span>
                {store.name} - {store.city} - {store.postcode}
              </span>
              <div className="space-x-2">
                <button onClick={() => handleEdit(store)} className="text-blue-500">
                  Edit
                </button>
                <button onClick={() => handleDelete(store.id)} className="text-red-500">
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StoreManagement;
