// frontend/src/components/EmptyCart.js

import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaShoppingCart } from 'react-icons/fa';

const EmptyCart = () => {
  return (
    <motion.div
      className="flex flex-col items-center justify-center h-full mt-16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <FaShoppingCart className="text-6xl text-blue-500 mb-4" />
      <h2 className="text-3xl font-bold mb-4">Your cart is empty.</h2>
      <Link
        to="/store"
        className="py-2 px-6 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg shadow-md transition-colors"
        aria-label="Continue shopping"
      >
        Continue Shopping
      </Link>
    </motion.div>
  );
};

export default memo(EmptyCart);
