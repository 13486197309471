// src/api.js

import axios from 'axios';
import axiosRetry from 'axios-retry';
import Bottleneck from 'bottleneck';
import logger from './utils/logger'; // Ensure this path is correct
import { toast } from 'react-toastify';

// Create a Bottleneck limiter
const limiter = new Bottleneck({
  minTime: 20, // Minimum time between requests (in ms)
  maxConcurrent: 5, // Maximum number of concurrent requests
});

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5003', // Ensure this matches your backend
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to include Authorization header if token is available
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Retrieve token from Local Storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    logger.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

// Response interceptor for logging and additional handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      logger.error(
        `API Error: ${error.response.status} - ${error.response.statusText}`,
        { data: error.response.data }
      );
      if (error.response.status === 403) {
        toast.error('Access denied. You do not have permission to perform this action.');
      } else if (error.response.status === 401) {
        toast.error('Unauthorized. Please log in again.');
        // Optionally, you can trigger a logout or redirect to login page here
      } else if (error.response.status === 429) {
        toast.error('Too many requests. Please try again later.');
      } else {
        toast.error(`An error occurred: ${error.response.data.message || 'Please try again.'}`);
      }
    } else {
      logger.error('API Error:', error.message);
      toast.error('Network error. Please check your connection.');
    }
    return Promise.reject(error);
  }
);

// Configure axios-retry to handle specific retry conditions
axiosRetry(api, {
  retries: 2, // Number of retry attempts
  retryCondition: (error) => {
    // Retry only if the error status is 429 (Too Many Requests) or network errors
    return (
      axiosRetry.isNetworkError(error) ||
      (error.response && error.response.status === 429)
    );
  },
  retryDelay: (retryCount, error) => {
    if (error.response && error.response.status === 429) {
      // Respect the Retry-After header if present
      const retryAfter = parseInt(error.response.headers['retry-after'], 10);
      if (!isNaN(retryAfter)) {
        return retryAfter * 1000; // Convert to milliseconds
      }
    }
    // Otherwise, use exponential backoff
    return axiosRetry.exponentialDelay(retryCount);
  },
});

// Wrap Axios methods with Bottleneck's schedule
const limitedApi = {
  get: (url, config) => limiter.schedule(() => api.get(url, config)),
  post: (url, data, config) => limiter.schedule(() => api.post(url, data, config)),
  put: (url, data, config) => limiter.schedule(() => api.put(url, data, config)),
  delete: (url, config) => limiter.schedule(() => api.delete(url, config)),
  // Add other methods if needed
};

// Define API functions

/**
 * Fetch products with optional query parameters.
 * 
 * @param {Object} params - Query parameters for filtering and pagination.
 * @returns {Promise<Object>} - The API response data.
 */
const fetchProductsAPI = async (params = {}) => {
  try {
    const response = await limitedApi.get('/products', { params });
    return response.data;
  } catch (error) {
    logger.error('Failed to fetch products:', error);
    throw error;
  }
};

/**
 * Fetch a single product by ID.
 * 
 * @param {number|string} productId - The ID of the product.
 * @param {Object} [params] - Additional query parameters.
 * @returns {Promise<Object>} - The API response data.
 */
const fetchProductByIdAPI = async (productId, params = {}) => {
  console.log('fetchProductByIdAPI called with productId:', productId);
  try {
    const response = await limitedApi.get(`/products/${productId}`, { params });
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch product with ID ${productId}:`, error);
    throw error;
  }
};

/**
 * Add a review to a product.
 * 
 * @param {number|string} productId - The ID of the product.
 * @param {Object} reviewData - The review data (rating, comment).
 * @returns {Promise<Object>} - The API response data.
 */
const addProductReviewAPI = async (productId, reviewData) => {
  try {
    const response = await limitedApi.post(`/products/${productId}/reviews`, reviewData);
    return response.data;
  } catch (error) {
    logger.error(`Failed to add review to product ID ${productId}:`, error);
    throw error;
  }
};

/**
 * Search for products by query.
 * 
 * @param {Object} params - Search parameters (query, page, limit).
 * @returns {Promise<Object>} - The API response data.
 */
const searchProductsAPI = async (params) => {
  try {
    const response = await limitedApi.get('/products/search', { params });
    return response.data;
  } catch (error) {
    logger.error('Failed to search products:', error);
    throw error;
  }
};

/**
 * Fetch all stores.
 * 
 * @returns {Promise<Object>} - The API response data.
 */
const fetchStoresAPI = async () => {
  try {
    const response = await limitedApi.get('/stores');
    return response.data;
  } catch (error) {
    logger.error('Failed to fetch stores:', error);
    throw error;
  }
};

/**
 * Fetch orders with optional query parameters.
 * 
 * @param {Object} params - Query parameters for filtering and pagination.
 * @returns {Promise<Object>} - The API response data.
 */
const fetchOrdersAPI = async (params = {}) => {
  try {
    const response = await limitedApi.get('/orders', { params });
    return response.data;
  } catch (error) {
    logger.error('Failed to fetch orders:', error);
    throw error;
  }
};

/**
 * Fetch detailed information about a single order by ID.
 * 
 * @param {number|string} orderId - The ID of the order.
 * @returns {Promise<Object>} - The API response data.
 */
const fetchOrderDetailsAPI = async (orderId) => {
  try {
    const response = await limitedApi.get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    logger.error(`Failed to fetch details for order ID ${orderId}:`, error);
    throw error;
  }
};

// Export all functions as named exports
export {
  addProductReviewAPI,
  fetchProductByIdAPI,
  fetchProductsAPI,
  fetchStoresAPI,
  searchProductsAPI,
  fetchOrdersAPI,
  fetchOrderDetailsAPI,
  // ... other exports
};

export default limitedApi;
