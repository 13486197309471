// src/components/CatBar.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FaTimes,
  FaChevronDown,
  FaChevronUp,
  FaSearch,
  FaStar,
  FaListAlt, // Icon for "All" option
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

/**
 * Enhanced CatBar Component
 *
 * Features:
 * - "All" Option with Icon
 * - Category Images
 * - Popular Categories Section
 * - Collapsible Subcategories
 * - Search Functionality within Sidebar
 * - Favorites Section (Optional)
 * - Improved Styling and Animations
 * - Accessibility Enhancements
 * - Responsive Design
 */
const CatBar = ({
  categories,
  popularCategories,
  selectedCategory,
  onSelectCategory,
  isOpen,
  toggleSidebar,
  favorites, // Optional: Array of favorite category IDs
  onToggleFavorite, // Optional: Function to toggle favorite categories
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({});

  /**
   * Handles toggling of subcategories
   * @param {string|number} categoryId
   */
  const toggleSubcategories = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  /**
   * Filters categories based on search term
   */
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  /**
   * Handles favorite toggle (Optional)
   */
  const handleFavoriteToggle = (categoryId) => {
    if (onToggleFavorite) {
      onToggleFavorite(categoryId);
    }
  };

  return (
    <>
      {/* Overlay for Mobile when CatBar is open */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black z-40 md:hidden"
            onClick={toggleSidebar}
            aria-hidden="true"
          ></motion.div>
        )}
      </AnimatePresence>

      {/* Sidebar Drawer for Mobile */}
      <AnimatePresence>
        {isOpen && (
          <motion.aside
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed top-0 left-0 h-full w-72 bg-white shadow-lg z-50 transform md:translate-x-0 md:static md:shadow-none dark:bg-gray-800"
            aria-label="Category Sidebar"
          >
            {/* Sidebar Header */}
            <div className="flex items-center justify-between px-4 py-4 border-b dark:border-gray-700">
              {/* Remove the "Categories" title as per request */}
              {/* Close button for Mobile */}
              <button
                onClick={toggleSidebar}
                className="text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white focus:outline-none md:hidden"
                aria-label="Close Categories"
              >
                <FaTimes size={20} />
              </button>
            </div>

            {/* Search Bar */}
            <div className="px-4 py-2">
              <div className="relative">
                <FaSearch className="absolute left-3 top-3 text-gray-400 dark:text-gray-500" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search categories..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-500 dark:focus:ring-pink-400 text-gray-800 dark:text-gray-100"
                  aria-label="Search Categories"
                />
              </div>
            </div>

            {/* "All" Option */}
            <div className="px-4 py-2">
              <button
                onClick={() => onSelectCategory('all')}
                className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                  selectedCategory === 'all'
                    ? 'bg-pink-500 text-white'
                    : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                }`}
                aria-pressed={selectedCategory === 'all'}
              >
                {/* "All" Icon */}
                <FaListAlt className="mr-2 text-lg" />
                <span className="flex-1 text-left">All</span>
              </button>
            </div>

            {/* Popular Categories Section */}
            {popularCategories && popularCategories.length > 0 && (
              <div className="px-4 py-2">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                  Popular
                </h3>
                <ul className="space-y-2">
                  {popularCategories.map((category) => (
                    <li key={category.id}>
                      <button
                        onClick={() => {
                          onSelectCategory(category.id);
                          toggleSidebar(); // Close sidebar after selection
                        }}
                        className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                          selectedCategory === category.id
                            ? 'bg-pink-500 text-white'
                            : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                        }`}
                        aria-pressed={selectedCategory === category.id}
                      >
                        {/* Category Image */}
                        {category.image && (
                          <img
                            src={category.image}
                            alt={category.name}
                            className="w-6 h-6 mr-2 object-cover rounded-full"
                          />
                        )}
                        <span className="flex-1 text-left">{category.name}</span>
                        {/* Star Icon to indicate popularity */}
                        <FaStar className="text-yellow-400" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Favorites Section (Optional) */}
            {favorites && favorites.length > 0 && (
              <div className="px-4 py-2">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                  Favorites
                </h3>
                <ul className="space-y-2">
                  {favorites.map((favId) => {
                    const favoriteCategory = categories.find(
                      (cat) => cat.id === favId
                    );
                    if (!favoriteCategory) return null;
                    return (
                      <li key={favId}>
                        <button
                          onClick={() => handleFavoriteToggle(favId)}
                          className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                            selectedCategory === favId
                              ? 'bg-pink-500 text-white'
                              : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                          }`}
                          aria-pressed={selectedCategory === favId}
                        >
                          {/* Category Image */}
                          {favoriteCategory.image && (
                            <img
                              src={favoriteCategory.image}
                              alt={favoriteCategory.name}
                              className="w-6 h-6 mr-2 object-cover rounded-full"
                            />
                          )}
                          <span className="flex-1 text-left">
                            {favoriteCategory.name}
                          </span>
                          {/* Optional: Add a favorite icon or badge */}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {/* Categories List */}
            <nav className="px-4 py-2 overflow-y-auto">
              <ul className="space-y-1">
                {filteredCategories.length > 0 ? (
                  filteredCategories.map((category) => (
                    <li key={category.id}>
                      <div className="flex items-center justify-between">
                        <button
                          onClick={() => onSelectCategory(category.id)}
                          className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                            selectedCategory === category.id
                              ? 'bg-pink-500 text-white'
                              : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                          }`}
                          aria-current={selectedCategory === category.id ? 'page' : undefined}
                        >
                          {/* Category Image */}
                          {category.image && (
                            <img
                              src={category.image}
                              alt={category.name}
                              className="w-6 h-6 mr-2 object-cover rounded-full"
                            />
                          )}
                          <span className="flex-1 text-left">{category.name}</span>
                          {/* Chevron for Subcategories */}
                          {category.subcategories && category.subcategories.length > 0 && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSubcategories(category.id);
                              }}
                              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none"
                              aria-label={
                                expandedCategories[category.id]
                                  ? 'Collapse Subcategories'
                                  : 'Expand Subcategories'
                              }
                            >
                              {expandedCategories[category.id] ? (
                                <FaChevronUp />
                              ) : (
                                <FaChevronDown />
                              )}
                            </button>
                          )}
                        </button>
                      </div>

                      {/* Subcategories */}
                      {category.subcategories && category.subcategories.length > 0 && (
                        <AnimatePresence>
                          {expandedCategories[category.id] && (
                            <motion.ul
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: 'auto', opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                              className="pl-8 mt-1 space-y-1"
                            >
                              {category.subcategories.map((subcat) => (
                                <li key={subcat.id}>
                                  <button
                                    onClick={() => onSelectCategory(subcat.id)}
                                    className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                                      selectedCategory === subcat.id
                                        ? 'bg-pink-400 text-white'
                                        : 'text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600'
                                    }`}
                                    aria-pressed={selectedCategory === subcat.id}
                                  >
                                    {/* Subcategory Image */}
                                    {subcat.image && (
                                      <img
                                        src={subcat.image}
                                        alt={subcat.name}
                                        className="w-6 h-6 mr-2 object-cover rounded-full"
                                      />
                                    )}
                                    <span className="flex-1 text-left">{subcat.name}</span>
                                  </button>
                                </li>
                              ))}
                            </motion.ul>
                          )}
                        </AnimatePresence>
                      )}
                    </li>
                  ))
                ) : (
                  <li className="text-gray-500 dark:text-gray-400">No categories found.</li>
                )}
              </ul>
            </nav>
          </motion.aside>
        )}
      </AnimatePresence>

      {/* Sidebar for Desktop */}
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, x: '-100%' }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: '-100%' }}
          transition={{ type: 'tween', duration: 0.3 }}
          className="w-72 bg-white shadow-md p-4 hidden md:block dark:bg-gray-800"
          aria-label="Category Sidebar"
        >
          {/* "All" Option */}
          <div className="mb-4">
            <button
              onClick={() => onSelectCategory('all')}
              className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                selectedCategory === 'all'
                  ? 'bg-pink-500 text-white'
                  : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
              }`}
              aria-pressed={selectedCategory === 'all'}
            >
              {/* "All" Icon */}
              <FaListAlt className="mr-2 text-lg" />
              <span className="flex-1 text-left">All</span>
            </button>
          </div>

          {/* Popular Categories Section for Desktop */}
          {popularCategories && popularCategories.length > 0 && (
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                Popular
              </h3>
              <ul className="space-y-2">
                {popularCategories.map((category) => (
                  <li key={category.id}>
                    <button
                      onClick={() => onSelectCategory(category.id)}
                      className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                        selectedCategory === category.id
                          ? 'bg-pink-500 text-white'
                          : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                      }`}
                      aria-pressed={selectedCategory === category.id}
                    >
                      {/* Category Image */}
                      {category.image && (
                        <img
                          src={category.image}
                          alt={category.name}
                          className="w-6 h-6 mr-2 object-cover rounded-full"
                        />
                      )}
                      <span className="flex-1 text-left">{category.name}</span>
                      {/* Star Icon to indicate popularity */}
                      <FaStar className="text-yellow-400" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Favorites Section (Optional) */}
          {favorites && favorites.length > 0 && (
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                Favorites
              </h3>
              <ul className="space-y-2">
                {favorites.map((favId) => {
                  const favoriteCategory = categories.find(
                    (cat) => cat.id === favId
                  );
                  if (!favoriteCategory) return null;
                  return (
                    <li key={favId}>
                      <button
                        onClick={() => handleFavoriteToggle(favId)}
                        className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                          selectedCategory === favId
                            ? 'bg-pink-500 text-white'
                            : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                        }`}
                        aria-pressed={selectedCategory === favId}
                      >
                        {/* Category Image */}
                        {favoriteCategory.image && (
                          <img
                            src={favoriteCategory.image}
                            alt={favoriteCategory.name}
                            className="w-6 h-6 mr-2 object-cover rounded-full"
                          />
                        )}
                        <span className="flex-1 text-left">
                          {favoriteCategory.name}
                        </span>
                        {/* Optional: Add a favorite icon or badge */}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {/* Categories List for Desktop */}
          <ul className="space-y-2">
            {categories.map((category) => (
              <li key={category.id}>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => onSelectCategory(category.id)}
                    className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                      selectedCategory === category.id
                        ? 'bg-pink-500 text-white'
                        : 'text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600'
                    }`}
                    aria-pressed={selectedCategory === category.id}
                  >
                    {/* Category Image */}
                    {category.image && (
                      <img
                        src={category.image}
                        alt={category.name}
                        className="w-6 h-6 mr-2 object-cover rounded-full"
                      />
                    )}
                    <span className="flex-1 text-left">{category.name}</span>
                    {/* Chevron for Subcategories */}
                    {category.subcategories && category.subcategories.length > 0 && (
                      <button
                        onClick={() => toggleSubcategories(category.id)}
                        className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none"
                        aria-label={
                          expandedCategories[category.id]
                            ? 'Collapse Subcategories'
                            : 'Expand Subcategories'
                        }
                      >
                        {expandedCategories[category.id] ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </button>
                    )}
                  </button>
                </div>

                {/* Subcategories */}
                {category.subcategories && category.subcategories.length > 0 && (
                  <AnimatePresence>
                    {expandedCategories[category.id] && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="pl-8 mt-1 space-y-1"
                      >
                        {category.subcategories.map((subcat) => (
                          <li key={subcat.id}>
                            <button
                              onClick={() => onSelectCategory(subcat.id)}
                              className={`flex items-center w-full px-3 py-2 rounded-md focus:outline-none ${
                                selectedCategory === subcat.id
                                  ? 'bg-pink-400 text-white'
                                  : 'text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600'
                              }`}
                              aria-pressed={selectedCategory === subcat.id}
                            >
                              {/* Subcategory Image */}
                              {subcat.image && (
                                <img
                                  src={subcat.image}
                                  alt={subcat.name}
                                  className="w-6 h-6 mr-2 object-cover rounded-full"
                                />
                              )}
                              <span className="flex-1 text-left">{subcat.name}</span>
                            </button>
                          </li>
                        ))}
                      </motion.ul>
                    )}
                  </AnimatePresence>
                )}
              </li>
            ))}
          </ul>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

CatBar.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string, // URL for category image
      icon: PropTypes.element, // Optional React element for icon
      subcategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          name: PropTypes.string.isRequired,
          image: PropTypes.string, // URL for subcategory image
          icon: PropTypes.element, // Optional React element for icon
        })
      ),
    })
  ).isRequired,
  popularCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string, // URL for popular category image
      icon: PropTypes.element, // Optional React element for icon
    })
  ),
  favorites: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ), // Optional: Array of favorite category IDs
  onToggleFavorite: PropTypes.func, // Optional: Function to toggle favorite categories
  selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelectCategory: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

CatBar.defaultProps = {
  popularCategories: [],
  favorites: [],
  onToggleFavorite: null,
};

export default React.memo(CatBar);
