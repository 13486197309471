// src/components/UsersManagement.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import StatusFilter from './StatusFilter';
import { FaEdit, FaTrashAlt, FaSave, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material'; // Import CircularProgress
import { capitalizeWords } from '../utils'; // Import capitalizeWords

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('all');
  const [sortField, setSortField] = useState('username');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [editedUsers, setEditedUsers] = useState({});
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token
        if (!token) {
          toast.error('Authentication token not found. Please log in.');
          setLoading(false);
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/admin`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Log the response to inspect its structure
        console.log('API Response:', response.data);

        // Check if response.data.data.users exists and is an array
        if (response.data && response.data.success && Array.isArray(response.data.data.users)) {
          setUsers(response.data.data.users);
        } else {
          console.error('Unexpected API response structure:', response.data);
          toast.error('Failed to fetch users: Unexpected response format.');
        }

        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch users:', error);
        if (error.response) {
          // Server responded with a status other than 2xx
          toast.error(`Error: ${error.response.data.message || 'Failed to fetch users.'}`);
        } else if (error.request) {
          // Request was made but no response received
          toast.error('Network error: No response from server.');
        } else {
          // Something else caused the error
          toast.error(`Error: ${error.message}`);
        }
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const editUser = (userId) => {
    setEditMode({ ...editMode, [userId]: true });
    const userToEdit = users.find(user => user.userId === userId);
    if (userToEdit) {
      setEditedUsers({ ...userToEdit });
    }
  };

  const saveUser = async (userId) => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token
      if (!token) {
        toast.error('Authentication token not found. Please log in.');
        return;
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, editedUsers, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(users.map(user => (user.userId === userId ? editedUsers : user)));
      setEditMode({ ...editMode, [userId]: false });
      setEditedUsers({});
      toast.success('User updated successfully');
    } catch (error) {
      console.error('Failed to update user:', error);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message || 'Failed to update user.'}`);
      } else if (error.request) {
        toast.error('Network error: No response from server.');
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  const deleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;

    try {
      const token = localStorage.getItem('token'); // Retrieve token
      if (!token) {
        toast.error('Authentication token not found. Please log in.');
        return;
      }

      await axios.delete(`${process.env.REACT_APP_API_URL}/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(users.filter(user => user.userId !== userId));
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Failed to delete user:', error);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message || 'Failed to delete user.'}`);
      } else if (error.request) {
        toast.error('Network error: No response from server.');
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleFilterChange = (status) => {
    setFilter(status);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleSort = (field) => {
    const order = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers(
      selectedUsers.includes(userId)
        ? selectedUsers.filter(id => id !== userId)
        : [...selectedUsers, userId]
    );
  };

  const handleMultiDelete = async () => {
    if (selectedUsers.length === 0) {
      toast.info('No users selected for deletion.');
      return;
    }

    if (!window.confirm('Are you sure you want to delete the selected users?')) return;

    try {
      const token = localStorage.getItem('token'); // Retrieve token
      if (!token) {
        toast.error('Authentication token not found. Please log in.');
        return;
      }

      await Promise.all(selectedUsers.map(userId => axios.delete(`${process.env.REACT_APP_API_URL}/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })));
      setUsers(users.filter(user => !selectedUsers.includes(user.userId)));
      setSelectedUsers([]);
      toast.success('Selected users deleted successfully');
    } catch (error) {
      console.error('Failed to delete selected users:', error);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message || 'Failed to delete selected users.'}`);
      } else if (error.request) {
        toast.error('Network error: No response from server.');
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  const handleUserEditChange = (userId, field, value) => {
    setEditedUsers({
      ...editedUsers,
      [field]: value,
    });
  };

  const filteredUsers = users
    .filter(user => user.username.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter(user => filter === 'all' || user.role.toLowerCase() === filter.toLowerCase())
    .sort((a, b) => {
      const aField = a[sortField];
      const bField = b[sortField];

      if (aField < bField) return sortOrder === 'asc' ? -1 : 1;
      if (aField > bField) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

  const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress /> {/* Display loading spinner */}
      </div>
    );
  }

  return (
    <div className="ml-64 p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Users</h1>
      <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div className="flex items-center mb-2 sm:mb-0">
          <input
            type="text"
            placeholder="Search by username"
            value={searchQuery}
            onChange={handleSearch}
            className="border rounded p-2 bg-gray-200 mr-4"
            aria-label="Search Users"
          />
          <StatusFilter filter={filter} onChange={handleFilterChange} />
        </div>
        {selectedUsers.length > 0 && (
          <div>
            <button
              onClick={handleMultiDelete}
              className="bg-red-500 text-white py-2 px-4 rounded flex items-center"
              aria-label="Delete Selected Users"
            >
              <FaTrashAlt className="mr-2" />
              Delete Selected ({selectedUsers.length})
            </button>
          </div>
        )}
      </div>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">
              <input 
                type="checkbox" 
                checked={selectedUsers.length === users.length && users.length > 0}
                onChange={(e) => setSelectedUsers(e.target.checked ? users.map(user => user.userId) : [])}
                aria-label="Select All Users"
              />
            </th>
            <th
              className="py-2 px-4 border-b cursor-pointer"
              onClick={() => handleSort('userId')}
              aria-sort={sortField === 'userId' ? sortOrder : 'none'}
            >
              User ID {sortField === 'userId' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th
              className="py-2 px-4 border-b cursor-pointer"
              onClick={() => handleSort('username')}
              aria-sort={sortField === 'username' ? sortOrder : 'none'}
            >
              Username {sortField === 'username' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th
              className="py-2 px-4 border-b cursor-pointer"
              onClick={() => handleSort('email')}
              aria-sort={sortField === 'email' ? sortOrder : 'none'}
            >
              Email {sortField === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th
              className="py-2 px-4 border-b cursor-pointer"
              onClick={() => handleSort('role')}
              aria-sort={sortField === 'role' ? sortOrder : 'none'}
            >
              Role {sortField === 'role' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center py-4">No users found.</td>
            </tr>
          ) : (
            paginatedUsers.map((user) => (
              <tr
                key={user.userId}
                className={`${selectedUsers.includes(user.userId) ? 'bg-gray-100' : ''}`}
              >
                <td className="py-2 px-4 border-b text-center">
                  <input 
                    type="checkbox" 
                    checked={selectedUsers.includes(user.userId)}
                    onChange={() => handleSelectUser(user.userId)}
                    aria-label={`Select user ${user.username}`}
                  />
                </td>
                <td className="py-2 px-4 border-b">{user.userId}</td>
                <td className="py-2 px-4 border-b">
                  {editMode[user.userId] ? (
                    <input
                      type="text"
                      value={editedUsers.username || ''}
                      onChange={(e) => handleUserEditChange(user.userId, 'username', e.target.value)}
                      className="border rounded p-2 bg-gray-100 w-full"
                      aria-label={`Edit username for user ${user.username}`}
                    />
                  ) : (
                    user.username
                  )}
                </td>
                <td className="py-2 px-4 border-b">
                  {editMode[user.userId] ? (
                    <input
                      type="email"
                      value={editedUsers.email || ''}
                      onChange={(e) => handleUserEditChange(user.userId, 'email', e.target.value)}
                      className="border rounded p-2 bg-gray-100 w-full"
                      aria-label={`Edit email for user ${user.username}`}
                    />
                  ) : (
                    user.email
                  )}
                </td>
                <td className="py-2 px-4 border-b">
                  {editMode[user.userId] ? (
                    <select
                      value={editedUsers.role || 'user'}
                      onChange={(e) => handleUserEditChange(user.userId, 'role', e.target.value)}
                      className="border rounded p-2 bg-gray-100 w-full"
                      aria-label={`Edit role for user ${user.username}`}
                    >
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                  ) : (
                    capitalizeWords(user.role)
                  )}
                </td>
                <td className="py-2 px-4 border-b flex items-center">
                  {editMode[user.userId] ? (
                    <>
                      <button
                        onClick={() => saveUser(user.userId)}
                        className="bg-green-500 text-white p-2 rounded mr-2"
                        aria-label={`Save changes for user ${user.username}`}
                      >
                        <FaSave />
                      </button>
                      <button
                        onClick={() => {
                          setEditMode({ ...editMode, [user.userId]: false });
                          setEditedUsers({});
                        }}
                        className="bg-gray-500 text-white p-2 rounded"
                        aria-label={`Cancel editing for user ${user.username}`}
                      >
                        <FaTimes />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => editUser(user.userId)}
                        className="bg-blue-500 text-white p-2 rounded mr-2"
                        aria-label={`Edit user ${user.username}`}
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => deleteUser(user.userId)}
                        className="bg-red-500 text-white p-2 rounded"
                        aria-label={`Delete user ${user.username}`}
                      >
                        <FaTrashAlt />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Pagination
        totalItems={filteredUsers.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default UsersManagement;
