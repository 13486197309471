// src/Pages/Cancel.js

import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Cancel = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-100">
      <motion.h1
        className="text-4xl font-bold text-red-600 mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Payment Canceled
      </motion.h1>
      <p className="text-lg mb-6">Your payment was canceled. You can try again.</p>
      <Link to="/" className="text-blue-500 hover:underline">
        Return to Home
      </Link>
    </div>
  );
};

export default Cancel;
