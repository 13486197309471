// src/components/contexts/StoreContext.js

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from './UserContext';
import api from '../../api'; // Adjust the import based on your project structure
import logger from '../../utils/logger';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { milesToKilometers } from '../../utils/convertUnits'; // Correct import path
import { io } from 'socket.io-client';
import config from './config'; // Adjust the path based on your project structure

// Create StoreContext
const StoreContext = createContext();

// Export useStore hook for easy consumption
export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};

// StoreProvider component that wraps around your application
export const StoreProvider = ({ children }) => {
  const { token, user } = useUserContext();

  // State Variables
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    totalPages: 1,
  });
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [userReview, setUserReview] = useState({ rating: 0, comment: '' });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Modal States
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false);
  const [selectedProductForCart, setSelectedProductForCart] = useState(null);

  // Toggle State
  const [toggle, setToggle] = useState(false);

  // Store State Variables
  const [stores, setStores] = useState([]);
  const [nearestStore, setNearestStore] = useState(null);
  const [loadingStores, setLoadingStores] = useState(false);
  const [storeError, setStoreError] = useState(null);

  // Payment Methods
  const [paymentMethods, setPaymentMethods] = useState([]);

  // Payments
  const [payments, setPayments] = useState([]);

  // Flag to ensure initialization runs only once
  const [initialized, setInitialized] = useState(false);

  // Pagination State
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page

  // Toggle function if needed
  const toggleFunction = useCallback(() => {
    setToggle((prevToggle) => !prevToggle);
  }, []); // No dependencies since it only uses setToggle

  // userHasPurchased state
  const [userHasPurchased, setUserHasPurchased] = useState(false);

  // Selected Store State
  const [selectedStore, setSelectedStore] = useState(null);

  /**
   * Utility Function: Calculate Distance Between Two Coordinates
   * @param {number} lat1
   * @param {number} lon1
   * @param {number} lat2
   * @param {number} lon2
   * @returns {number} - Distance in kilometers
   */
  const calculateDistanceKm = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degrees) => degrees * (Math.PI / 180);
    const R = 6371; // Earth's radius in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  /**
   * Example Function to Test Distance Calculation
   * Call this function to verify if your store falls within a desired radius
   */
  const testStoreDistance = useCallback(() => {
    const queryLat = 53.6876279;
    const queryLon = -2.574087;
    const storeLat = 53.568118;
    const storeLon = -2.201608;
    const distance = calculateDistanceKm(queryLat, queryLon, storeLat, storeLon);
    console.log(`Distance between query location and store: ${distance.toFixed(2)} km`);
    // You can also display this in the UI or use it for conditional logic
  }, []);

  useEffect(() => {
    testStoreDistance();
  }, [testStoreDistance]);

  /**
   * Fetches all product categories.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchCategories = useCallback(async () => {
    if (!token) {
      logger.warn('fetchCategories called without a valid token.');
      setError('Authentication required to fetch categories.');
      toast.error('You must be logged in to fetch categories.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      console.log('Fetching categories...');
      const response = await api.get('/categories', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories(response.data.data);
      logger.info('Fetched categories successfully.');
      console.log('Fetched categories:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch categories:', err);
      setError('Failed to fetch categories');
      toast.error('Failed to fetch categories.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Fetches all ingredients.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchIngredients = useCallback(async () => {
    if (!token) {
      logger.warn('fetchIngredients called without a valid token.');
      setError('Authentication required to fetch ingredients.');
      toast.error('You must be logged in to fetch ingredients.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      console.log('Fetching ingredients...');
      const response = await api.get('/ingredients', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIngredients(response.data.data);
      logger.info('Fetched ingredients successfully.');
      console.log('Fetched ingredients:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch ingredients:', err);
      setError('Failed to fetch ingredients');
      toast.error('Failed to fetch ingredients.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Fetches all stores (requires authentication).
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchStores = useCallback(async () => {
    if (!token) {
      logger.warn('fetchStores called without a valid token.');
      setStoreError('Authentication required to fetch stores.');
      toast.error('You must be logged in to fetch stores.');
      return;
    }

    setLoadingStores(true);
    setStoreError(null);
    try {
      console.log('Fetching all stores...');
      const response = await api.get('/stores', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStores(response.data.data);
      logger.info('Fetched stores successfully.');
    } catch (err) {
      logger.error('Failed to fetch stores:', err);
      setStoreError('Failed to fetch stores');
      toast.error('Failed to fetch stores.');
    } finally {
      setLoadingStores(false);
    }
  }, [token]);

  /**
   * Fetches all products with optional parameters.
   * @param {Object} params - The query parameters for fetching products.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchProducts = useCallback(
    async (
      params = {
        page: 1,
        limit: 10,
        storeId: null,
        categoryId: null,
        sortBy: 'createdAt',
        order: 'desc',
      }
    ) => {
      if (!token) {
        logger.warn('fetchProducts called without a valid token.');
        setError('Authentication required to fetch products.');
        toast.error('You must be logged in to fetch products.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        // Destructure params and set default values
        const {
          storeId,
          categoryId,
          sortBy = 'createdAt', // Default sortBy
          order = 'desc', // Default order
          page = 1,
          limit = 10,
        } = params;

        // Build the query object conditionally
        const queryParams = {
          page,
          limit,
          sortBy,
          order,
        };

        if (storeId) {
          queryParams.storeId = storeId;
        }

        if (categoryId && Number.isInteger(Number(categoryId))) {
          queryParams.categoryId = Number(categoryId);
        }

        console.log('Fetching products with params:', queryParams);

        // Make the API call with the constructed query parameters
        const response = await api.get('/products', {
          params: queryParams,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedProducts = response.data.data;

        setProducts(fetchedProducts); // Products already include ingredients
        setPagination(response.data.pagination);
        logger.info(`Fetched products successfully. Page: ${page}`);
        console.log(`Fetched ${fetchedProducts.length} products for page ${page}`);
      } catch (err) {
        logger.error('Failed to fetch products:', err);
        setError('Failed to fetch products');
        toast.error('Failed to fetch products.');
      } finally {
        setLoading(false);
      }
    },
    [token] // Dependency: token
  );

  /**
   * Fetches store details by store ID (Public)
   * @param {number|string} storeId - The ID of the store to fetch details for.
   * @returns {Promise<Object|null>} - The store details or null if failed.
   */
  const fetchStoreDetailsPublic = useCallback(
    async (storeId) => {
      if (!storeId) {
        setStoreError('Store ID is required to fetch store details.');
        toast.error('Store ID is required to fetch store details.');
        return null;
      }

      setLoadingStores(true);
      setStoreError(null);
      try {
        console.log(`Fetching store details for store ID ${storeId} (public)`);
        const response = await api.get(`/public/stores/${storeId}`, {
          params: { include: 'products' }, // Adjust based on backend implementation
        });
        const storeDetails = response.data.data;

        setStores([storeDetails]); // Assuming single store
        logger.info(`Fetched store details for Store ID ${storeId}.`);
        console.log('Fetched store details (public):', storeDetails);
        return storeDetails;
      } catch (err) {
        logger.error(`Failed to fetch store details for Store ID ${storeId} (public):`, err);
        setStoreError('Failed to fetch store details.');
        toast.error('Failed to fetch store details.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    []
  );

  /**
   * Fetches products for a specific store (Public)
   * @param {number|string} storeId - The ID of the store to fetch products for.
   * @param {Object} params - Optional query parameters (e.g., pagination, sorting).
   * @returns {Promise<Array|null>} - Array of products or null if failed.
   */
  const fetchStoreProductsPublic = useCallback(
    async (storeId, params = {}) => {
      if (!storeId) {
        setStoreError('Store ID is required to fetch store products.');
        toast.error('Store ID is required to fetch store products.');
        return null;
      }

      setLoadingStores(true);
      setStoreError(null);
      try {
        console.log(`Fetching products for store ID ${storeId} (public) with params:`, params);
        const response = await api.get(`/public/stores/${storeId}/products`, {
          params, // Include any pagination or filtering parameters
        });
        const storeProducts = response.data.data;

        setProducts(storeProducts);
        logger.info(`Fetched products for Store ID ${storeId} (public).`);
        console.log('Fetched store products (public):', storeProducts);
        return storeProducts;
      } catch (err) {
        logger.error(`Failed to fetch products for Store ID ${storeId} (public):`, err);
        setStoreError('Failed to fetch store products.');
        toast.error('Failed to fetch store products.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    []
  );

  /**
   * Fetches a single product by its ID, including ingredients.
   * @param {number|string} id - The ID of the product.
   * @returns {Promise<Object|null>} - The product data or null if failed.
   */
  const fetchProductWithIngredientsById = useCallback(
    async (id) => {
      console.log('fetchProductWithIngredientsById called with id:', id);
      if (!id) {
        setError('Product ID is required');
        toast.error('Product ID is required.');
        return null;
      }

      // Add type check and extract id if necessary
      if (typeof id === 'object' && id !== null && 'id' in id) {
        console.log('Received object, extracting id:', id.id);
        id = id.id;
      } else if (typeof id !== 'string' && typeof id !== 'number') {
        setError('Invalid Product ID');
        toast.error('Invalid Product ID.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        logger.info(`Fetching product details for ID: ${id}`);
        console.log(`Making API call to fetch product with ID: ${id}`);
        const response = await api.get(`/products/${id}`, {
          params: { include: 'ingredients' },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const product = response.data.data;

        setProductDetails(product);
        logger.info(`Fetched product details for ID: ${id}`);
        console.log('Fetched product details:', product);
        return product;
      } catch (err) {
        logger.error(`Failed to fetch product with ID ${id}:`, err);
        setError('Failed to fetch product details');
        toast.error('Failed to fetch product details.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token] // Added token to dependencies
  );

  /**
   * Alias fetchProductWithIngredientsById to getProductWithIngredients
   */
  const getProductWithIngredients = fetchProductWithIngredientsById;

  /**
   * Creates a new category.
   * @param {string} name - The name of the new category.
   * @returns {Promise<Object|null>} - The newly created category or null if failed.
   */
  const createCategory = useCallback(
    async (name) => {
      if (!name || name.trim() === '') {
        setError('Category name is required');
        toast.error('Category name is required.');
        return null;
      }

      if (!token) {
        logger.warn('createCategory called without a valid token.');
        setError('Authentication required to create category.');
        toast.error('You must be logged in to create a category.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating category with name:', name);
        const response = await api.post(
          '/categories',
          { name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newCategory = response.data.data;

        setCategories((prevCategories) => [...prevCategories, newCategory]);
        logger.info('Category created successfully.');
        toast.success('Category created successfully!');
        console.log('Created category:', newCategory);
        return {
          label: newCategory.name,
          value: newCategory.id,
        }; // Return in react-select format
      } catch (err) {
        logger.error('Failed to create category:', err);
        setError('Failed to create category');
        toast.error('Failed to create category.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Creates a new ingredient.
   * @param {Object} data - The data for the new ingredient { name: string, price: string }.
   * @returns {Promise<Object|null>} - The newly created ingredient or null if failed.
   */
  const createIngredient = useCallback(
    async (data) => {
      const { name, price } = data;

      if (!name || name.trim() === '') {
        setError('Ingredient name is required');
        toast.error('Ingredient name is required.');
        return null;
      }

      if (!price || parseFloat(price) <= 0) {
        setError('Ingredient price must be a positive number');
        toast.error('Ingredient price must be a positive number.');
        return null;
      }

      if (!token) {
        logger.warn('createIngredient called without a valid token.');
        setError('Authentication required to create ingredient.');
        toast.error('You must be logged in to create an ingredient.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating ingredient with data:', data);
        const response = await api.post(
          '/ingredients',
          { name, price },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newIngredient = response.data.data;
        setIngredients((prevIngredients) => [...prevIngredients, newIngredient]);
        logger.info('Ingredient created successfully.');
        toast.success('Ingredient created successfully!');
        console.log('Created ingredient:', newIngredient);
        return {
          label: newIngredient.name,
          value: newIngredient.id,
        }; // Return in react-select format
      } catch (err) {
        logger.error('Failed to create ingredient:', err);
        // Handle Sequelize Unique Constraint Error for name
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.errors
        ) {
          // Handle validation errors
          err.response.data.errors.forEach((error) => {
            toast.error(`${error.path}: ${error.msg}`);
          });
        } else {
          toast.error('Failed to create ingredient.');
        }
        setError('Failed to create ingredient');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Updates an existing ingredient.
   * @param {number} ingredientId - The ID of the ingredient to update.
   * @param {Object} updates - The updates to apply.
   * @returns {Promise<Object|null>} - The updated ingredient or null if failed.
   */
  const updateIngredient = useCallback(
    async (ingredientId, updates) => {
      if (!ingredientId || !updates) {
        setError('Ingredient ID and updates are required');
        toast.error('Ingredient ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateIngredient called without a valid token.');
        setError('Authentication required to update ingredient.');
        toast.error('You must be logged in to update an ingredient.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating ingredient ID ${ingredientId} with updates:`, updates);
        const response = await api.put(`/ingredients/${ingredientId}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedIngredient = response.data.data;

        setIngredients((prevIngredients) =>
          prevIngredients.map((ingredient) =>
            ingredient.id === ingredientId ? updatedIngredient : ingredient
          )
        );
        logger.info(`Ingredient ID ${ingredientId} updated successfully.`);
        toast.success('Ingredient updated successfully!');
        console.log('Updated ingredient:', updatedIngredient);
        return updatedIngredient; // Return updated ingredient
      } catch (err) {
        logger.error(`Failed to update ingredient ID ${ingredientId}:`, err);
        setError('Failed to update ingredient');
        toast.error('Failed to update ingredient.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes an ingredient.
   * @param {number} ingredientId - The ID of the ingredient to delete.
   */
  const deleteIngredient = useCallback(
    async (ingredientId) => {
      if (!ingredientId) {
        setError('Ingredient ID is required');
        toast.error('Ingredient ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteIngredient called without a valid token.');
        setError('Authentication required to delete ingredient.');
        toast.error('You must be logged in to delete an ingredient.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting ingredient ID ${ingredientId}`);
        await api.delete(`/ingredients/${ingredientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setIngredients((prevIngredients) =>
          prevIngredients.filter((ingredient) => ingredient.id !== ingredientId)
        );
        logger.info(`Ingredient ID ${ingredientId} deleted successfully.`);
        toast.success('Ingredient deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete ingredient ID ${ingredientId}:`, err);
        setError('Failed to delete ingredient');
        toast.error('Failed to delete ingredient.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Creates a new product.
   * @param {FormData} productData - The form data of the product to create.
   * @returns {Promise<Object|null>} - The newly created product or null if failed.
   */
  const createProduct = useCallback(
    async (productData) => {
      if (!productData) {
        setError('Product data is required');
        toast.error('Product data is required.');
        return null;
      }

      if (!token) {
        logger.warn('createProduct called without a valid token.');
        setError('Authentication required to create product.');
        toast.error('You must be logged in to create a product.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Creating product with FormData:', productData);
        const response = await api.post('/products', productData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        const newProduct = response.data.data;

        setProducts((prevProducts) => [newProduct, ...prevProducts]);
        logger.info('Product created successfully.');
        toast.success('Product created successfully!');
        console.log('Created product:', newProduct);
        return newProduct;
      } catch (err) {
        logger.error('Failed to create product:', err);
        setError('Failed to create product');
        toast.error('Failed to create product.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Updates an existing product.
   * @param {number} productId - The ID of the product to update.
   * @param {FormData} updates - The form data containing updates.
   * @returns {Promise<Object|null>} - The updated product or null if failed.
   */
  const updateProduct = useCallback(
    async (productId, updates) => {
      if (!productId || !updates) {
        setError('Product ID and updates are required');
        toast.error('Product ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateProduct called without a valid token.');
        setError('Authentication required to update product.');
        toast.error('You must be logged in to update a product.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating product ID ${productId} with FormData:`, updates);
        const response = await api.put(`/products/${productId}`, updates, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedProduct = response.data.data;

        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === productId ? updatedProduct : product
          )
        );
        logger.info(`Product ID ${productId} updated successfully.`);
        toast.success('Product updated successfully!');
        console.log('Updated product:', updatedProduct);
        return updatedProduct; // Return updated product
      } catch (err) {
        logger.error(`Failed to update product ID ${productId}:`, err);
        setError('Failed to update product');
        toast.error('Failed to update product.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes a product.
   * @param {number} productId - The ID of the product to delete.
   */
  const deleteProduct = useCallback(
    async (productId) => {
      if (!productId) {
        setError('Product ID is required');
        toast.error('Product ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteProduct called without a valid token.');
        setError('Authentication required to delete product.');
        toast.error('You must be logged in to delete a product.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting product ID ${productId}`);
        await api.delete(`/products/${productId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
        logger.info(`Product ID ${productId} deleted successfully.`);
        toast.success('Product deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete product ID ${productId}:`, err);
        setError('Failed to delete product');
        toast.error('Failed to delete product.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches reviews for a specific product.
   * @param {number} productId - The ID of the product to find related products for.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchProductReviews = useCallback(async (productId) => {
    if (!productId) {
      setError('Product ID is required to fetch reviews.');
      toast.error('Product ID is required to fetch reviews.');
      return null;
    }

    setLoading(true);
    setError(null);
    try {
      console.log(`Fetching reviews for product ID ${productId}`);
      const response = await api.get(`/products/${productId}/reviews`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReviews(response.data.data);
      logger.info(`Fetched reviews for Product ID ${productId}.`);
      console.log('Fetched reviews:', response.data.data);
    } catch (err) {
      logger.error(`Failed to fetch reviews for Product ID ${productId}:`, err);
      setError('Failed to fetch reviews');
      toast.error('Failed to fetch reviews.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Submits a review for a product.
   * @param {number} productId - The ID of the product.
   * @param {number} userId - The ID of the user submitting the review.
   * @param {Object} reviewData - The review data { rating: number, comment: string }.
   * @returns {Promise<Object|null>} - The submitted review data or null if failed.
   */
  const submitReview = useCallback(
    async (productId, userId, reviewData) => {
      if (!productId || !userId || !reviewData) {
        setError('Product ID, User ID, and review data are required.');
        toast.error('Product ID, User ID, and review data are required.');
        return null;
      }

      if (!token) {
        logger.warn('submitReview called without a valid token.');
        setError('Authentication required to submit a review.');
        toast.error('You must be logged in to submit a review.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(
          `Submitting review for product ID ${productId} by user ID ${userId}`,
          reviewData
        );
        const response = await api.post(`/products/${productId}/reviews`, reviewData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Optionally, refresh the reviews
        await fetchProductReviews(productId);
        logger.info(
          `Review submitted for Product ID ${productId} by User ID ${userId}.`
        );
        toast.success('Review submitted successfully!');
        console.log('Submitted review:', response.data.data);
        return response.data.data;
      } catch (err) {
        logger.error(`Failed to submit review for Product ID ${productId}:`, err);
        setError('Failed to submit review');
        toast.error('Failed to submit review.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, fetchProductReviews]
  );

  /**
   * Checks if a user has purchased a specific product with Throttle
   * @param {number} productId - The ID of the product.
   * @param {number} userId - The ID of the user.
   * @returns {Promise<boolean>} - True if the user has purchased the product, else false.
   */
  const checkUserPurchase = useMemo(
    () =>
      throttle(async (productId, userId) => {
        if (!productId || !userId) {
          setError('Product ID and User ID are required to check purchase.');
          toast.error('Product ID and User ID are required to check purchase.');
          return false;
        }

        if (!token) {
          logger.warn('checkUserPurchase called without a valid token.');
          setError('Authentication required to check purchase.');
          toast.error('You must be logged in to check purchase.');
          return false;
        }

        setLoading(true);
        setError(null);
        try {
          console.log(`Checking if user ID ${userId} has purchased product ID ${productId}`);
          const response = await api.get(`/users/${userId}/purchases/${productId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const hasPurchased = response.data.data.hasPurchased;
          logger.info(
            `User ID ${userId} hasPurchased for Product ID ${productId}: ${hasPurchased}`
          );
          console.log(`User has purchased: ${hasPurchased}`);
          return hasPurchased;
        } catch (err) {
          logger.error(
            `Failed to check purchase for User ID ${userId} and Product ID ${productId}:`,
            err
          );
          setError('Failed to check purchase status');
          toast.error('Failed to check purchase status.');
          return false;
        } finally {
          setLoading(false);
        }
      }, 1000), // 1-second throttle delay
    [token]
  );

  /**
   * Fetches a user's review for a specific product.
   * @param {number} productId - The ID of the product.
   * @param {number} userId - The ID of the user.
   * @returns {Promise<Object|null>} - The user's review or null if none exists.
   */
  const fetchUserReview = useCallback(
    async (productId, userId) => {
      if (!productId || !userId) {
        setError('Product ID and User ID are required to fetch user review.');
        toast.error('Product ID and User ID are required to fetch user review.');
        return null;
      }

      if (!token) {
        logger.warn('fetchUserReview called without a valid token.');
        setError('Authentication required to fetch user review.');
        toast.error('You must be logged in to fetch your review.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(
          `Fetching user review for user ID ${userId} and product ID ${productId}`
        );
        const response = await api.get(
          `/products/${productId}/reviews/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        logger.info(
          `Fetched user review for User ID ${userId} and Product ID ${productId}.`
        );
        console.log('Fetched user review:', response.data.data);
        return response.data.data;
      } catch (err) {
        logger.error(
          `Failed to fetch user review for User ID ${userId} and Product ID ${productId}:`,
          err
        );
        setError('Failed to fetch user review');
        toast.error('Failed to fetch your review.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Adds an item to the user's wishlist.
   * @param {number} productId - The ID of the product to add.
   * @returns {Promise<void>} - Resolves when the item is added.
   */
  const addItemToWishlist = useCallback(
    async (productId) => {
      if (!productId) {
        setError('Product ID is required to add to wishlist.');
        toast.error('Product ID is required to add to wishlist.');
        return;
      }

      if (!token) {
        logger.warn('addItemToWishlist called without a valid token.');
        setError('Authentication required to add to wishlist.');
        toast.error('You must be logged in to add items to wishlist.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(
          `Adding product ID ${productId} to wishlist for user ID ${user.id}`
        );
        await api.post(
          `/users/${user.id}/wishlist`,
          { productId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        logger.info(
          `Product ID ${productId} added to User ID ${user.id}'s wishlist.`
        );
        toast.success('Product added to wishlist!');
        console.log('Added to wishlist successfully');
      } catch (err) {
        logger.error(`Failed to add Product ID ${productId} to wishlist:`, err);
        setError('Failed to add to wishlist');
        toast.error('Failed to add to wishlist.');
      } finally {
        setLoading(false);
      }
    },
    [token, user]
  );

  /**
   * Fetches store details by store ID.
   * @param {number} storeId - The ID of the store.
   * @returns {Promise<Object|null>} - The store details or null if failed.
   */
  const fetchStoreDetails = useCallback(
    async (storeId) => {
      if (!storeId) {
        setStoreError('Store ID is required to fetch store details.');
        toast.error('Store ID is required to fetch store details.');
        return null;
      }

      if (!token) {
        logger.warn('fetchStoreDetails called without a valid token.');
        setStoreError('Authentication required to fetch store details.');
        toast.error('You must be logged in to fetch store details.');
        return null;
      }

      setLoadingStores(true);
      setStoreError(null);
      try {
        console.log(`Fetching store details for store ID ${storeId}`);
        const response = await api.get(`/stores/${storeId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const storeDetails = response.data.data;

        setStores((prevStores) =>
          prevStores.map((store) =>
            store.id === storeId ? storeDetails : store
          )
        );
        logger.info(`Fetched details for Store ID ${storeId}.`);
        console.log('Fetched store details:', storeDetails);
        return storeDetails;
      } catch (err) {
        logger.error(`Failed to fetch details for Store ID ${storeId}:`, err);
        setStoreError('Failed to fetch store details');
        toast.error('Failed to fetch store details.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    [token]
  );

  /**
   * Fetches stores near a given location (latitude, longitude).
   * @param {Object} location - { latitude, longitude }
   * @param {number} radiusInMiles - Radius in miles (default: 5 miles)
   * @returns {Promise<Array|null>} - Array of stores or null if failed
   */
  const fetchStoresByLocation = useCallback(
    async (location, radiusInMiles = 5) => {
      if (!token) {
        logger.warn('fetchStoresByLocation called without a valid token.');
        setStoreError('Authentication required to fetch nearby stores.');
        toast.error('You must be logged in to fetch nearby stores.');
        return null;
      }

      if (
        !location ||
        typeof location.latitude !== 'number' ||
        typeof location.longitude !== 'number'
      ) {
        setStoreError('Valid latitude and longitude are required.');
        toast.error('Valid latitude and longitude are required.');
        return null;
      }

      // **Convert radius from miles to kilometers**
      const radiusInKm = milesToKilometers(radiusInMiles);

      setLoadingStores(true);
      setStoreError(null);
      try {
        console.log(
          `Fetching stores near location:`,
          location,
          `within ${radiusInMiles} miles (${radiusInKm.toFixed(2)} km)`
        );
        const response = await api.get('/stores/nearby', {
          params: { ...location, radius: radiusInKm },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedStores = response.data.data;

        if (fetchedStores.length === 0) {
          toast.info(`No stores found within a ${radiusInMiles}-mile radius.`);
          setNearestStore(null);
          setStores([]);
          console.log(`No stores found within a ${radiusInMiles}-mile radius.`);
          return null;
        }

        setStores(fetchedStores);
        logger.info('Fetched nearby stores successfully.', fetchedStores);
        console.log('Fetched nearby stores:', fetchedStores);
        return fetchedStores;
      } catch (err) {
        logger.error('Failed to fetch nearby stores:', err);
        setStoreError('Failed to fetch nearby stores');
        toast.error('Failed to fetch nearby stores.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    [token]
  );

  /**
   * Fetches the nearest store to a given location within a specified radius.
   * @param {Object} location - { latitude, longitude }
   * @param {number} radiusInMiles - Radius in miles (default: 5 miles)
   * @returns {Promise<Object|null>} - The nearest store or null if none found within radius
   */
  const fetchNearestStore = useCallback(
    async (location, radiusInMiles = 5) => {
      if (!token) {
        logger.warn('fetchNearestStore called without a valid token.');
        setStoreError('Authentication required to fetch nearest store.');
        toast.error('You must be logged in to fetch the nearest store.');
        return null;
      }

      if (
        !location ||
        typeof location.latitude !== 'number' ||
        typeof location.longitude !== 'number'
      ) {
        setStoreError('Valid latitude and longitude are required.');
        toast.error('Valid latitude and longitude are required.');
        return null;
      }

      // **Convert radius from miles to kilometers**
      const radiusInKm = milesToKilometers(radiusInMiles);

      setLoadingStores(true);
      setStoreError(null);
      try {
        console.log(
          `Fetching nearest store near location:`,
          location,
          `within ${radiusInMiles} miles (${radiusInKm.toFixed(2)} km)`
        );
        const response = await api.get('/stores/nearby', {
          params: { ...location, radius: radiusInKm },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedStores = response.data.data;

        if (fetchedStores.length === 0) {
          toast.info(`No stores found within a ${radiusInMiles}-mile radius.`);
          setNearestStore(null);
          setStores([]);
          console.log(`No stores found within a ${radiusInMiles}-mile radius.`);
          return null;
        }

        // Assuming the backend orders stores by distance ascending
        const nearest = fetchedStores[0];
        setNearestStore(nearest);
        setStores([nearest]); // Only keep the nearest store
        logger.info('Fetched nearest store successfully.', nearest);
        console.log('Fetched nearest store:', nearest);
        return nearest;
      } catch (err) {
        logger.error('Failed to fetch nearest store:', err);
        setStoreError('Failed to fetch nearest store');
        toast.error('Failed to fetch nearest store.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    [token]
  );

  /**
   * **Public Store Fetching Function**
   * Fetches the nearest store to a given location without requiring authentication.
   * @param {Object} location - { latitude, longitude }
   * @param {number} radiusInMiles - Radius in miles (default: 5 miles)
   * @returns {Promise<Object|null>} - The nearest store or null if none found within radius
   */
  const fetchNearestStorePublic = useCallback(
    async (location, radiusInMiles = 5) => {
      if (
        !location ||
        typeof location.latitude !== 'number' ||
        typeof location.longitude !== 'number'
      ) {
        setStoreError('Valid latitude and longitude are required.');
        toast.error('Valid latitude and longitude are required.');
        return null;
      }

      // **Convert radius from miles to kilometers**
      const radiusInKm = milesToKilometers(radiusInMiles);

      setLoadingStores(true);
      setStoreError(null);
      try {
        console.log(
          `Public Fetch: Fetching nearest store near location:`,
          location,
          `within ${radiusInMiles} miles (${radiusInKm.toFixed(2)} km)`
        );
        const response = await api.get('/public/stores/nearby', { // Note the '/public' prefix
          params: { ...location, radius: radiusInKm },
        });
        const fetchedStores = response.data.data;

        if (fetchedStores.length === 0) {
          toast.info(`No stores found within a ${radiusInMiles}-mile radius.`);
          setNearestStore(null);
          setStores([]);
          console.log(`No stores found within a ${radiusInMiles}-mile radius.`);
          return null;
        }

        // Assuming the backend orders stores by distance ascending
        const nearest = fetchedStores[0];
        setNearestStore(nearest);
        setStores([nearest]); // Only keep the nearest store
        logger.info('Fetched nearest store successfully (public).', nearest);
        console.log('Fetched nearest store (public):', nearest);
        return nearest;
      } catch (err) {
        logger.error('Failed to fetch nearest store (public):', err);
        setStoreError('Failed to fetch nearest store.');
        toast.error('Failed to fetch nearest store.');
        return null;
      } finally {
        setLoadingStores(false);
      }
    },
    []
  );

  /**
   * Fetches related products based on a specific product ID.
   * @param {number} productId - The ID of the product to find related products for.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchRelatedProducts = useCallback(
    async (productId) => {
      if (!productId) {
        setError('Product ID is required to fetch related products');
        toast.error('Product ID is required to fetch related products.');
        return;
      }

      if (!token) {
        logger.warn('fetchRelatedProducts called without a valid token.');
        setError('Authentication required to fetch related products.');
        toast.error('You must be logged in to fetch related products.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Fetching related products for product ID ${productId}`);
        const response = await api.get(`/products/related/${productId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedRelatedProducts = response.data.data;

        setRelatedProducts(fetchedRelatedProducts); // Products already include ingredients
        logger.info(`Fetched related products for product ID: ${productId}`);
        console.log('Fetched related products:', fetchedRelatedProducts);
      } catch (err) {
        logger.error(
          `Failed to fetch related products for product ID ${productId}:`,
          err
        );
        setError('Failed to fetch related products');
        toast.error('Failed to fetch related products.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches payment methods.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchPaymentMethods = useCallback(async () => {
    if (!token) {
      logger.warn('fetchPaymentMethods called without a valid token.');
      setError('Authentication required to fetch payment methods.');
      toast.error('You must be logged in to fetch payment methods.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      console.log('Fetching payment methods...');
      const response = await api.get('/payment-methods', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPaymentMethods(response.data.data);
      logger.info('Fetched payment methods successfully.');
      console.log('Fetched payment methods:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch payment methods:', err);
      setError('Failed to fetch payment methods');
      toast.error('Failed to fetch payment methods.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Fetches payments.
   * @returns {Promise<void>} - Resolves when fetching is complete.
   */
  const fetchPayments = useCallback(async () => {
    if (!token) {
      logger.warn('fetchPayments called without a valid token.');
      setError('Authentication required to fetch payments.');
      toast.error('You must be logged in to fetch payments.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      console.log('Fetching payments...');
      const response = await api.get('/payments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPayments(response.data.data);
      logger.info('Fetched payments successfully.');
      console.log('Fetched payments:', response.data.data);
    } catch (err) {
      logger.error('Failed to fetch payments:', err);
      setError('Failed to fetch payments');
      toast.error('Failed to fetch payments.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  /**
   * Places an order.
   * @param {Object} orderData - The order data containing cart items, user info, addresses, etc.
   * @returns {Promise<Object|null>} - The placed order or null if failed.
   */
  const placeOrder = useCallback(
    async (orderData) => {
      if (!orderData) {
        setError('Order data is required');
        toast.error('Order data is required.');
        return null;
      }

      if (!token) {
        logger.warn('placeOrder called without a valid token.');
        setError('Authentication required to place order.');
        toast.error('You must be logged in to place an order.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Placing order with data:', orderData);
        const response = await api.post('/orders', orderData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const placedOrder = response.data.data;

        setOrders((prevOrders) => [placedOrder, ...prevOrders]);
        logger.info(`Order placed successfully: Order ID ${placedOrder.id}`);
        toast.success('Order placed successfully!');
        console.log('Placed order:', placedOrder);
        return placedOrder;
      } catch (err) {
        logger.error('Failed to place order:', err);
        setError('Failed to place order');
        toast.error('Failed to place order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches all orders for the authenticated user or admin.
   * Now accepts query parameters for flexible data fetching.
   * @param {Object} params - Query parameters for fetching orders.
   * @returns {Promise<Object|null>} - The fetched orders data or null if failed.
   */
  const fetchOrdersExtended = useCallback(
    async (params = {}) => {
      if (!token) {
        logger.warn('fetchOrdersExtended called without a valid token.');
        setError('Authentication required to fetch orders.');
        toast.error('You must be logged in to view orders.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Fetching orders with params:', params);

        // If admin, maybe pass a flag or adjust params
        if (user && user.role === 'admin') {
          params.admin = true; // Example parameter; adjust based on backend
        }

        const response = await api.get('/orders', {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data.data);
        setPagination(response.data.pagination);
        logger.info(`Fetched orders: Page ${response.data.pagination.currentPage}`);
        console.log('Fetched orders:', response.data.data);

        // **Return the fetched data**
        return response.data;
      } catch (err) {
        logger.error('Failed to fetch orders:', err);
        setError('Failed to fetch orders.');
        toast.error('Failed to fetch orders.');
        return null; // Explicitly return null on failure
      } finally {
        setLoading(false);
      }
    },
    [token, user]
  );

  /**
   * Updates an existing order (e.g., update status).
   * @param {number} orderId - The ID of the order to update.
   * @param {Object} updates - The updates to apply.
   * @returns {Promise<Object|null>} - The updated order or null if failed.
   */
  const updateOrder = useCallback(
    async (orderId, updates) => {
      if (!orderId || !updates) {
        setError('Order ID and updates are required');
        toast.error('Order ID and updates are required.');
        return null;
      }

      if (!token) {
        logger.warn('updateOrder called without a valid token.');
        setError('Authentication required to update order.');
        toast.error('You must be logged in to update an order.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Updating order ID ${orderId} with updates:`, updates);
        const response = await api.put(`/orders/${orderId}`, updates, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const updatedOrder = response.data.data;

        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? updatedOrder : order
          )
        );
        logger.info(`Order ID ${orderId} updated successfully.`);
        toast.success('Order updated successfully!');
        console.log('Updated order:', updatedOrder);
        return updatedOrder; // Return updated order
      } catch (err) {
        logger.error(`Failed to update order ID ${orderId}:`, err);
        setError('Failed to update order');
        toast.error('Failed to update order.');
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Deletes an existing order.
   * @param {number} orderId - The ID of the order to delete.
   * @returns {Promise<void>} - Resolves when deletion is complete.
   */
  const deleteOrder = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required');
        toast.error('Order ID is required.');
        return;
      }

      if (!token) {
        logger.warn('deleteOrder called without a valid token.');
        setError('Authentication required to delete order.');
        toast.error('You must be logged in to delete an order.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Deleting order ID ${orderId}`);
        await api.delete(`/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
        logger.info(`Order deleted: Order ID ${orderId}`);
        toast.success('Order deleted successfully!');
      } catch (err) {
        logger.error(`Failed to delete order ID ${orderId}:`, err);
        setError('Failed to delete order');
        toast.error('Failed to delete order.');
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Bulk updates orders' statuses.
   * @param {Array<number>} orderIds - Array of order IDs to update.
   * @param {string} status - The new status to apply.
   * @returns {Promise<Object|null>} - The result of the bulk update or null if failed.
   */
  const bulkUpdateOrders = useCallback(
    async (orderIds, status) => {
      if (!orderIds || !Array.isArray(orderIds) || orderIds.length === 0) {
        setError('Order IDs are required for bulk update');
        toast.error('Order IDs are required for bulk update.');
        return;
      }

      // Updated to match ORDER_STATUSES
      const validStatuses = [
        'pending',
        'processing',
        'baking',
        'ready for pickup',
        'collected',
        'completed',
        'refunded',
        'cancelled',
        'deleted',
      ];

      if (!validStatuses.includes(status)) {
        setError('Invalid status');
        toast.error('Invalid status');
        return;
      }

      if (!token) {
        logger.warn('bulkUpdateOrders called without a valid token.');
        setError('Authentication required to bulk update orders.');
        toast.error('You must be logged in to bulk update orders.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log('Sending to bulkUpdateOrders endpoint:', { orderIds, status });
        console.log(`Bulk updating orders ${orderIds} to status: ${status}`);
        const response = await api.put(
          '/orders/bulk-update',
          { orderIds, status },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(response.data.message || 'Bulk update successful.');
        logger.info('Bulk update successful:', response.data);
        console.log('Bulk update response:', response.data);
        // Refresh orders
        await fetchOrdersExtended();
        return response.data; // Optionally return response data
      } catch (err) {
        logger.error('Failed to bulk update orders:', err);
        setError('Failed to bulk update orders');
        toast.error('Failed to bulk update orders.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token, fetchOrdersExtended]
  );

  /**
   * Generates and downloads an invoice PDF for an order.
   * @param {number} orderId - The ID of the order.
   * @returns {Promise<Object|null>} - The result of the download or null if failed.
   */
  const downloadInvoice = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required to download invoice');
        toast.error('Order ID is required to download invoice.');
        return null;
      }

      if (!token) {
        logger.warn('downloadInvoice called without a valid token.');
        setError('Authentication required to download invoice.');
        toast.error('You must be logged in to download an invoice.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Downloading invoice for order ID ${orderId}`);
        const response = await api.get(`/orders/${orderId}/invoice`, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${orderId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        logger.info(`Invoice downloaded for Order ID ${orderId}`);
        toast.success('Invoice downloaded successfully!');
        console.log('Invoice downloaded successfully');
        return response.data; // Optionally return the blob
      } catch (err) {
        logger.error(`Failed to download invoice for Order ID ${orderId}:`, err);
        setError('Failed to download invoice');
        toast.error('Failed to download invoice.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Sends a notification (e.g., email) about order updates.
   * @param {number} orderId - The ID of the order.
   * @param {string} type - The type of notification (e.g., 'email').
   * @returns {Promise<Object|null>} - The result of the notification or null if failed.
   */
  const sendNotification = useCallback(
    async (orderId, type) => {
      if (!orderId || !type) {
        setError('Order ID and notification type are required');
        toast.error('Order ID and notification type are required.');
        return;
      }

      if (!token) {
        logger.warn('sendNotification called without a valid token.');
        setError('Authentication required to send notifications.');
        toast.error('You must be logged in to send notifications.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Sending ${type} notification for order ID ${orderId}`);
        await api.post(
          `/orders/${orderId}/notify`,
          { type },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        logger.info(`Notification sent via ${type} for Order ID ${orderId}`);
        toast.success(`Notification sent via ${type} successfully!`);
        console.log(`Notification sent via ${type} successfully!`);
      } catch (err) {
        logger.error(
          `Failed to send ${type} notification for Order ID ${orderId}:`,
          err
        );
        setError(`Failed to send ${type} notification`);
        toast.error(`Failed to send ${type} notification.`);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Tracks an order by fetching its current status and tracking number.
   * @param {number} orderId - The ID of the order to track.
   * @returns {Promise<Object|null>} - The tracking details of the order.
   */
  const trackOrder = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required to track order');
        toast.error('Order ID is required to track order.');
        return null;
      }

      if (!token) {
        logger.warn('trackOrder called without a valid token.');
        setError('Authentication required to track order.');
        toast.error('You must be logged in to track orders.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Tracking order ID ${orderId}`);
        const response = await api.get(`/orders/track/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const trackingDetails = response.data.data; // { status, trackingNumber }
        logger.info(`Tracked Order ID ${orderId}:`, trackingDetails);
        console.log('Tracking details:', trackingDetails);
        return trackingDetails;
      } catch (err) {
        logger.error(`Failed to track Order ID ${orderId}:`, err);
        setError('Failed to track order');
        toast.error('Failed to track order.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Fetches detailed information about a single order.
   * @param {number} orderId - The ID of the order.
   * @returns {Promise<Object|null>} - The detailed information of the order.
   */
  const fetchOrderDetails = useCallback(
    async (orderId) => {
      if (!orderId) {
        setError('Order ID is required to fetch details');
        toast.error('Order ID is required to fetch details.');
        return null;
      }

      if (!token) {
        logger.warn('fetchOrderDetails called without a valid token.');
        setError('Authentication required to fetch order details.');
        toast.error('You must be logged in to fetch order details.');
        return null;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(`Fetching details for order ID ${orderId}`);
        const response = await api.get(`/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const detailedOrder = response.data.data;

        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? detailedOrder : order
          )
        );
        logger.info(`Fetched details for Order ID ${orderId}:`, detailedOrder);
        console.log('Fetched detailed order:', detailedOrder);
        return detailedOrder;
      } catch (err) {
        logger.error(`Failed to fetch details for Order ID ${orderId}:`, err);
        setError('Failed to fetch order details');
        toast.error('Failed to fetch order details.');
        return null;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  /**
   * Search Products by Query with Debounce
   * @param {string} query - The search query string.
   * @returns {Promise<void>} - Resolves when searching is complete.
   */
  const searchProducts = useMemo(
    () =>
      debounce(async (query) => {
        if (!query || query.trim() === '') {
          setSearchResults([]);
          return;
        }

        if (!token) {
          logger.warn('searchProducts called without a valid token.');
          setError('Authentication required to search products.');
          toast.error('You must be logged in to search products.');
          return;
        }

        setLoading(true);
        setError(null);
        try {
          console.log(`Searching products with query: ${query}`);
          logger.info(`Searching products with query: ${query}`);
          const response = await api.get('/products/search', {
            params: { query },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const fetchedProducts = response.data.data;

          setSearchResults(fetchedProducts); // Products already include ingredients
          logger.info(`Found ${fetchedProducts.length} products matching query: ${query}`);
          console.log(`Found ${fetchedProducts.length} products matching query: ${query}`);
        } catch (err) {
          logger.error('Failed to search products:', err);
          setError('Failed to search products');
          toast.error('Failed to search products.');
        } finally {
          setLoading(false);
        }
      }, 300), // 300ms debounce delay
    [token]
  );

  /**
   * Cleanup debounce and throttle on unmount
   */
  useEffect(() => {
    return () => {
      searchProducts.cancel();
      checkUserPurchase.cancel();
    };
  }, [searchProducts, checkUserPurchase]);

  /**
   * Initialization of Store Data on Component Mount
   */
  const initializeStore = useCallback(async () => {
    if (initialized) return; // Prevent multiple initializations
    setInitialized(true);
    setLoading(true);
    setError(null);
    try {
      // Fetch categories
      await fetchCategories();
      logger.info('Fetched categories successfully.');

      // Fetch ingredients
      await fetchIngredients();
      logger.info('Fetched ingredients successfully.');

      // Fetch products
      await fetchProducts({
        page: 1,
        limit: itemsPerPage, // Use itemsPerPage from state
        sortBy: 'createdAt',
        order: 'desc',
      });
      logger.info('Fetched products successfully.');

      // Fetch recipes
      const recipesResponse = await api.get('/recipes', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRecipes(recipesResponse.data.data);
      logger.info('Fetched recipes successfully.');
      console.log('Fetched recipes:', recipesResponse.data.data);

      // Fetch orders only if authenticated
      if (token) {
        await fetchOrdersExtended({
          page: 1,
          limit: itemsPerPage, // Use itemsPerPage
        });
        logger.info('Fetched orders successfully.');
      }

      // Fetch stores only if authenticated
      if (token) {
        await fetchStores();
        logger.info('Fetched stores successfully.');
      }

      // Fetch payment methods
      await fetchPaymentMethods();
      logger.info('Fetched payment methods during initialization.');

      // Fetch payments
      await fetchPayments();
      logger.info('Fetched payments during initialization.');
    } catch (err) {
      logger.error('Initialization failed:', err);
      setError('Failed to initialize store data.');
      toast.error('Failed to initialize store data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [
    initialized,
    fetchPayments,
    fetchPaymentMethods,
    fetchCategories,
    fetchIngredients,
    fetchProducts,
    fetchOrdersExtended,
    fetchStores,
    itemsPerPage,
    token,
  ]);

  useEffect(() => {
    initializeStore();
  }, [initializeStore]);

  /**
   * **Polling Mechanism to Fetch Orders Every Minute**
   */
  useEffect(() => {
    // Define the polling function
    const pollOrders = async () => {
      if (token) {
        try {
          console.log('Polling: Fetching latest orders...');
          await fetchOrdersExtended({
            page: 1,
            limit: itemsPerPage,
            // Add any additional parameters if needed
          });
          console.log('Polling: Orders updated.');
        } catch (error) {
          console.error('Polling: Failed to fetch orders.', error);
          // Optionally, you can handle specific errors or retry logic here
        }
      }
    };

    // Set up the interval to poll every minute (60000 ms)
    const intervalId = setInterval(pollOrders, 60000); // 1 minute

    // Optionally, perform an immediate poll when the component mounts
    pollOrders();

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [token, fetchOrdersExtended, itemsPerPage]);

  /**
   * **Integrate Socket.IO Connection**
   */
  useEffect(() => {
    if (!token) {
      console.warn('No token available. Socket.IO connection not established.');
      return;
    }

    const socket = io(config.API_URL, {
      transports: ['websocket'],
      auth: { token },
      reconnectionAttempts: 5,
      timeout: 10000,
    });

    socket.on('connect', () => {
      console.log('Connected to Socket.IO server.');
      toast.success('Connected to live updates.');

      // Always join user_{userId} room for personal order updates
      if (user && user.userId) {
        socket.emit('join_user', user.userId);
        console.log(`Emitted join_user for user ID ${user.userId}`);
      }

      // If admin, join admin room
      if (user && user.role === 'admin') {
        socket.emit('join_admin');
        console.log('Emitted join_admin for admin role.');
      } else if (selectedStore) {
        // If store staff, join store_{storeId} room
        socket.emit('join_store', selectedStore.id);
        console.log(`Emitted join_store for store ID ${selectedStore.id}`);
      }

      socket.emit('request_presence');
      console.log('Emitted request_presence.');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server.');
      toast.error('Disconnected from live updates.');
    });

    // Listen for order status updates
    socket.on(
      'order_status_update',
      ({
        orderId,
        status,
        processingStartTime,
        bakingStartTime,
        readyForCollectionTime,
      }) => {
        console.log(`Order #${orderId} updated to ${status}`);
        toast.info(`Order #${orderId} updated to ${status}`);
        setOrders((prevOrders) =>
          prevOrders.map((ord) =>
            ord.id === orderId
              ? { ...ord, status, processingStartTime, bakingStartTime, readyForCollectionTime }
              : ord
          )
        );
      }
    );

    // Listen for new orders
    socket.on('new_order', (order) => {
      console.log(`New order received: #${order.id}`);
      toast.info(`New order #${order.id} received`);
      setOrders((prev) => [order, ...prev]);
    });

    // Listen for order deletions
    socket.on('order_deleted', ({ orderId }) => {
      console.log(`Order #${orderId} deleted`);
      toast.info(`Order #${orderId} deleted`);
      setOrders((prev) => prev.filter((o) => o.id !== orderId));
    });

    // Handle connection errors
    socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err.message);
      toast.error('Live updates connection failed.');
    });

    // Optional: Handle reconnection attempts
    socket.io.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnection attempt #${attempt}`);
    });

    socket.io.on('reconnect_failed', () => {
      console.log('Reconnection failed.');
      toast.error('Reconnection failed.');
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
      console.log('Socket.IO connection closed.');
    };
  }, [token, user, selectedStore]);

  /**
   * **Modal Control Functions**
   */
  const openProductModal = useCallback((product) => {
    setSelectedProduct(product);
    setIsProductModalOpen(true);
  }, []);

  const closeProductModal = useCallback(() => {
    setSelectedProduct(null);
    setIsProductModalOpen(false);
  }, []);

  const openAddToCartModal = useCallback((product) => {
    setSelectedProductForCart(product);
    setIsAddToCartModalOpen(true);
  }, []);

  const closeAddToCartModal = useCallback(() => {
    setSelectedProductForCart(null);
    setIsAddToCartModalOpen(false);
  }, []);

  /**
   * **Rest of the StoreContext Provider Value**
   */
  return (
    <StoreContext.Provider
      value={{
        // =============================
        // ======== Categories =========
        // =============================
        categories,
        setCategories,
        fetchCategories,
        createCategory,

        // =============================
        // ========= Products ==========
        // =============================
        products,
        setProducts,
        fetchProducts,
        fetchStoreProductsPublic, // **Public Fetching Function**
        fetchProductWithIngredientsById,
        getProductWithIngredients,
        createProduct,
        updateProduct,
        deleteProduct,

        // =============================
        // ========= Search ============
        // =============================
        searchResults,
        setSearchResults,
        searchProducts,
        pagination,
        setPagination,

        // =============================
        // ==== Related Products =======
        // =============================
        relatedProducts,
        setRelatedProducts,
        fetchRelatedProducts,

        // =============================
        // ========= Ingredients =========
        // =============================
        ingredients,
        setIngredients,
        fetchIngredients,
        createIngredient,
        updateIngredient,
        deleteIngredient,

        // =============================
        // ========= Recipes ============
        // =============================
        recipes,
        setRecipes,
        // Define fetchRecipeById, createRecipe, etc., if needed

        // =============================
        // ===== Product Details ========
        // =============================
        productDetails,
        setProductDetails,

        // =============================
        // ========= Reviews ============
        // =============================
        reviews,
        setReviews,
        fetchProductReviews,
        submitReview,
        userReview,
        setUserReview,
        checkUserPurchase,
        fetchUserReview,

        // =============================
        // ========= Wishlist ===========
        // =============================
        addItemToWishlist,

        // =============================
        // ========= Orders ============
        // =============================
        orders,
        setOrders,
        fetchOrders: fetchOrdersExtended, // Updated to accept parameters and return data
        placeOrder,
        updateOrder,
        deleteOrder,
        bulkUpdateOrders,
        downloadInvoice,
        sendNotification,
        trackOrder,
        fetchOrderDetails,

        // =============================
        // ========= Stores ============
        // =============================
        stores,
        setStores,
        fetchStores,
        fetchStoresByLocation,
        fetchNearestStore,
        fetchNearestStorePublic, // **Added Public Store Fetching Function**
        fetchStoreDetails, // Authenticated fetching
        fetchStoreDetailsPublic, // **Added Public Store Details Fetching Function**
        nearestStore,
        loadingStores,
        storeError,

        // =============================
        // ===== Payment Methods ========
        // =============================
        paymentMethods,
        fetchPaymentMethods,

        // =============================
        // ========= Payments ===========
        // =============================
        payments,
        fetchPayments,

        // =============================
        // ======= Toggle State =========
        // =============================
        toggle,
        setToggle,
        toggleFunction,

        // =============================
        // ====== userHasPurchased =======
        // =============================
        userHasPurchased,
        setUserHasPurchased,

        // =============================
        // ====== Selected Store ========
        // =============================
        selectedStore,
        setSelectedStore,

        // =============================
        // ======= Pagination ===========
        // =============================
        itemsPerPage,
        setItemsPerPage,

        // =============================
        // ========= Modals ============
        // =============================
        isProductModalOpen,
        selectedProduct,
        openProductModal,
        closeProductModal,

        isAddToCartModalOpen,
        selectedProductForCart,
        openAddToCartModal,
        closeAddToCartModal,

        // =============================
        // ======= Loading & Error ======
        // =============================
        loading,
        setLoading,
        error,
        setError,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

// Define PropTypes for type checking
StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Export the StoreContext
export default StoreContext;
