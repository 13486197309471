// frontend/src/store.js

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import cartReducer from './features/cart/cartSlice';
import notificationsReducer from './features/notifcation/notificationsSlice'; // Import notifications reducer
// Import other reducers as needed

const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
    notifications: notificationsReducer, // Add notifications reducer
    // Add other reducers here
  },
  // Optional: Add middleware or devTools configurations here
});

export default store; // Change from named export to default export
