// src/components/AboutUs.js

import React from 'react';
import { FaGooglePlay, FaApple, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';

function AboutUs() {
  return (
    <div className="bg-white text-gray-800">
      {/* Header Section */}
      <header className="bg-gradient-to-r from-pink-500 to-purple-600 text-white py-12">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold">About Us</h1>
          <p className="mt-4 text-lg">Welcome to Cookies & Cream</p>
        </div>
      </header>

      {/* Introduction Section */}
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center">
            <h2 className="text-3xl font-semibold mb-4">Menu Cookies & Cream</h2>
            <p className="text-lg leading-relaxed">
              Our dessert-type restaurant welcomes you with a bright smile from your family members and friends. Our restaurant has signature dishes, such as desserts and creams available to your fullest satisfaction. Our tasty preparation entices you to the restaurant with a lot of expectations. Our main dishes like Caramel Iced Coffee, Chocolate Iced Coffee, Hazelnut Iced Coffee, and Pina Colada Mocktail satiate the requirements of all customers to the core. Both young and old customers love visiting our restaurant due to the exemplary food items available. You can enjoy and quench your thirst by dining in our place.
            </p>
          </div>
        </div>
      </section>

      {/* Featured Menu Items Section */}
      <section className="py-12">
        <div className="container mx-auto px-6">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-semibold">Our Specialties</h2>
            <p className="mt-2 text-gray-600">Delicious offerings to satisfy every palate.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Caramel Iced Coffee */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Caramel Iced Coffee</h3>
              <p className="text-gray-600">A rich blend of espresso and caramel syrup, topped with creamy foam.</p>
            </div>
            {/* Chocolate Iced Coffee */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Chocolate Iced Coffee</h3>
              <p className="text-gray-600">Decadent chocolate flavor mixed with our finest coffee beans.</p>
            </div>
            {/* Hazelnut Iced Coffee */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Hazelnut Iced Coffee</h3>
              <p className="text-gray-600">A nutty twist to your classic iced coffee, perfect for any time of day.</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
            {/* Pina Colada Mocktail */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Pina Colada Mocktail</h3>
              <p className="text-gray-600">A tropical delight with pineapple and coconut flavors.</p>
            </div>
            {/* Strawberry Smoothie */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Strawberry Smoothie</h3>
              <p className="text-gray-600">Fresh strawberries blended to perfection with creamy goodness.</p>
            </div>
            {/* Very Berry Smoothie */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Very Berry Smoothie</h3>
              <p className="text-gray-600">A mix of assorted berries for a burst of flavor in every sip.</p>
            </div>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center">
            <h2 className="text-3xl font-semibold mb-4">About Cookies & Cream</h2>
            <p className="text-lg leading-relaxed">
              Our Cookies and Cream restaurant is one of the main restaurants in the city. We are leading professionals in the hotel industry. We have reached this height due to our continuous commitment and dedication to our lovable customers. We are sure that you will be tempted on seeing our menu list in our place. Yes, the unique food style and versatile features of our restaurant give you a wonderful experience. Yes, you may feel different and fulfilled after dining here. Our quality food items and special customer services make you mind-blowing. You can know about it in-depth by visiting our website <a href="https://cookies-and-cream.co.uk" className="text-pink-600 underline">cookies-and-cream.co.uk</a>.
            </p>
          </div>
        </div>
      </section>

      {/* Location and Contact Section */}
      <section className="py-12">
        <div className="container mx-auto px-6">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-semibold">Restaurant Location</h2>
            <p className="mt-2 text-gray-600">Find us easily and enjoy our delightful offerings.</p>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            {/* Address */}
            <div className="text-center md:text-left">
              <h3 className="text-xl font-semibold mb-2">Cookies & Cream</h3>
              <p className="text-gray-600">97 High St N, Dunstable LU6 1JL</p>
              <p className="text-gray-600 mt-2">📞 Phone: <a href="tel:+441582966310" className="text-pink-600 underline">+44 1582 966310</a></p>
            </div>
            {/* Map or Image Placeholder */}
            <div className="w-full md:w-1/2">
              {/* You can embed a Google Map here or use an image */}
              <iframe
                title="Restaurant Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.930424806981!2d-0.47015908414485307!3d51.88036217960998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761e50a0c5b3d3%3A0x3a3d3e4d5c5f5c5!2s97%20High%20St%2C%20Dunstable%20LU6%201JL%2C%20UK!5e0!3m2!1sen!2sin!4v1618311234567!5m2!1sen!2sin"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                className="rounded-lg"
              ></iframe>
            </div>
          </div>
          {/* Download App Section */}
          <div className="mt-8 text-center">
            <h3 className="text-xl font-semibold mb-4">Download Our App</h3>
            <div className="flex justify-center gap-4">
              <a href="https://play.google.com/store/apps/details?id=com.cookiescream.app" target="_blank" rel="noopener noreferrer">
                <FaGooglePlay size={40} className="text-gray-600 hover:text-green-600 transition-colors duration-200" />
              </a>
              <a href="https://apps.apple.com/app/cookiescream/id123456789" target="_blank" rel="noopener noreferrer">
                <FaApple size={40} className="text-gray-600 hover:text-gray-800 transition-colors duration-200" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Call-to-Action Section */}
      <section className="bg-pink-600 text-white py-8">
        <div className="container mx-auto text-center">
          <h2 className="text-2xl font-semibold">Visit Us Today!</h2>
          <p className="mt-2">Experience the best desserts and friendly service at Cookies & Cream.</p>
          <a
            href="/menu"
            className="mt-4 inline-block bg-white text-pink-600 font-semibold py-2 px-4 rounded-full hover:bg-gray-200 transition-colors duration-200"
          >
            Explore Our Menu
          </a>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
